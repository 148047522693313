import React, { useEffect, useState } from 'react';
import Icons from '../../../Icons';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { useEditor } from 'components/Canvas';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';

function Lock() {
  const [options, setOptions] = useState({ locked: false });
  const editor = useEditor();
  const { activeObject } = useTemplateEditorContext();

  useEffect(() => {
    if (activeObject) {
      updateOptions(activeObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  const updateOptions = object => {
    const { locked } = object;
    setOptions({ ...options, locked: !!locked });
  };

  return (
    <>
      {options.locked ? (
        <Tooltip label="Lock">
          <IconButton
            onClick={() => {
              setOptions({ ...options, locked: false });
              editor.unlock();
            }}
            color="#000000"
            borderRadius="0"
            variant="ghost"
            size="sm"
            icon={<Icons.Locked size={24} />}
          />
        </Tooltip>
      ) : (
        <Tooltip label="Lock">
          <IconButton
            onClick={() => {
              setOptions({ ...options, locked: true });
              editor.lock();
            }}
            color="#000000"
            borderRadius="0"
            variant="ghost"
            size="sm"
            icon={<Icons.UnLocked size={24} />}
          />
        </Tooltip>
      )}
    </>
  );
}

export default Lock;
