import React, { createContext, useState } from 'react';
import { DEFAULT_FONT, DEFAULT_FORMAT_SIZE } from '../constants/editor';
import {
  defaultGrid,
  initialSizeFormat,
} from 'components/Canvas/common/constants';

export const TemplateEditorContext = createContext();
export const TemplateEditorProvider = ({ children }) => {
  const [activePanel, setActivePanel] = useState('Text');
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isFontLoading, setIsFontLoading] = useState(false);
  const [isEditorLoading, setIsEditorLoading] = useState(false);
  const [isEnabledDoubleSide, setIsEnabledDoubleSide] = useState(false);
  const [isEnabledDoubleSideMessage, setIsEnabledDoubleSideMessage] =
    useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [defaultFont, setDefaultFont] = useState(DEFAULT_FONT);
  const [defaultFormatSize, setDefaultFormatSize] =
    useState(DEFAULT_FORMAT_SIZE);
  const [doubleSidedBgImgUrls, setDoubleSidedBgImgUrls] = useState(['', '']);

  //EditorContext
  const [activeObject, setActiveObject] = useState();
  const [handlers, setHandlers] = useState();
  const [sizeFormat, setSizeFormat] = useState(initialSizeFormat);
  const [doubleSided, setDoubleSided] = useState(false);
  const [defaultSizeFormat, setDefaultSizeFormat] = useState(initialSizeFormat);
  const [zoomRatio, setZoomRatio] = useState(1);
  const [fitZoomRatio, setFitZoomRatio] = useState(1);
  const [fillZoomRatio, setFillZoomRatio] = useState(1);
  const [contextMenuType, setContextMenuType] = useState('default');
  const [grid, setGrid] = useState(defaultGrid);

  // editor is actually a class of helpers
  const [editor, setEditor] = useState(null);
  const [zoomMode, setZoomMode] = useState('fit');
  const [currentBackgroundColor, setCurrentBackgroundColor] =
    useState('#ffffff');
  const [currentBackgroundImage, setCurrentBackgroundImage] = useState(false);
  const [keysMenu, setKeysMenu] = useState({
    isEditing: false,
    shouldShow: false,
    visible: false,
    object: undefined,
    position: {
      top: 0,
      left: 0,
    },
  });

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    object: undefined,
    position: {
      top: 0,
      left: 0,
    },
  });

  const context = {
    activePanel,
    setActivePanel,
    activeSubMenu,
    setActiveSubMenu,
    isFontLoading,
    setIsFontLoading,
    isTemplateLoading,
    setIsTemplateLoading,
    currentTemplate,
    setCurrentTemplate,
    defaultFont,
    setDefaultFont,
    defaultFormatSize,
    setDefaultFormatSize,
    isEditorLoading,
    setIsEditorLoading,
    isEnabledDoubleSideMessage,
    setIsEnabledDoubleSideMessage,
    isEnabledDoubleSide,
    setIsEnabledDoubleSide,
    doubleSidedBgImgUrls,
    setDoubleSidedBgImgUrls,
    /*-EditorContext -*/
    activeObject,
    setActiveObject,
    handlers,
    setHandlers,
    sizeFormat,
    setSizeFormat,
    doubleSided,
    setDoubleSided,
    defaultSizeFormat,
    setDefaultSizeFormat,
    zoomRatio,
    setZoomRatio,
    fitZoomRatio,
    setFitZoomRatio,
    fillZoomRatio,
    setFillZoomRatio,
    zoomMode,
    setZoomMode,
    keysMenu,
    setKeysMenu,
    contextMenu,
    setContextMenu,
    contextMenuType,
    setContextMenuType,
    grid,
    setGrid,
    editor,
    setEditor,
    currentBackgroundColor,
    setCurrentBackgroundColor,
    currentBackgroundImage,
    setCurrentBackgroundImage,
  };
  return (
    <TemplateEditorContext.Provider value={context}>
      {children}
    </TemplateEditorContext.Provider>
  );
};
