import { memo } from 'react';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  List,
  ListItem,
  useDisclosure,
} from '@chakra-ui/react';
import { useEditor } from 'components/Canvas';
import { zoomLevels } from 'components/Canvas/common/constants';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import colors from 'theme/Colors.module.scss';

import './Zoom.scss';

const Zoom = () => {
  const editor = useEditor();
  const { zoomRatio, fitZoomRatio, fillZoomRatio, zoomMode } =
    useTemplateEditorContext();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const isZoomToFit =
    zoomRatio.toFixed(2) === fitZoomRatio.toFixed(2) && zoomMode === 'fit';
  const isZoomToFill =
    zoomRatio.toFixed(2) === fillZoomRatio.toFixed(2) && zoomMode === 'fill';

  return (
    <div
      className="template-editor__zoom"
      name="zoom"
      onClick={() => {
        editor.closeKeysMenu();
        editor.closeContextMenu();
      }}
    >
      <Popover placement="top-end" isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button className="zoom__slider-btn" onClick={onToggle}>
            {isOpen && (
              <Slider
                w="150px"
                mr="1rem"
                aria-label="zoom-slider"
                value={(zoomRatio * 100 * 100) / 300}
                onChange={value => {
                  const newValue = (value * 3) / 100;
                  editor.handlers.zoomHandler.zoomToRatio(
                    value >= 5 ? newValue : 0.1
                  );
                }}
                onClick={ev => {
                  ev.preventDefault();
                  ev.stopPropagation();
                }}
              >
                <SliderTrack h="2px">
                  <SliderFilledTrack bgColor={colors.neutral600} />
                </SliderTrack>
                <SliderThumb bgColor={colors.neutral700} />
              </Slider>
            )}
            {`${Math.round(zoomRatio * 100)}%`}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="zoom__popover-content">
          <List className="zoom__ratio-options">
            {zoomLevels.map((zoomRatioVal, index) => {
              return (
                <ListItem
                  className="zoom__ratio-option"
                  key={index}
                  bgColor={
                    zoomRatio.toFixed(2) === zoomRatioVal.toFixed(2) &&
                    colors.neutral100
                  }
                  onClick={() => {
                    editor.handlers.zoomHandler.zoomToRatio(zoomRatioVal);
                    onClose();
                  }}
                >
                  {`${Math.round(zoomRatioVal * 100)}%`}
                </ListItem>
              );
            })}

            <ListItem
              className="zoom__ratio-option"
              bgColor={isZoomToFit && colors.neutral100}
              onClick={() => {
                editor.handlers.zoomHandler.zoomToFit();
                onClose();
              }}
            >
              Fit
            </ListItem>

            <ListItem
              className="zoom__ratio-option"
              bgColor={isZoomToFill && colors.neutral100}
              onClick={() => {
                editor.handlers.zoomHandler.zoomToFill();
                onClose();
              }}
            >
              Fill
            </ListItem>
          </List>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default memo(Zoom);
