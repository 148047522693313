import { useContext } from 'react';
import { TemplateEditorContext } from '../context/TemplateEditorContext';

const useTemplateEditorContext = () => {
  const context = useContext(TemplateEditorContext);

  if (!context) {
    throw new Error(
      'useTemplateEditorContext must be used within a TemplateEditorProvider'
    );
  }

  return context;
};

export default useTemplateEditorContext;
