import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { useFlag } from 'flags';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { paramsToObject } from 'helpers/paramHelpers';
import * as appActions from 'modules/app/appActions';
import * as appSelectors from 'modules/app/appSelectors';
import * as usersActions from 'modules/users/usersActions';
// import * as usersSelectors from 'modules/users/usersSelectors';
import {
  userFieldValidations,
  userPasswordFieldValidations,
} from 'modules/users/userValidations';
import InputFeedback from 'components/InputFeedback/InputFeedback';
// import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';
import FullScreen from 'components/FullScreen/FullScreen';
import colors from 'theme/Colors.module.scss';
import './UserCreation.scss';
import { showMessages } from 'helpers/messageHelpers';

const FORM_DEFAULTS = {
  firstname: '',
  lastname: '',
  password: '',
  password_confirmation: '',
  email: '',
};

const schema = yup.object().shape({
  ...userFieldValidations,
  ...userPasswordFieldValidations,
});

export default props => {
  const { history } = props;
  const { search } = useLocation();
  const params = paramsToObject(search);
  const invitationToken = params.t;
  const sessionUser = useSelector(appSelectors.getSessionUser);
  const messages = useSelector(state => appSelectors.messages(state));

  const [invitation, setInvitation] = useState(null);
  const isReadOnly = !invitation && useFlag(['features', 'isReadOnly']);
  const dispatch = useDispatch();

  const setMessage = payload => dispatch(appActions.setMessage(payload));
  const clearMessage = index => dispatch(appActions.clearMessage(index));

  const checkInvitationToken = () =>
    new Promise((resolve, reject) =>
      dispatch(
        usersActions.checkInvitationToken(invitationToken, resolve, reject)
      )
    );

  useEffect(() => {
    const fetchData = async () => {
      if (!invitationToken) return;
      const invitation = await checkInvitationToken();
      setInvitation(invitation);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationToken]);

  const createUser = data =>
    new Promise((resolve, reject) =>
      dispatch(usersActions.createUser(data, resolve, reject))
    );

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const data = {
      ...values,
      source: 'website',
    };

    if (invitation) {
      data.invitation_token = invitationToken;
    } else if (sessionUser) {
      data.company_id = sessionUser.company_id;
    }

    try {
      const user = await createUser(data);

      if (invitation) {
        await new Promise(resolve => {
          dispatch(
            appActions.login(
              {
                user: {
                  email: user.email,
                  password: values.password,
                },
              },
              resolve
            )
          );

          history.push('/dashboard');
        });
      } else {
        history.push(`/users/${user.id}`);
      }
    } catch (error) {
      const json = await error.json();
      Object.keys(json?.error?.data || {}).map(errorKey => {
        json.error.data[errorKey].map(async message => {
          setMessage({
            message,
            type: 'error',
          });
        });
        return true;
      });
    } finally {
      setSubmitting(false);
    }

    setSubmitting(false);
  };

  const content = (
    <div className="formContainer">
      <Formik
        initialValues={FORM_DEFAULTS}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {form => {
          const { values, isSubmitting, isValid } = form;

          if (invitation && values.email !== invitation.email) {
            form.setFieldValue('email', invitation.email);
          }

          return (
            <Form className="userCreationForm">
              <h1 className="formTitle">Create a new user</h1>

              <label className="formLabel">User Information</label>

              <div className="formSection">
                <Row>
                  <Col sm={6}>
                    <div className="formGroup formGroup-noMargin">
                      <label className="formLabel" htmlFor="firstname">
                        First Name*
                      </label>
                      <Field
                        className="fillWidth"
                        name="firstname"
                        placeholder="First Name*"
                        disabled={isReadOnly}
                      />
                      <InputFeedback name="firstname" />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="formGroup formGroup-noMargin">
                      <label className="formLabel" htmlFor="lastname">
                        Last Name*
                      </label>
                      <Field
                        className="fillWidth"
                        name="lastname"
                        placeholder="Last Name*"
                        disabled={isReadOnly}
                      />
                      <InputFeedback name="lastname" />
                    </div>
                  </Col>
                </Row>
                <hr
                  style={{
                    marginTop: '2rem',
                    marginBottom: '1rem',
                  }}
                />
                <div className="formGroup">
                  <label className="formLabel" htmlFor="lastname">
                    Password*
                  </label>
                  <Field
                    className="fillWidth"
                    type="password"
                    name="password"
                    placeholder="Password*"
                    disabled={isReadOnly}
                  />
                  <InputFeedback name="password" />
                </div>
                <div className="formGroup">
                  <label className="formLabel" htmlFor="lastname">
                    Password Confirmation*
                  </label>
                  <Field
                    className="fillWidth"
                    type="password"
                    name="password_confirmation"
                    placeholder="Password Confirmation*"
                    disabled={isReadOnly}
                  />
                  <InputFeedback name="password_confirmation" />
                </div>
                <hr
                  style={{
                    margin: '1rem 0',
                  }}
                />
                <div className="formGroup formGroup-noMargin">
                  <label className="formLabel" htmlFor="email">
                    Email*
                  </label>
                  <Field
                    className="fillWidth"
                    type="email"
                    name="email"
                    placeholder="Email*"
                    disabled={isReadOnly || !!invitation}
                  />
                  <InputFeedback name="email" />
                </div>
              </div>

              {!isReadOnly && (
                <div className="formActionsArea">
                  <Button
                    icon="plus"
                    iconColor={colors.white}
                    type="submit"
                    text="Create User"
                    textColor={colors.white}
                    btnStyle="solid"
                    hoverColor={colors.secondary900}
                    disabled={isReadOnly || isSubmitting || !isValid}
                  />

                  <p className="footnote">
                    An asterisk (*) marks a required field.
                  </p>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <div>
      <div className="rail rail-rule">
        <Button
          type="button"
          text="Back"
          className="backBtn"
          textColor={colors.slate}
          icon="back"
          btnStyle="transparent"
          url="/users"
        />
      </div>
      {invitationToken ? <FullScreen>{content}</FullScreen> : content}
      {showMessages(messages, clearMessage)}
    </div>
  );
};
