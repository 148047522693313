import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { sortBy } from 'lodash';
import { useFlag } from 'flags';

import {
  creativesActions,
  creativesSelectors,
} from 'modules/creatives/creativesDuck';
import { useBreakpointUp } from 'modules/layout/layoutHooks';
import './Campaigns.scss';
import Filter from 'components/Filter/Filter';
import ShadowRule from 'components/ShadowRule/ShadowRule';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import Button from 'components/Button/Button';
import EntityTable from 'components/EntityTable/EntityTable';
import CampaignCard from './CampaignCard/CampaignCard';
import colors from 'theme/Colors.module.scss';
import * as campaignStatus from './campaignStatus';
import './CampaignCard/CampaignCard.scss';
import Checkbox from 'components/Checkbox/Checkbox';
import { Box } from '@chakra-ui/layout';

const getCampaignHeaders = isReadOnly => {
  const CAMPAIGNS_HEADERS = (
    !isReadOnly
      ? [
          {
            name: '',
            field: 'enabled-control',
            align: 'left',
            width: 35,
          },
        ]
      : []
  ).concat([
    {
      name: 'Campaign Name',
      field: 'name',
      sortable: true,
      sortIcon: 'alpha',
      hasIndicator: true,
      align: 'left',
      width: 200,
    },
    {
      name: 'Priority',
      field: 'priority',
      sortable: true,
      sortIcon: 'numeric',
      sortNullsLast: true,
      align: 'center',
      width: 100,
    },
    {
      name: 'Status',
      field: 'enabled',
      sortable: true,
      sortIcon: 'alpha',
      align: 'center',
      width: 100,
    },
    {
      name: 'Personalized Prints',
      field: 'delivery_limit',
      sortable: true,
      sortIcon: 'numeric',
      align: 'center',
      width: 100,
      whiteSpace: 'pre-wrap',
    },
    {
      name: 'Start Date',
      field: 'start_date',
      sortable: true,
      sortIcon: 'numeric',
      sortNullsLast: true,
      align: 'center',
      width: 100,
    },
    {
      name: 'Ends',
      field: 'end_date',
      sortable: true,
      sortIcon: 'numeric',
      sortNullsLast: true,
      align: 'center',
      width: 100,
    },
    {
      name: 'Template',
      field: 'template',
      sortable: false,
      align: 'center',
      width: 100,
    },
    // {
    //   name: 'Actions',
    //   field: 'actions',
    //   sortable: false,
    // },
  ]);
  return CAMPAIGNS_HEADERS;
};

const CAMPAIGNS_SORT_SUFFIX = [
  {
    field: 'priority',
    direction: 'asc-nulls-last',
  },
];

const CAMPAIGNS_PERSISTENCE_KEYS = ['search', 'enabled'];
const CAMPAIGNS_SEARCH_PARAMETER_FILTER_CHIPS = [
  {
    key: 'search',
    label: 'Search',
  },
];

const isSortedByPriorityAscending = searchParameters =>
  searchParameters.sort.length === 0 ||
  (searchParameters.sort[0].field === 'priority' &&
    searchParameters.sort[0].direction === 'asc-nulls-last');

const Campaigns = props => {
  const { getCampaigns, isMobile } = props;
  const updateCampaignConnected = props.updateCampaign;
  const [updatingCampaign, setUpdatingCampaign] = useState(false);
  const updatingCampaignDebounce = useRef(Symbol());
  const updateCampaign = async (id, payload) => {
    const debounce = (updatingCampaignDebounce.current = Symbol());
    const result = await new Promise((resolve, reject) => {
      updateCampaignConnected(id, payload, resolve, reject);
    });

    setUpdatingCampaign(true);

    setTimeout(() => {
      if (updatingCampaignDebounce.current === debounce) {
        setUpdatingCampaign(false);
      }
    }, 0);

    return result;
  };

  const dispatch = useDispatch();
  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const headers = getCampaignHeaders(isReadOnly);
  const { search } = useLocation();
  const [refreshSymbol, setRefreshSymbol] = useState(Symbol());
  const [searchParameters, setSearchParameters] = useState({
    search: '',
    sort: [],
  });
  const sortedByPriorityAscending =
    isSortedByPriorityAscending(searchParameters);

  const [updatingCampaignPriorities, setUpdatingCampaignPriorities] = useState(
    new Map()
  );

  const handleSearchResultEntities = (campaigns, _total, searchParameters) => {
    if (!isSortedByPriorityAscending(searchParameters)) {
      return;
    }

    const sortedCampaigns = campaigns
      .map(campaign => {
        const updatingPriority = updatingCampaignPriorities.get(campaign.id);

        return {
          ...campaign,
          priority: updatingPriority || campaign.priority,
          originalPriority: campaign.priority,
          updatingPriority,
        };
      })
      // eslint-disable-next-line array-callback-return
      .sort((campaign1, campaign2) => {
        switch (true) {
          case campaign1.priority > campaign2.priority:
            return 1;
          case campaign1.priority < campaign2.priority:
            return -1;
          case campaign1.priority === campaign2.priority:
            const updatingPriority1 = !!campaign1.updatingPriority;
            const updatingPriority2 = !!campaign2.updatingPriority;

            switch (true) {
              case updatingPriority1 && updatingPriority2:
                if (campaign1.originalPriority > campaign2.originalPriority) {
                  return 1;
                } else {
                  return -1;
                }
              case updatingPriority1:
                return -1;
              case updatingPriority2:
                return 1;
              default:
                break;
            }
            break;
          default:
            return -1; // this should not happen
        }
      });
    // // eslint-disable-next-line array-callback-return
    // .sort((campaign1, campaign2) => {
    //   if (!!campaign1.enabled && !campaign2.enabled) {
    //     return -1;
    //   } else if (!campaign1.enabled && !!campaign2.enabled) {
    //     return 1;
    //   }
    // });

    return sortedCampaigns;
  };

  const isBreakpointSmallOrAbove = useBreakpointUp('sm');
  const tableLayout = isBreakpointSmallOrAbove;

  const [editingPrioritySymbol, setEditingPrioritySymbol] = useState(null);

  // Selectors
  const templates = useSelector(state => creativesSelectors.creatives(state));

  // Actions
  const getTemplates = useCallback(
    (payload, doneCallback) =>
      dispatch(creativesActions.getTemplates(payload, doneCallback)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const payload = { current_page: 1, size: 10000 };
    getTemplates(payload);
    getCampaigns(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCampaignStatus = async (campaign, enabled) => {
    if (!campaign) return;

    const payload = {
      campaign: {
        enabled,
      },
    };

    await updateCampaign(campaign.id, payload);

    setRefreshSymbol(Symbol());
  };

  const renderCampaignCard = (campaign, state) => {
    const delivered = (campaign.fulfill_count || 0).toLocaleString();
    const now = new Date();
    const endTime = new Date(campaign.end_date);
    const hasEnded = campaign.end_date && endTime.getTime() < now.getTime();
    const template = templates?.find(
      template => template.id === campaign.ad_template_id
    );
    const newKeyOrder = {
      ...campaign,
      status: campaign?.enabled
        ? hasEnded
          ? campaignStatus.ended
          : campaignStatus.delivering
        : campaignStatus.inactive,
      delivery_limit:
        campaign.delivery_limit === null
          ? `${delivered} / ∞`
          : `${delivered} / ${campaign.delivery_limit.toLocaleString()}`,
    };

    return (
      <CampaignCard
        key={campaign.id}
        draggableProvided={state.draggableProvided}
        draggableSnapshot={state.draggableSnapshot}
        focus={state.focus}
        headers={headers}
        entry={search}
        template={template}
        content={newKeyOrder}
        isMobile={isMobile}
        prependControl={
          !isReadOnly && (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={event => {
                event.stopPropagation();
              }}
            >
              <ToggleSwitch
                color={colors.status00}
                name="cmpToggle"
                disabled={updatingCampaign}
                value={campaign.enabled}
                onChange={event => {
                  const enabled = event.target.checked;
                  updateCampaignStatus(campaign, enabled);
                }}
              />
            </div>
          )
        }
        editingPrioritySymbol={editingPrioritySymbol}
        onEditingPrioritySymbol={setEditingPrioritySymbol}
        updateCampaign={updateCampaign}
        onUpdate={() => setRefreshSymbol(Symbol())}
      />
    );
  };

  const handleReorder = async (event, state) => {
    if (!Array.isArray(state.entities)) return;

    const campaigns = [...state.entities];
    const getNewPriority = (sourceIndex, destinationIndex) => {
      if (destinationIndex === 0) {
        return 1;
      }

      return campaigns[destinationIndex].priority;
    };

    if (event.source && event.destination) {
      const index = event.source.index;
      const toIndex = event.destination.index;

      if (index === toIndex) return;

      const newPriority = getNewPriority(index, toIndex);
      const campaign = campaigns[index];
      const payload = {
        campaign: {
          priority: newPriority,
        },
      };

      campaigns[index] = {
        ...campaign,
        priority: newPriority,
      };

      state.setEntities(
        handleSearchResultEntities(
          campaigns,
          state.total,
          state.searchParameters
        )
      );

      setUpdatingCampaignPriorities(updatingCampaignPriorities =>
        new Map(updatingCampaignPriorities).set(campaign.id, newPriority)
      );

      try {
        await updateCampaign(campaign.id, payload);
      } catch (error) {}

      setRefreshSymbol(Symbol());

      setUpdatingCampaignPriorities(updatingCampaignPriorities => {
        const newUpdatingCampaignPriorities = new Map(
          updatingCampaignPriorities
        );
        newUpdatingCampaignPriorities.delete(campaign.id);

        return newUpdatingCampaignPriorities;
      });
    }
  };

  return (
    <div>
      <Box height="60px" className="cmpHeader">
        {!isReadOnly && (
          <div className="cmpLeft">
            <Button
              type="button"
              url="/campaigns/new"
              icon="add"
              text="New Campaign"
              textColor={colors.slate}
              btnStyle="transparent"
            />
          </div>
        )}
        <div className="cmpNav"></div>
        {/* <div className="cmpRight">
          <div className="legendOpen">Open</div>
          <div className="legendAud">Targeted</div>
        </div> */}
      </Box>
      <Box height="60px" className="rail rail-rule">
        <div className="flexGrow">
          <Filter
            value={searchParameters.search}
            placeholder="Search campaigns"
            onChange={e =>
              setSearchParameters({ ...searchParameters, search: e })
            }
          />
        </div>
        <div className="campaignsFilterHideDisabled">
          <Checkbox
            label="Hide Disabled"
            checked={
              searchParameters?.enabled === true ||
              searchParameters?.enabled === 'true'
            }
            onChange={event => {
              setSearchParameters({
                ...searchParameters,
                enabled: event.target.checked || undefined,
              });
            }}
          />
        </div>
      </Box>

      <div className="cmpBody">
        {/* <Headers
          headers={CAMPAIGNS_HEADERS}
          sort={searchParameters.sort || DEFAULT_CAMPAIGNS_SORT}
          onSort={handleChangeSort}
          gridTemplateAreasPrefix=". "
          gridTemplateColumns="repeat(1, 1.25fr) repeat(5, 1fr) repeat(1, 6rem)"
        />
        <div className="content">
          {!isReadOnly && (
            <Droppable droppableId={uniqueId('dropList_')}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  <LoadingIndicator loading={isCampaignsLoading}>
                    {buildCampaignsList()}
                    {provided.placeholder}
                  </LoadingIndicator>
                </div>
              )}
            </Droppable>
          )}
          {isReadOnly && buildCampaignsList()}
          <Pagination
            pageData={!isEmpty(pageData) && pageData}
            onPageChange={handlePageChange}
          />
        </div> */}
        {!tableLayout && <ShadowRule absolute />}
        <EntityTable
          id="campaigns"
          refreshSymbol={refreshSymbol}
          headers={headers}
          tableProps={{
            tableLayout,
          }}
          headProps={{
            cardLayout: !tableLayout,
            searchParameterFilterChips: CAMPAIGNS_SEARCH_PARAMETER_FILTER_CHIPS,
            searchParameters,
            setSearchParameters,
            style: { whiteSpace: 'pre-wrap' },
          }}
          bodyProps={{
            requestingSilent: updatingCampaign,
          }}
          reorderable={sortedByPriorityAscending}
          onReorder={handleReorder}
          pageable
          sortable
          focusable
          scrollable
          // scrollSpacerHeight={65}
          persistable
          persistenceKeys={CAMPAIGNS_PERSISTENCE_KEYS}
          defaultSearchParameters={searchParameters}
          sortSuffix={CAMPAIGNS_SORT_SUFFIX}
          entityType="campaigns"
          getEntities={searchParameters => {
            return new Promise((resolve, reject) => {
              dispatch(getCampaigns(searchParameters, resolve, reject));
            });
          }}
          renderRow={renderCampaignCard}
          onSearchParameters={setSearchParameters}
          onSearchResultsEntities={handleSearchResultEntities}
        />
      </div>

      <div className="cmpFooter"></div>
    </div>
  );
};

export default Campaigns;
