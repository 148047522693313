import React, { useEffect, useState } from 'react';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  IconButton,
  Button,
  Input,
} from '@chakra-ui/react';
import Icons from '../../../Icons';
import { useEditor } from 'components/Canvas';
import { HexColorPicker } from 'react-colorful';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';

const defaultColor = '#e2e2e2';

function Grid() {
  const editor = useEditor();
  const { grid } = useTemplateEditorContext();

  const [options, setOptions] = useState({
    color: '#ffffff',
    size: 12,
    enabled: false,
  });

  useEffect(() => {
    updateGridOption(grid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid]);

  const updateGridOption = values => {
    setOptions({ ...grid, ...values });
  };

  const changeOption = updatedOptions => {
    editor.handlers.gridHandler.update({ ...grid, ...updatedOptions });
    setOptions({ ...grid, ...updatedOptions });
  };

  const changeGridSize = () => {
    if (
      options.size &&
      parseInt(options.size) >= 10 &&
      parseInt(options.size) <= 100
    ) {
      editor.handlers.gridHandler.update({
        ...grid,
        size: parseInt(options.size),
      });
    } else if (parseInt(options.size) >= 100) {
      editor.handlers.gridHandler.update({ ...grid, size: 100 });
      updateGridOption({ size: 100 });
    } else {
      editor.handlers.gridHandler.update({ ...grid, size: 15 });
      updateGridOption({ size: 15 });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '0.25rem',
      }}
    >
      <IconButton
        color={options.enabled ? '#333333' : '#afafaf'}
        borderColor="#A7A7A7"
        borderRadius="0"
        name="grid"
        variant="ghost"
        size="sm"
        onClick={() => changeOption({ enabled: !grid.enabled })}
        icon={<Icons.Grid size={24} />}
      />

      <Popover placement="bottom-start">
        <PopoverTrigger>
          <IconButton
            color="#333333"
            borderColor="#A7A7A7"
            borderRadius="0"
            name="gridSetting"
            variant="ghost"
            size="sm"
            icon={<Icons.Settings size={24} />}
          />
        </PopoverTrigger>
        <PopoverContent sx={{ width: 'auto', padding: '1.5rem' }}>
          <Box>
            <Box sx={{ fontSize: '14px', paddingBottom: '0.25rem' }}>
              Grid size
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '100px 80px',
                fontSize: '14px',
              }}
            >
              <Box>
                <Input
                  type="number"
                  value={options.size}
                  name="gridSize"
                  onChange={e =>
                    updateGridOption({ size: e.currentTarget.value })
                  }
                  onKeyPress={e => e.key === 'Enter' && changeGridSize(e)}
                  onBlur={changeGridSize}
                  size="sm"
                  borderRadius={0}
                />
              </Box>
              <Button
                onClick={() => changeOption({ size: 15 })}
                size="sm"
                name="resetGridSize"
                borderRadius={0}
              >
                Reset
              </Button>
            </Box>
          </Box>
          <Box>
            <Box sx={{ fontSize: '14px', paddingBottom: '0.25rem' }}>
              Grid color
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '100px 80px',
                fontSize: '14px',
              }}
            >
              <Box>
                <Popover placement="bottom-start">
                  <PopoverTrigger>
                    <IconButton
                      color={options.color}
                      borderRadius="0"
                      variant="outline"
                      name="gridColor"
                      size="sm"
                      icon={<Icons.Square size={32} />}
                      borderColor="#A7A7A7"
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    sx={{ display: 'flex', width: 'auto', padding: '1.5rem' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.75rem',
                      }}
                    >
                      <HexColorPicker
                        name="colorGridPicker"
                        color={options.color}
                        onChange={color => changeOption({ color })}
                      />
                      <Box sx={{ width: '200px' }}>
                        <Input
                          borderRadius={0}
                          textAlign="center"
                          name="colorGridSelector"
                          value={options.color}
                          onChange={e =>
                            setOptions({ ...options, color: e.target.value })
                          }
                        />
                        <Button
                          borderRadius={0}
                          name="applyGridColor"
                          onClick={() => changeOption({ color: options.color })}
                          mt="0.75rem"
                          w="100%"
                        >
                          Apply
                        </Button>
                      </Box>
                    </Box>
                  </PopoverContent>
                </Popover>
              </Box>
              <Button
                name="resetColorGrid"
                size="sm"
                borderRadius={0}
                onClick={() => changeOption({ color: defaultColor })}
              >
                Reset
              </Button>
            </Box>
          </Box>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
export default Grid;
