import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { _, isEmpty } from 'lodash';
import { useFlag } from 'flags';
// import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
// import Headers from 'components/Headers/Headers';
import Button from 'components/Button/Button';
// import Pagination from 'components/Pagination/Pagination';
// import ActionZone from 'components/ActionZone/ActionZone';
import AudienceCard from './AudienceCard/AudienceCard';
import EntityTable from 'components/EntityTable/EntityTable';
import * as appActions from 'modules/app/appActions';
import * as audiencesActions from 'modules/audiences/audiencesActions';
// import * as audiencesSelectors from 'modules/audiences/audiencesSelectors';

import colors from 'theme/Colors.module.scss';
import './AudienceCard/AudienceCard.scss';
import './Audiences.scss';
import Filter from 'components/Filter/Filter';
import { Box } from '@chakra-ui/react';

const AUDIENCE_KEYS = ['name', 'size', 'inserted_at', 'updated_at'];

const Audiences = props => {
  const { isMobile } = props;

  const getAudiencesHeaders = isReadOnly => {
    const AUDIENCES_HEADERS = [
      {
        name: 'Audience Name',
        field: 'name',
        sortable: true,
        sortIcon: 'alpha',
        align: 'center',
        width: isMobile ? '30%' : '50%',
      },
      {
        name: 'Date Created',
        field: 'inserted_at',
        sortable: true,
        sortIcon: 'numeric',
        align: 'center',
      },
      {
        name: 'Last Updated',
        field: 'updated_at',
        sortable: true,
        sortIcon: 'numeric',
        align: 'center',
      },
      // Temporary hidden due to lack of ruleset data
      // {
      //   name: 'Summary',
      //   field: 'summary',
      //   align: 'center',
      // },
    ].concat(
      !isReadOnly
        ? [
            {
              name: 'Remove',
              field: 'actions',
              align: 'center',
              width: '80px',
            },
          ]
        : []
    );

    return AUDIENCES_HEADERS;
  };
  const dispatch = useDispatch();
  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const [headers, setHeaders] = useState(getAudiencesHeaders(isReadOnly));
  const [searchParameters, setSearchParameters] = useState({
    search: '',
    sort: [{ field: 'name', direction: 'asc' }],
  });
  const [refreshSymbol, setRefreshSymbol] = useState(Symbol());
  const handleDeleteAudience = () => {
    setRefreshSymbol(Symbol());
  };

  // Actions
  const setMessage = message => dispatch(appActions.setMessage(message));
  const getAudiences = (payload, doneCb, failCb) =>
    dispatch(audiencesActions.getAudiences(payload, doneCb, failCb));
  const deleteAudience = id => {
    dispatch(audiencesActions.deleteAudience(id));
    setMessage({
      message: 'Audience removed successfully',
      type: 'success',
    });
  };

  useEffect(() => {
    if (isMobile && headers.length === 5) headers.splice(2, 1);
    headers[1].name = 'Date';
    headers[1].sortable = false;
    setHeaders(headers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, isMobile]);

  return (
    <>
      <Box className="audHeader" height="60px">
        <div className="audLeft">
          {!isReadOnly && (
            <Button
              type="button"
              url="/audiences/new"
              icon="add"
              text="New Audience"
              textColor={colors.slate}
              btnStyle="transparent"
            />
          )}
        </div>

        <div className="audNav"></div>

        <div className="audRight">
          <div className="legend">Currently used in campaign.</div>
        </div>
      </Box>

      <div className="audBody">
        <Box height="60px" className="rail rail-rule">
          <Filter
            value={searchParameters.search}
            placeholder="Search audiences"
            onChange={e =>
              setSearchParameters({ ...searchParameters, search: e })
            }
          />
        </Box>
        {/* <Headers
          className="audTableHeader"
          headers={AUDIENCES_HEADERS}
          sort={searchParameters.sort || DEFAULT_AUDIENCES_SORT}
          onSort={handleChangeSort}
          girdTemplatePrefix="."
          gridTemplateColumns="repeat(4, 1fr) 4rem"
        />

        <div className="content">
          <LoadingIndicator loading={isAudiencesLoading}>
            {renderListing(audiencesByUserId)}
          </LoadingIndicator>

          <Pagination
            pageData={!isEmpty(pageData) && pageData}
            onPageChange={handlePageChange}
          />
        </div> */}
        <EntityTable
          headers={headers}
          pageable
          sortable
          focusable
          scrollable
          // scrollSpacerHeight={65}
          persistable
          entityType="audiences"
          refreshSymbol={refreshSymbol}
          defaultSearchParameters={searchParameters}
          getEntities={searchParameters => {
            return new Promise((resolve, reject) => {
              getAudiences(searchParameters, resolve, reject);
            });
          }}
          renderRow={(audience, state) => {
            return (
              <AudienceCard
                key={audience.id}
                headers={headers}
                focus={state.focus}
                isMobile={isMobile}
                sortedKeys={AUDIENCE_KEYS}
                content={audience}
                status="ok"
                deleteAudience={deleteAudience}
                onDelete={handleDeleteAudience}
              />
            );
          }}
        />
      </div>

      <div className="audFooter"></div>
    </>
  );
};

export default Audiences;
