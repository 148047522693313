import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-grid-system';
// import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';

import * as usersActions from 'modules/users/usersActions';
// import * as usersSelectors from 'modules/users/usersSelectors';
import { getSessionUser } from 'modules/app/appSelectors';
import { userValidation } from 'modules/users/userValidations';
import { useFlag } from 'flags';
import InputFeedback from 'components/InputFeedback/InputFeedback';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';
import colors from 'theme/Colors.module.scss';
import './MyUserDetail.scss';
import { Divider } from '@chakra-ui/layout';

const defaultValues = {
  company_id: '',
  email: '',
  firstname: '',
  id: '',
  lastname: '',
  source: '',
};

export default ({ match }) => {
  const sessionUser = useSelector(getSessionUser);
  const { id } = sessionUser;
  const [user, setUser] = useState(defaultValues);
  const [loaded, setLoaded] = useState(null);
  const [error, setError] = useState(null);
  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const disabled = isReadOnly;
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await new Promise((resolve, reject) =>
          dispatch(usersActions.getUserById(id, resolve, reject))
        );
        setUser({
          ...user,
          password: '',
          password_confirmation: '',
        });
      } catch (err) {
        setError(err);
        console.error(error);
      } finally {
        setLoaded(true);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const updateUser = useCallback(
    data =>
      new Promise((resolve, reject) =>
        dispatch(usersActions.updateUserById(id, data, resolve, reject))
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const handleSubmit = async (values, form) => {
    form.setSubmitting(true);

    try {
      const updatedUser = await updateUser(values);

      setUser(updatedUser);
    } catch (error) {
      setError(error);
    } finally {
      form.setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="rail rail-rule">
        <Button
          type="button"
          text="Back"
          className="backBtn"
          textColor={colors.slate}
          icon="back"
          btnStyle="transparent"
          url="/users"
        />
      </div>
      {loaded &&
        (user ? (
          <div className="formContainer">
            <Formik
              enableReinitialize
              validateOnMount
              initialValues={user}
              validationSchema={userValidation}
              onSubmit={handleSubmit}
            >
              {form => {
                const { isSubmitting, dirty } = form;

                return (
                  <Form className="myUserDetailForm">
                    <h1 className="formTitle">My Account</h1>
                    <p className="formLabel">User Information</p>
                    <div className="formSection">
                      <Row>
                        <Col sm={6}>
                          <div className="formGroup-noMargin">
                            <label htmlFor="firstname" className="formLabel">
                              First Name
                            </label>
                            <Field
                              className="fillWidth"
                              name="firstname"
                              placeholder="First Name*"
                              disabled={disabled || isSubmitting}
                            />
                            <InputFeedback name="firstname" />
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="formGroup-noMargin">
                            <label htmlFor="lastname" className="formLabel">
                              Last Name
                            </label>
                            <Field
                              className="fillWidth"
                              name="lastname"
                              placeholder="Last Name*"
                              disabled={disabled || isSubmitting}
                            />
                            <InputFeedback name="lastname" />
                          </div>
                        </Col>
                      </Row>
                      <Divider
                        margin={'32px 0'}
                        border={'none'}
                        borderTop={'1px solid #e2e2e2'}
                      />
                      <div className="formGroup">
                        <label className="formLabel" htmlFor="lastname">
                          Password*
                        </label>
                        <Field
                          className="fillWidth"
                          type="password"
                          name="password"
                          placeholder="Password*"
                          disabled={disabled || isSubmitting}
                        />
                        <InputFeedback name="password" />
                      </div>
                      <div className="formGroup">
                        <label className="formLabel" htmlFor="lastname">
                          Password Confirmation*
                        </label>
                        <Field
                          className="fillWidth"
                          type="password"
                          name="password_confirmation"
                          placeholder="Password Confirmation*"
                          disabled={disabled || isSubmitting}
                        />
                        <InputFeedback name="password_confirmation" />
                      </div>
                      <Divider
                        margin={'32px 0'}
                        border={'none'}
                        borderTop={'1px solid #e2e2e2'}
                      />
                      <div className="formGroup formGroup-noMargin">
                        <label
                          htmlFor="email"
                          className="formLabel formLabel-withTooltip"
                        >
                          Email
                          <Tooltip
                            tipText="Updating your email address affects the login credentials."
                            direction="right"
                          />
                        </label>
                        <Field
                          className="fillWidth"
                          type="email"
                          name="email"
                          placeholder="Email*"
                          disabled={disabled || isSubmitting}
                        />
                        <InputFeedback name="email" />
                      </div>
                    </div>
                    {!disabled && (
                      <div className="formActionsArea">
                        <Button
                          type="submit"
                          text="Update User"
                          textColor={colors.white}
                          btnStyle="solid"
                          hoverColor={colors.secondary900}
                          disabled={disabled || isSubmitting || !dirty}
                        />
                        <p className="footnote">
                          An asterisk (*) marks a required field.
                        </p>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        ) : (
          'Not found'
        ))}
    </div>
  );
};
