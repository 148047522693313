import Add from './Add';
import Background from './Background';
import Edit from './Edit';
import Elements from './Elements';
import Text from './Text';
import QR from './QR';
import Barcode from './Barcode';
import Delete from './Delete';
import Locked from './Locked';
import UnLocked from './Unlocked';
import Bold from './Bold';
import Italic from './Italic';
import TextColor from './TextColor';
// import FillColor from './FillColor.'
import Images from './Images';
import TextAlignCenter from './TextAlignCenter';
import TextAlignRight from './TextAlignRight';
import TextAlignLeft from './TextAlignLeft';
import TextAlignJustify from './TextAlignJustify';
import Underline from './Underline';
import Opacity from './Opacity.';
import Duplicate from './Duplicate';
import ToFront from './ToFront';
import Forward from './Forward';
import ToBack from './ToBack';
import Backward from './Backward';
import Undo from './Undo';
import Redo from './Redo';
import ChevronLeft from './ChevronLeft';
import ChevronDown from './ChevronDown';
import Spacing from './Spacing';
import CopyStyle from './CopyStyle';
import Settings from './Settings';
import Grid from './Grid';
import PanoramaVertical from './PanoramaVertical';
import PanoramaHorizontal from './PanoramaHorizontal';
import Rect from './Rect';
import FormatColorText from './FormatColorText';
import AlignBottom from './AlignBottom';
import AlignCenter from './AlignCenter';
import AlignTop from './AlignTop';
import AlignMiddle from './AlignMiddle';
import AlignRight from './AlignRight';
import AlignLeft from './AlignLeft';
import Plus from './Plus';
import Minus from './Minus';
import Download from './Download';
import Cross from './Cross';
import Square from './Square';
import Expand from './Expand';
import Check from './Check';
import Search from './Search';
import Filter from './Filter';
import Info from './Info';
import BringForward from './BringForward';
import BringToFront from './BringToFront';
import SendBackward from './SendBackward';
import SendToBack from './SendToBack';
import Calendar from './Calendar';

import DoubleSide from './DoubleSide';

import UploadCloud from './UploadCloud';
import Checked from './Checked';
import Warning from './Warning';
import AddNewPage from './AddNewPage';
import Prev from './Prev';
import Next from './Next';
import ColorPicker from './ColorPicker';
import DocumentColor from './DocumentColor';
import DefaultColor from './DefaultColor';
import PlusTE from './PlusTE';

// Side Menu
import Home from './Home';
import Analytics from './Analytics';
import Campaigns from './Campaigns';
import Audiences from './Audiences';
import Templates from './Templates';
import MyAccount from './MyAccount';
import ManageUsers from './ManageUsers';
import ManageFonts from './ManageFonts';
import OrderFields from './OrderFields';
import Logout from './Logout';

class Icons {
  static Add = Add;
  static AddNewPage = AddNewPage;
  static AlignBottom = AlignBottom;
  static AlignCenter = AlignCenter;
  static AlignTop = AlignTop;
  static AlignMiddle = AlignMiddle;
  static AlignRight = AlignRight;
  static AlignLeft = AlignLeft;

  static Background = Background;
  static Edit = Edit;
  static Elements = Elements;
  static Text = Text;
  static Delete = Delete;
  static Locked = Locked;
  static UnLocked = UnLocked;
  static Bold = Bold;
  static Italic = Italic;
  static TextColor = TextColor;
  static Calendar = Calendar;
  // static FillColor = FillColor
  static Images = Images;
  static QR = QR;
  static Barcode = Barcode;
  static TextAlignRight = TextAlignRight;
  static TextAlignCenter = TextAlignCenter;
  static TextAlignLeft = TextAlignLeft;
  static TextAlignJustify = TextAlignJustify;
  static Underline = Underline;
  static Opacity = Opacity;
  static Duplicate = Duplicate;
  static ToFront = ToFront;
  static Forward = Forward;
  static ToBack = ToBack;
  static Backward = Backward;
  static Undo = Undo;
  static Redo = Redo;
  static ChevronLeft = ChevronLeft;
  static ChevronDown = ChevronDown;
  static Spacing = Spacing;
  static CopyStyle = CopyStyle;
  static Settings = Settings;
  static Grid = Grid;
  static PanoramaVertical = PanoramaVertical;
  static PanoramaHorizontal = PanoramaHorizontal;
  static Rect = Rect;
  static DoubleSide = DoubleSide;
  static FormatColorText = FormatColorText;
  static DocumentColor = DocumentColor;
  static DefaultColor = DefaultColor;
  static PlusTE = PlusTE;

  static Plus = Plus;
  static Minus = Minus;
  static Download = Download;
  static Cross = Cross;
  static Square = Square;
  static Expand = Expand;
  static Check = Check;
  static Search = Search;
  static Filter = Filter;
  static Info = Info;
  static Prev = Prev;
  static Next = Next;
  static ColorPicker = ColorPicker;

  static BringForward = BringForward;
  static BringToFront = BringToFront;
  static SendBackward = SendBackward;
  static SendToBack = SendToBack;

  static UploadCloud = UploadCloud;
  static Checked = Checked;
  static Warning = Warning;

  // Side Menu
  static Home = Home;
  static Analytics = Analytics;
  static Campaigns = Campaigns;
  static Audiences = Audiences;
  static Templates = Templates;
  static MyAccount = MyAccount;
  static ManageUsers = ManageUsers;
  static ManageFonts = ManageFonts;
  static OrderFields = OrderFields;
  static Logout = Logout;
}

export default Icons;
