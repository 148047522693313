import { fabric } from 'fabric';
import exportObject from 'components/Canvas/utils/fabricToObject';
import objectToFabric from 'components/Canvas/utils/objectToFabric';
import { parseSizeFormat } from 'components/Canvas/utils/transform';
import { PROPERTIES_TO_INCLUDE } from '../common/constants';
import BaseHandler from './BaseHandler';
import { isInsideFrontSideFrame } from '../utils/frame';

class TemplateHandler extends BaseHandler {
  /**
   * Export template
   * 1. Parse frame params
   * 2. Parse objects
   * 3. Parse background
   * @returns template
   */
  exportToJSON() {
    const canvasJSON = this.canvas.toJSON(PROPERTIES_TO_INCLUDE);
    const frameJSON = this.handlers.frameHandler.getOptionsFrame();
    const sizeFormat = this.handlers.frameHandler.sizeFormat;
    const backgrounds = this.getBackgrounds();
    const objects = canvasJSON.objects;
    const filteredObjects =
      objects.filter(
        object => object.type !== 'Frame' && object.type !== 'BackgroundImage'
      ) || [];

    if (this.editor.doubleSided === false) {
      const template = {
        ...backgrounds.background,
        is_portrait: sizeFormat.isPortrait,
        size_format_id: sizeFormat.id,
        ad_template_vars: [],
        ad_template_pages: [],
        double_sided: this.editor.doubleSided,
      };

      filteredObjects.forEach((object, index) => {
        if (object.id !== frameJSON.id) {
          const exportedObject = exportObject.run(
            {
              ...object,
              z_index: index * 1.0,
            },
            sizeFormat,
            frameJSON
          );
          template.ad_template_vars =
            template.ad_template_vars.concat(exportedObject);
        }
      });
      return template;

      // REVISIT after backend api update
      /*
      const template = {
        is_portrait: sizeFormat.isPortrait,
        size_format_id: sizeFormat.id,
        ad_template_vars: [],
        ad_template_pages: [],
        double_sided: this.editor.doubleSided,
      };

      //get frames information
      const frames = this.handlers.frameHandler.getFrames();

      // add pages
      template.ad_template_pages = [
        {
          ad_template_vars: [],
          page_num: 1,
          ...backgrounds.firstBackground,
          id: frames[0]['ad_template_page_id'],
        },
      ];

      // one frames
      // push elements in the first page
      filteredObjects.forEach((object, index) => {
        const exportedObject = exportObject.run(
          {
            ...object,
            z_index: index * 1.0,
          },
          sizeFormat,
          frameJSON
        );
        template.ad_template_pages[0].ad_template_vars.push(exportedObject);
        template.ad_template_vars = [];
      });

      template.bg_img = null;

      return template;
      */
    } else {
      const template = {
        is_portrait: sizeFormat.isPortrait,
        size_format_id: sizeFormat.id,
        ad_template_vars: [],
        ad_template_pages: [],
        double_sided: this.editor.doubleSided,
      };

      //get frames information
      const frames = this.handlers.frameHandler.getFrames();

      // add pages
      template.ad_template_pages =
        frames.length === 1
          ? (template.ad_template_pages = [
              {
                ad_template_vars: [],
                page_num: 1,
                ...backgrounds.firstBackground,
                id: frames[0]['ad_template_page_id'],
              },
            ])
          : (template.ad_template_pages = [
              {
                ad_template_vars: [],
                page_num: 1,
                ...backgrounds.firstBackground,
                id: frames[0]['ad_template_page_id'],
              },
              {
                ad_template_vars: [],
                page_num: 2,
                ...backgrounds.secondBackground,
                id: frames[1]['ad_template_page_id'],
              },
            ]);

      if (frames.length === 1) {
        // one frames
        // push elements in the first page
        filteredObjects.forEach((object, index) => {
          const exportedObject = exportObject.run(
            {
              ...object,
              z_index: index * 1.0,
            },
            sizeFormat,
            frameJSON
          );
          template.ad_template_pages[0].ad_template_vars.push(exportedObject);
          template.ad_template_vars = [];
        });
      } else {
        // multiple frames
        filteredObjects.forEach((object, index) => {
          // push elements in the first page
          if (isInsideFrontSideFrame(frames, object)) {
            const exportedObject = exportObject.run(
              {
                ...object,
                z_index: index * 1.0,
              },
              sizeFormat,
              frameJSON
            );
            template.ad_template_pages[0].ad_template_vars.push(exportedObject);
            template.ad_template_vars = [];
          } else {
            // push elements in the second page
            const frameJSON = this.handlers.frameHandler.getBackFrameOptions();
            const exportedObject = exportObject.run(
              {
                ...object,
                z_index: index * 1.0,
              },
              sizeFormat,
              frameJSON
            );
            template.ad_template_pages[1].ad_template_vars.push(exportedObject);
            template.ad_template_vars = [];
          }
        });
      }
      template.bg_img = null;
      return template;
    }
  }

  /**
   * Import template
   * 1. Remove all objects from canvas, but frame
   * 2. Update frame size
   * 3. Update background
   * 4. Load objects to canvas
   * @param {options} Template options
   */
  async importFromJSON(options) {
    this.handlers.objectsHandler.clear(true);

    const { size_format, template } = options;
    const sizeFormat = parseSizeFormat(size_format);
    this.handlers.frameHandler.create({
      ...sizeFormat,
      isPortrait: size_format.isPortrait,
    });
    this.editor.doubleSided = template.double_sided;
    // this.context.setDoubleSided(template.double_sided);

    if (this.editor.doubleSided && template.ad_template_pages.length > 1) {
      this.handlers.frameHandler.addNewFrame();
    }

    await this.setBackground(template);
    let adTemplateVars = template.ad_template_vars;
    const [frontFrame, backFrame] = this.handlers.frameHandler.getFrames();
    const frontFrameOptions = this.handlers.frameHandler.getOptionsFrame();
    if (this.editor.doubleSided && template.ad_template_pages.length > 1) {
      this.handlers.frameHandler.setAdTemplatePageId(
        template.ad_template_pages[0].id,
        template.ad_template_pages[1].id
      );
      const backFrameOptions = this.handlers.frameHandler.getBackFrameOptions();
      for (let i = 0; i < template.ad_template_pages.length; i++) {
        // Sort ad_template_vars by z_index for the current page
        const sortedAdTemplateVars = (template.ad_template_pages[i].ad_template_vars || []).sort((a, b) => {
          if (!isNaN(a?.z_index) && !isNaN(b?.z_index)) {
            return a.z_index - b.z_index; // Sorting in ascending order of z_index
          } else {
            return 0;
          }
        });
      
        // Iterate over the sorted ad_template_vars
        for (let adTemplateVar of sortedAdTemplateVars) {
          const frameOptions = !i ? frontFrameOptions : backFrameOptions;
          const frame = !i ? frontFrame : backFrame;

          const element = await objectToFabric.run(
            adTemplateVar,
            { ...sizeFormat, isPortrait: size_format.isPortrait },
            frameOptions,
            frame
          );
          
          if (element) {
            this.canvas.add(element); 
          } else {
            console.log('UNABLE TO LOAD OBJECT: ', adTemplateVar);
          }
        }
      }
      this.canvas.clipPath = new fabric.Group(
        [frontFrame, backFrame].map(
          frame =>
            new fabric.Rect({
              width: frame.width + 5,
              height: frame.height + 4,
              left: frame.left - 3,
              top: frame.top - 2,
            })
        )
      );
    }

    if (this.editor.doubleSided && template.ad_template_pages.length === 1) {
      adTemplateVars = template.ad_template_pages[0].ad_template_vars;
    }

    if (
      (this.editor.doubleSided && template.ad_template_pages.length === 1) ||
      this.editor.doubleSided === false
    ) {
      (adTemplateVars || []).sort((a, b) => {
        if (!isNaN(a?.z_index) && !isNaN(b?.z_index)) {
          if (a.z_index < b.z_index) return -1;
          else if (a.z_index > b.z_index) return 1;
          return 0;
        } else return 0;
      });
      for (const adTemplateVar of adTemplateVars) {
        const element = await objectToFabric.run(
          adTemplateVar,
          { ...sizeFormat, isPortrait: size_format.isPortrait },
          frontFrameOptions,
          frontFrame
        );

        this.canvas.clipPath = new fabric.Rect({
          width: frontFrame.width + 5,
          height: frontFrame.height + 4,
          left: frontFrame.left - 3,
          top: frontFrame.top - 2,
        });
        if (element) {
          var obj = element;
          if (obj.metadata.importInit?.transformMatrix) {
            var currentT = obj.calcTransformMatrix();
            var transformMatrix = obj.metadata.importInit?.transformMatrix;
            // multiply the matrices together to get the combined transform
            var tm = [
              transformMatrix.a,
              transformMatrix.b,
              transformMatrix.c,
              transformMatrix.d,
              transformMatrix.tx,
              transformMatrix.ty,
            ];
            var mT = fabric.util.multiplyTransformMatrices(currentT, tm);
            // Unfold the matrix in a combination of scaleX, scaleY, skewX, skewY...
            var opts = fabric.util.qrDecompose(mT);

            var newCenter = { x: opts.translateX, y: opts.translateY };
            //obj.set(opts);
            // position the object in the center given from translateX and translateY
            obj.setPositionByOrigin(newCenter, 'center', 'center');
          }

          if (obj.metadata?.styles) obj.set('styles', obj.metadata?.styles);

          this.canvas.add(obj);
        } else {
          console.log('UNABLE TO LOAD OBJECT: ', adTemplateVar);
        }
      }
    }

    this.handlers.historyHandler.save('template:load');
    if (!options.local) {
      this.handlers.historyHandler.clear();
    }
    // this.handlers.zoomHandler.zoomToFit();
    this.handlers.scrollbarHandler.updateScrollPosition();
  }

  getBackgrounds = () => {
    const frames = this.handlers.frameHandler.getFrames();
    const canvasJSON = this.canvas.toJSON(PROPERTIES_TO_INCLUDE);
    const frameJSON = this.handlers.frameHandler.getOptionsFrame();
    const backgroundImage = canvasJSON.objects.find(
      object => object.type === 'BackgroundImage'
    );
    let background;

    if (this.editor.doubleSided === false) {
      if (backgroundImage) {
        background = {
          bg_img: {
            original_url: backgroundImage.src,
            thumb_url: null,
          },
          metadata: {
            bg_color: frameJSON.fill ? frameJSON.fill : '#ffffff',
          },
        };
      } else {
        background = {
          metadata: {
            bg_color: frameJSON.fill
              ? frameJSON.fill
              : frameJSON.backgroundColor,
          },
        };
      }

      return { background };

      // REVISIT after backend api update
      /*
      let firstBackgroundFrame = canvasJSON.objects.find(
        object =>
          object.type === 'BackgroundImage' &&
          object.clipPath.id === frames[0].id
      );

      let firstBackground = null;

      if (firstBackgroundFrame) {
        firstBackground = {
          bg_img: {
            original_url: firstBackgroundFrame.src,
            fileName: `frontBG-${new Date().getTime()}`,
            thumb_url: null,
          },
          metadata: null,
        };
      } else {
        firstBackground = {
          metadata: {
            bg_color: frameJSON.fill
              ? frameJSON.fill
              : frameJSON.backgroundColor,
          },
        };
      }

      return { firstBackground };
      */
    } else {
      let firstBackgroundFrame = canvasJSON.objects.find(
        object =>
          object.type === 'BackgroundImage' &&
          object.clipPath.id === frames[0].id
      );
      const secondBackgroundFrame =
        frames.length > 1
          ? canvasJSON.objects.find(
              object =>
                object.type === 'BackgroundImage' &&
                object.clipPath.id === frames[1].id
            )
          : false;

      let firstBackground = null;
      let secondBackground = null;

      if (firstBackgroundFrame) {
        firstBackground = {
          bg_img: {
            original_url: firstBackgroundFrame.src,
            fileName: `frontBG-${new Date().getTime()}`,
            thumb_url: null,
          },
          metadata: null,
        };
      } else {
        firstBackground = {
          metadata: {
            bg_color: frameJSON.fill
              ? frameJSON.fill
              : frameJSON.backgroundColor,
          },
        };
      }

      if (this.handlers.frameHandler.getFrames().length > 1) {
        if (secondBackgroundFrame) {
          secondBackground = {
            bg_img: {
              original_url: secondBackgroundFrame.src,
              fileName: `backBG-${new Date().getTime()}`,
              thumb_url: null,
            },
            metadata: null,
          };
        } else {
          const secondFrameOptions =
            this.handlers.frameHandler.getBackFrameOptions();
          secondBackground = {
            metadata: {
              bg_color: secondFrameOptions.fill
                ? secondFrameOptions.fill
                : secondFrameOptions.backgroundColor,
            },
          };
        }
      }

      return { firstBackground, secondBackground };
    }
  };

  setBackground = async template => {
    const backgroundColor = template.metadata?.bg_color || '#ffffff';
    const backgroundImage = template.bg_img?.original_url;

    if (this.editor.doubleSided === false) {
      if (backgroundImage) {
        await this.handlers.frameHandler.setBackgroundImageURL(backgroundImage);
      } else {
        this.handlers.frameHandler.setBackgroundColor(backgroundColor);
      }
    } else {
      const backgroundImages = [];
      const backgroundColors = [];

      if (template.ad_template_pages.find(page => page.bg_img)) {
        template.ad_template_pages
          .sort((a, b) => a.page_num - b.page_num)
          .map(object => {
            const bgImg = object?.bg_img?.original_url || null;
            backgroundImages.push(bgImg);
            return bgImg;
          });
        await this.handlers.frameHandler.setBackgroundDoubleSided(
          backgroundImages
        );

        // this.context.setDoubleSidedBgImgUrls(backgroundImages);
      } else {
        template.ad_template_pages
          .sort((a, b) => a.page_num - b.page_num)
          .map(object => {
            const bgColor = object?.metadata?.bg_color || null;
            backgroundColors.push(bgColor);
            return bgColor;
          });
        await this.handlers.frameHandler.setBackgroundColorDoubleSided(
          backgroundColors
        );
      }
    }
  };

  async restoreTemplate(options) {
    this.handlers.objectsHandler.clear(true);
    const { sizeFormat, template } = options;

    // const sizeFormat = parseSizeFormat(size_format);
    this.handlers.frameHandler.create(sizeFormat);

    const backgroundColor = template.metadata?.bg_color || '#ffffff';
    const backgroundImage = template.bg_img?.original_url;

    if (backgroundImage) {
      await this.handlers.frameHandler.setBackgroundImageURL(backgroundImage);
    } else {
      this.handlers.frameHandler.setBackgroundColor(backgroundColor);
    }

    const frameOptions = this.handlers.frameHandler.getOptions();
    const adTemplateVars = template.ad_template_vars;
    for (const adTemplateVar of adTemplateVars) {
      const element = await objectToFabric.run(
        adTemplateVar,
        sizeFormat,
        frameOptions
      );
      if (element) {
        this.canvas.add(element);
      } else {
        console.log('UNABLE TO LOAD OBJECT: ', adTemplateVar);
      }
    }
  }

  setNewEntityIds = template => {
    this.setIdForTemplateVars(template);
    for (const pg of template.ad_template_pages) {
      this.setIdForTemplateVars(pg);
    }
  };

  // atv_container (ad_template_var container): ad_template or ad_template_page
  setIdForTemplateVars = atv_container => {
    let els = this.canvas
      .getObjects()
      .filter(o => o.metadata?.__session_var_id);
    if (els.length === 0) return;

    for (const atv of atv_container.ad_template_vars) {
      let el = els.find(({ metadata }) => {
        return (
          metadata['__session_var_id'] &&
          metadata['__session_var_id'] === atv.metadata['__session_var_id']
        );
      });
      if (el) {
        el.set('id', atv.id);
        // clean up __session_var_id id
        delete el.metadata.__session_var_id;
        el.set('metadata', el.metadata);
      }
    }
  };
}

export default TemplateHandler;
