import React, { useState, useEffect } from 'react';
import Icons from '../../Icons';
import {
  Box,
  IconButton,
  Button,
  Center,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';
import Common from './components/Common';
import Spacing from './components/Spacing';
import FontSize from './components/FontSize';
import TextColor from './components/TextColor';
import { useEditor } from 'components/Canvas';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import { useSelector, useDispatch } from 'react-redux';
import { templateEditorSelectors } from 'modules/templateEditor/templateEditorDuck';
import * as appActions from 'modules/app/appActions';

const fontFaceSet = document.fonts;

const defaultOptions = {
  name: 'NO FONT',
  styles: [],
  selectedFont: {
    style: 'Regular',
  },
};

function Text() {
  const dispatch = useDispatch();

  const [publicFonts, setPublicFonts] = useState(null);
  const [privateFonts, setPrivateFonts] = useState([]);
  const [fontFamily, setFontFamily] = useState({
    name: 'Arial',
    styles: [],
    selectedFont: {
      style: 'Regular',
    },
  });
  const [options, setOptions] = useState(defaultOptions);

  const editor = useEditor();
  const { activeObject, setActiveSubMenu, isFontLoading, setIsFontLoading } =
    useTemplateEditorContext();

  // Selectors
  const userFonts = useSelector(state =>
    templateEditorSelectors.userFonts(state)
  );

  // Actions
  const setMessage = payload => dispatch(appActions.setMessage(payload));

  useEffect(() => {
    if (activeObject && publicFonts && activeObject.type.match(/Text/g)) {
      const activeFontFamily = activeObject.fontFamily;
      let selectedFont = publicFonts.find(
        f =>
          f.name === activeFontFamily ||
          f.postscriptname === activeObject.metadata.postscriptname ||
          f.postscriptname === activeObject.metadata.postScriptName
      );

      if (selectedFont) {
        setFontFamily({
          name: selectedFont.family,
          styles: userFonts.public[selectedFont.family],
          selectedFont,
        });
      } else {
        selectedFont = privateFonts.find(
          f =>
            f.name === activeFontFamily ||
            f.postscriptname === activeObject.metadata.postscriptname ||
            f.postscriptname === activeObject.metadata.postScriptName
        );

        if (selectedFont) {
          setFontFamily({
            name: selectedFont.family,
            styles: [selectedFont],
            selectedFont,
          });
        } else {
          setMessage({
            message: `Font ${activeFontFamily} not found`,
            type: 'error',
          });
          setFontFamily(defaultOptions);
        }
      }
      setOptions({
        ...options,
        fontWeight: activeObject.fontWeight,
        fontStyle: activeObject.fontStyle,
        fontFamily: activeObject.fontFamily,
        textAligh: activeObject.textAlign,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject, publicFonts, privateFonts, userFonts]);

  useEffect(() => {
    const publicFonts = userFonts.public;
    const privateFonts = userFonts.private;
    if (publicFonts) {
      let fonts = [];
      Object.keys(publicFonts).forEach(key => {
        fonts = fonts.concat(publicFonts[key]);
      });
      setPublicFonts(fonts);
    }
    if (privateFonts) {
      setPrivateFonts(privateFonts);
    }
  }, [userFonts]);

  useEffect(() => {
    let handleChange = () => {
      const activeFontFamily = activeObject.fontFamily;
      let selectedFont = (publicFonts || []).find(
        f =>
          f.name === activeFontFamily ||
          f.postscriptname === activeObject.metadata.postscriptname ||
          f.postscriptname === activeObject.metadata.postScriptName
      );

      if (selectedFont) {
        setFontFamily({
          name: selectedFont.family,
          styles: userFonts.public[selectedFont.family],
          selectedFont,
        });
      } else {
        selectedFont = privateFonts.find(
          f =>
            f.name === activeFontFamily ||
            f.postscriptname === activeObject.metadata.postscriptname ||
            f.postscriptname === activeObject.metadata.postScriptName
        );

        if (selectedFont) {
          setFontFamily({
            name: selectedFont.family,
            styles: [selectedFont],
            selectedFont,
          });
        } else {
          setMessage({
            message: `Font ${activeFontFamily} not found`,
            type: 'error',
          });
          setFontFamily(defaultOptions);
        }
      }

      // recalculate keyValues bounds
      if (activeObject && activeObject.type === 'DynamicText') {
        activeObject.updateParams();
      }

      setOptions({
        ...options,
        fontWeight: activeObject.fontWeight,
        fontStyle: activeObject.fontStyle,
        fontFamily: activeObject.fontFamily,
        textAligh: activeObject.textAlign,
      });
    };
    if (editor && activeObject && activeObject.type.match(/Text/g)) {
      editor.on('history:changed', handleChange);
    }
    return () => {
      editor.off('history:changed', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, publicFonts, privateFonts, activeObject]);

  if (!activeObject) {
    return null;
  }

  const isFontLoaded = fontFace => {
    let fontExist = false;

    fontFaceSet.forEach(fontInSet => {
      if (fontInSet.family === fontFace.family) {
        fontExist = true;
      }
    });

    return fontExist;
  };

  const handleFontFamilyChange = async fontItem => {
    if (editor) {
      const font = {
        name: fontItem.name,
        url: fontItem.url,
      };
      const fontFace = new FontFace(font.name, `url(${font.url})`);

      const __fontFilename = font.url
        .substring(font.url.lastIndexOf('/') + 1)
        .split('?')[0];

      await fontFaceSet.ready.then(() => {
        if (isFontLoaded(fontFace)) {
          editor.update({
            fontFamily: font.name,
            metadata: {
              __fontFilename,
            },
          });
        } else {
          setIsFontLoading(true);

          if (fontFaceSet) {
            fontFaceSet.add(fontFace);
            fontFace.load();
            fontFace.loaded
              .then(fontFace => {
                editor.update({
                  fontFamily: font.name,
                  metadata: {
                    __fontFilename,
                  },
                });
              })
              .catch(err => console.log(err))
              .finally(() => {
                setIsFontLoading(false);
                // recalculate keyValues bounds
                if (activeObject && activeObject.type === 'DynamicText') {
                  activeObject.updateParams();
                }
              });
          }
        }
      });
    }
  };

  const getNextTextAlign = current => {
    const positions = ['left', 'center', 'right', 'left'];
    const currentIndex = positions.findIndex(v => v === current);
    const nextAlign = positions[currentIndex + 1];
    return nextAlign;
  };

  const toggleTextAlign = () => {
    if (activeObject) {
      const currentValue = activeObject.textAlign;
      const nextTextAlign = getNextTextAlign(currentValue);
      editor.update({
        textAlign: nextTextAlign,
      });

      // recalculate keyValues bounds
      if (activeObject.type === 'DynamicText') {
        activeObject.updateParams();
      }
    }
  };

  const getTextAlignIcon = () => {
    const currentValue = activeObject.textAlign;
    const Icon =
      currentValue === 'left'
        ? Icons.TextAlignLeft
        : currentValue === 'center'
        ? Icons.TextAlignCenter
        : currentValue === 'right'
        ? Icons.TextAlignRight
        : Icons.TextAlignJustify;
    return Icon;
  };

  const TextAlignIcon = getTextAlignIcon();

  const getFontFamily = fontFullname => {
    if (!userFonts) return fontFullname;
    const publicFonts = userFonts.public;
    const privateFonts = userFonts.private;

    for (let [familyName, familyFonts] of Object.entries(publicFonts)) {
      const font = familyFonts.find(f => f.name === fontFullname);
      if (font) return familyName;
    }

    const font = privateFonts.find(f => f.name === fontFullname);

    if (font) return font.family;

    return fontFullname;
  };

  return (
    <Box
      sx={{
        height: '60px',
        padding: '0 1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Button
          onClick={() => setActiveSubMenu('FontFamily')}
          size="sm"
          height="32px"
          name="fontFamily"
          borderColor="#CBCBCB"
          borderRadius="0"
          fontWeight="600"
          variant="outline"
          rightIcon={<Icons.ChevronDown size={16} />}
        >
          {isFontLoading
            ? 'Loading...'
            : getFontFamily(activeObject.fontFamily)}
        </Button>

        {fontFamily.styles.length > 1 ? (
          <Popover placement="bottom-start">
            <PopoverTrigger>
              <Button
                size="sm"
                height="32px"
                borderColor="#CBCBCB"
                borderRadius="0"
                fontWeight="600"
                name="fontWeight"
                variant="outline"
                rightIcon={<Icons.ChevronDown size={16} />}
              >
                {fontFamily.selectedFont.style}
              </Button>
            </PopoverTrigger>
            <PopoverContent width="240px" fontSize="14px" py="0.25rem">
              <Box>
                {fontFamily.styles.map((style, index) => {
                  return (
                    <Box
                      key={index}
                      name={style.style}
                      onClick={() => handleFontFamilyChange(style)}
                      _hover={{
                        cursor: 'pointer',
                        background: 'rgba(0,0,0,0.05)',
                      }}
                      padding="0.35rem 1rem"
                    >
                      {style.style}
                    </Box>
                  );
                })}
              </Box>
            </PopoverContent>
          </Popover>
        ) : (
          <Button
            disabled
            size="sm"
            height="32px"
            borderColor="#CBCBCB"
            borderRadius="0"
            fontWeight="600"
            variant="outline"
            rightIcon={<Icons.ChevronDown size={16} />}
          >
            {fontFamily.selectedFont.style}
          </Button>
        )}

        <Box name="fontSize">
          <FontSize />
        </Box>

        <Box name="textColor" sx={{ display: 'flex', alignItems: 'center' }}>
          <TextColor />
        </Box>
        <Center height="30px">
          <Divider orientation="vertical" />
        </Center>

        <IconButton
          onClick={toggleTextAlign}
          color="#000000"
          borderRadius="0"
          name="textAlign"
          variant="ghost"
          size="sm"
          icon={<TextAlignIcon size={24} />}
        />
        <Center height="30px">
          <Divider orientation="vertical" />
        </Center>

        <Box name="spacing">
          <Spacing />
        </Box>
      </Box>
      <Common />
    </Box>
  );
}

export default Text;
