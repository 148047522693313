import { fabric } from 'fabric';
import { groupBy, pick } from 'lodash';
import { v4 as uuid } from 'uuid';
// import { objectTypes } from '../common/constants';
import { findRectCenter, clickHit } from '../utils/coordination';

const controls = fabric.controlsUtils;
const getLocalPoint = controls.getLocalPoint;
const wrapWithFireEvent = controls.wrapWithFireEvent;
const wrapWithFixedAnchor = controls.wrapWithFixedAnchor;
const scaleSkewCursorStyleHandler = controls.scaleSkewCursorStyleHandler;
const REGEX_VAR = new RegExp(/\{\{[a-zA-Z0-9-_]+?\}\}/g);

const CENTER = 'center';
const ELLIPSIS = '...';

function isTransformCentered(transform) {
  return transform.originX === CENTER && transform.originY === CENTER;
}

function changeHeight(eventData, transform, x, y) {
  let target = transform.target,
    localPoint = getLocalPoint(
      transform,
      transform.originX,
      transform.originY,
      x,
      y
    ),
    multiplier = isTransformCentered(transform) ? 2 : 1,
    oldHeight = target.height,
    newHeight = Math.abs((localPoint.y * multiplier) / target.scaleY);
  target.set('height', Math.max(newHeight, 0));
  target.set('prevheight', Math.max(newHeight, 0));
  return oldHeight !== newHeight;
}

function changeWidth(eventData, transform, x, y) {
  let target = transform.target,
    localPoint = getLocalPoint(
      transform,
      transform.originX,
      transform.originY,
      x,
      y
    ),
    strokePadding =
      target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
    multiplier = isTransformCentered(transform) ? 2 : 1,
    oldWidth = target.width,
    oldHeight = target.height,
    newWidth =
      Math.abs((localPoint.x * multiplier) / target.scaleX) - strokePadding;

  target.set('width', Math.max(newWidth, 0));
  target.set('height', oldHeight);
  target.set('prevheight', oldHeight);

  return oldWidth !== newWidth;
}

fabric.Textbox.prototype.controls.mb = new fabric.Control({
  x: 0,
  y: 0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.tr = new fabric.Control({
  x: 0.5,
  y: -0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.tl = new fabric.Control({
  x: -0.5,
  y: -0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.br = new fabric.Control({
  x: 0.5,
  y: 0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.bl = new fabric.Control({
  x: -0.5,
  y: 0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.mt = new fabric.Control({
  x: 0,
  y: -0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.ml = new fabric.Control({
  x: -0.5,
  y: 0,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeWidth)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
});

fabric.Textbox.prototype.controls.mr = new fabric.Control({
  x: 0.5,
  y: 0,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeWidth)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
});

export class DynamicTextObject extends fabric.Textbox {
  static type = 'DynamicText';

  initiated = false;
  triggered = false;
  updating = false;
  updatingC = false;
  keyValues = [];
  keys = [];
  keysBounds = [];
  prevKey = '';
  originalText = '';
  label = '';

  initialize(options) {
    const { text, ...textOptions } = options;

    this.prevheight = options.height && options.height;
    this.keys = [];
    this.keysBounds = [];
    this.keyValues = options.keyValues ? options.keyValues : [];
    this.triggered = false;
    this.convertedKeyValue = new Set(this.keyValues);
    this.replacedKeyWithValue = new Set();

    super.initialize(text, {
      ...textOptions,
      backgroundColor: 'rgba(255,255,255,0)',
      keyValues: this.keyValues,
      editable: false,
    });

    this.on('modified', () => {
      this.removeEmptyText();
    });

    this.on('added', () => {
      this.canvas.on('mouse:move', e => {
        if (e.target === this) {
          const pointer = this.canvas.getPointer(e.e, false);
          const key = this.keysBounds.find(key => {
            if (!key) return false;
            const rectCenter = findRectCenter(
              this.left,
              this.top,
              this.width,
              this.height,
              this.angle
            );
            const keyPos = findRectCenter(
              this.left,
              this.top,
              2 * key.left,
              2 * key.top,
              this.angle
            );
            return clickHit(
              [pointer.x, pointer.y],
              [keyPos.x, keyPos.y],
              [key.width, key.height],
              this.angle,
              [rectCenter.x, rectCenter.y]
            );
          });
          if (key) this.hoverCursor = 'pointer';
          else if (e.target.isEditing) this.hoverCursor = 'text';
          else this.hoverCursor = 'move';
        }
      });
      this.updateParams(true);
    });

    this.on('mousedblclick', e => {
      this.set('editable', true);
      const pointer = this.canvas.getPointer(e.e, false);
      const key = this.keysBounds.find(key => {
        if (!key) return false;
        const rectCenter = findRectCenter(
          this.left,
          this.top,
          this.width,
          this.height,
          this.angle
        );
        const keyPos = findRectCenter(
          this.left,
          this.top,
          2 * key.left,
          2 * key.top,
          this.angle
        );
        return clickHit(
          [pointer.x, pointer.y],
          [keyPos.x, keyPos.y],
          [key.width, key.height],
          this.angle,
          [rectCenter.x, rectCenter.y]
        );
      });

      if (key) {
        const updatedTextLines = this.getUpdatedTextLines();
        const cursorLocation = this.get2DCursorLocation();

        const currentLine = updatedTextLines.find(
          u => u.lineIndex === cursorLocation.lineIndex
        );

        // const startIndex = key.startIndex + currentLine.groupStartIndex;
        const endIndex = key.endIndex + currentLine.groupStartIndex + 1;

        this.set('editable', true);
        this.setSelectionStart(endIndex);
        this.setSelectionEnd(endIndex);
        this.enterEditing();
      } else {
        this.enterEditing();
      }
    });

    // this.on('mousemove', e => {
    //   const pointer = this.canvas.getPointer(e.e, false);
    //   const key = this.keysBounds.find(key => {
    //     if (!key) return;
    //     if (
    //       pointer.x >= key.left + this.left &&
    //       pointer.x <= this.left + key.left + key.width &&
    //       pointer.y >= key.top + this.top &&
    //       pointer.y <= this.top + key.top + key.height
    //     )
    //       return true;
    //     else return false;
    //   });
    //   if (key) this.hoverCursor = 'pointer';
    //   else this.hoverCursor = 'move';
    // });

    this.on('mouseup', e => {
      const pointer = this.canvas.getPointer(e.e, false);
      const key = this.keysBounds.find(key => {
        if (!key) return false;
        const rectCenter = findRectCenter(
          this.left,
          this.top,
          this.width,
          this.height,
          this.angle
        );
        const keyCors = findRectCenter(
          this.left,
          this.top,
          2 * key.left,
          2 * key.top,
          this.angle
        );
        return clickHit(
          [pointer.x, pointer.y],
          [keyCors.x, keyCors.y],
          [key.width, key.height],
          this.angle,
          [rectCenter.x, rectCenter.y]
        );
      });
      if (e.button === 1) {
        if (key) {
          const zoom = this.canvas.getZoom();
          const { left, top } = this.getBoundingRect(false);

          // calculate character top position when line height and font size are different
          const getTop = () => {
            // font invisible background height constent
            // invisible background height: this._fontSizeMult * key.height
            const constantTextProportion = this._fontSizeMult;
            const lineHeight = this.lineHeight;
            const lineHeightWhitespace =
              key.height * zoom * lineHeight * constantTextProportion -
              key.height * zoom * constantTextProportion;

            return (
              top +
              key.height *
                zoom *
                lineHeight *
                constantTextProportion *
                (key.lineIndex + 1) -
              lineHeightWhitespace
            );
          };

          const eventData = {
            object: this,
            isEditing: this.isEditing,
            visible: true,
            position: {
              left: left + key.left * zoom,
              top: getTop(),
            },
            key,
          };
          this.canvas.fire('text:key:selected', eventData);
        } else {
          const eventData = {
            object: this,
            visible: false,
            position: {
              left: 0,
              top: 0,
            },
          };
          this.canvas.fire('object:rightclicked', eventData);
        }
      } else if (e.button === 3) {
        const canvasPosition = this.getCanvasBoundingClientRect();
        const eventData = {
          object: this,
          visible: true,
          position: {
            left: canvasPosition.left + e.pointer.x + 8,
            top: canvasPosition.top + e.pointer.y - 24,
          },
        };
        this.canvas.fire('object:rightclicked', eventData);
        this.canvas.setActiveObject(this);
      }
    });

    this.on('editing:entered', () => {
      const eventData = {
        object: this,
        isEditing: this.isEditing,
        visible: false,
        position: {
          left: 0,
          top: 0,
        },
        key: null,
      };
      if (this.canvas) {
        this.canvas.fire('text:key:selected', eventData);
      }

      window.addEventListener('keydown', this.handleKeyDown.bind(this));
    });

    this.on('editing:exited', () => {
      this.updateParams();
      this.set('editable', false);
      const eventData = {
        object: this,
        isEditing: this.isEditing,
        visible: false,
        position: {
          left: 0,
          top: 0,
        },
        key: null,
      };

      if (this.canvas) {
        this.canvas.fire('text:key:selected', eventData);
      }
      window.removeEventListener('keydown', this.handleKeyDown.bind(this));
    });

    this.on('text:keys:updated', () => {
      if (!this.isEditing) {
        this.updateExistingValues();
      }
    });

    this.on('resizing', () => {
      const textWidth = this.width;
      if (textWidth === this.dynamicMinWidth) {
        // if (this.updating) return;
        // this.updating = true;
        setTimeout(() => {
          const lineWidths = this.__lineWidths;
          lineWidths.forEach((lineWidth, index) => {
            const updatedTextLines = this.getUpdatedTextLines();
            const currentText = this.textLines[index];

            if (!currentText) return;

            const currentTextSize = currentText.length;

            if (lineWidth === this.dynamicMinWidth) {
              if (currentText.includes(ELLIPSIS)) {
                // Handle shortened ...
                if (currentTextSize > 4) {
                  const currentStyle = updatedTextLines[index];
                  const initial = currentText.slice(0, currentTextSize - 4);
                  // const final = currentText.slice(currentTextSize - 4);
                  const updatedText = initial + ELLIPSIS;
                  const firstIndexStyle = Object.keys(
                    currentStyle.lineStyles
                  )[0];
                  const prevStyle = currentStyle.lineStyles[firstIndexStyle];
                  let startIndex = this.getStartIndex(
                    updatedTextLines,
                    currentStyle,
                    index
                  );
                  const options = {
                    // ...options,
                    ...prevStyle,
                    value: updatedText,
                    startIndex: startIndex,
                    prev: currentText,
                  };
                  this.updateTextLine(options);
                } else {
                  // console.log('TOO SHORT TO HANDLE');
                }
              } else {
                // Handle initial
                const currentStyle = updatedTextLines[index];
                if (
                  currentTextSize > 4 &&
                  currentStyle &&
                  Object.keys(currentStyle.lineStyles).length > 0
                ) {
                  const initial = currentText.slice(0, currentTextSize - 3);
                  // const final = currentText.slice(currentTextSize - 3);
                  const updatedText = initial + ELLIPSIS;
                  const firstIndexStyle = Object.keys(
                    currentStyle.lineStyles
                  )[0];
                  const prevStyle = currentStyle.lineStyles[firstIndexStyle];

                  let startIndex = this.getStartIndex(
                    updatedTextLines,
                    currentStyle,
                    index
                  );

                  const options = {
                    ...prevStyle,
                    value: updatedText,
                    startIndex: startIndex,
                    prev: currentText,
                  };
                  this.updateTextLine(options);
                } else {
                  // console.log('OUT OF RULES');
                }
              }
            }
          });
          // this.updating = false;
        }, 100);
      } else if (textWidth > this.dynamicMinWidth) {
        // return;
        // if (this.updating) return;
        // this.updating = true;
        setTimeout(() => {
          // console.log('UPDATING C', this.updating);
          // this.setCoords();
          this.clone(cloned => {
            const lineWidths = this.__lineWidths;
            lineWidths.forEach((lineWidth, index) => {
              const updatedTextLines = this.getUpdatedTextLines();
              const currentText = this.textLines[index];
              if (!currentText) return;
              const currentTextSize = currentText.length;
              const currentStyle = updatedTextLines[index];
              const firstIndexStyle = Object.keys(currentStyle.lineStyles)[0];
              const prevStyle = currentStyle.lineStyles[firstIndexStyle];

              if (!prevStyle) return;
              const orinalText = prevStyle.original;
              const matchIndex = this.text.search(this.textLines[index]);

              if (
                currentText.includes(ELLIPSIS) &&
                currentStyle &&
                Object.keys(currentStyle.lineStyles).length > 0
              ) {
                if (lineWidth < textWidth) {
                  if (currentTextSize === orinalText.length) {
                    // const startIndex = this.getStartIndex(
                    //   updatedTextLines,
                    //   currentStyle,
                    //   index
                    // );

                    let updatedText = orinalText;
                    const requestedWidth = this.measureText(updatedText);
                    if (textWidth > requestedWidth) {
                      const options = {
                        ...prevStyle,
                        value: updatedText,
                        startIndex: matchIndex,
                        prev: currentText,
                      };
                      this.updateTextLine(options);
                    }
                  } else {
                    const initial = prevStyle.value.slice(
                      0,
                      currentTextSize - 3
                    );
                    const next = orinalText.slice(0, initial.length + 1);
                    const updatedText = next + ELLIPSIS;
                    const requestedWidth = this.measureText(updatedText);
                    // const up = this.getUpdatedTextLinesForObject(cloned);

                    if (textWidth > requestedWidth) {
                      let startIndex = this.getStartIndex(
                        updatedTextLines,
                        currentStyle,
                        index,
                        true,
                        initial
                      );
                      const options = {
                        ...prevStyle,
                        value: updatedText,
                        startIndex: startIndex,
                        prev: currentText,
                      };
                      this.updateTextLine(options, true);
                    }
                  }
                }
              }
            });
          });
          // this.updating = false;
        }, 5);
      }
      this.set(
        'height',
        this.__lineHeights.reduce((a, b) => a + b)
      );
      this.updateParams();
    });

    this.on('mousewheel', function () {
      this.canvas.renderAll();
      this._renderControls(this.canvas.contextTop, {
        hasControls: false,
      });
    });

    return this;
  }

  // [IMPORTANT] ONLY one value at a time will be updated
  updateExistingValues() {
    const updatedTextLines = this.getUpdatedTextLines();
    let indexChanges = 0;

    updatedTextLines.forEach(updatedTextLine => {
      const params = this.getKeysFromTextStyles(updatedTextLine.lineStyles);

      params.forEach(param => {
        const newParam = this.keyValues.find(kv => kv.id === param.id);

        if (newParam.value !== param.value || newParam.key !== param.key) {
          const { key, value } = newParam;
          const styles = Array(value.length).fill({
            textBackgroundColor: '#d4d1cb',
            fill: '#29251f',
            key: key,
            id: param.id,
            value: value,
          });

          const newStyles = { ...this.styles };

          const indexes = Array.from(
            { length: param.endIndex - param.startIndex },
            (v, k) => param.startIndex + k
          );

          indexes.forEach(index => {
            delete newStyles[updatedTextLine.textStyleGroupIndex][index];
          });

          this.styles = newStyles;

          this.insertChars(
            value,
            styles,
            indexChanges + updatedTextLine.groupStartIndex + param.startIndex,
            indexChanges + updatedTextLine.groupStartIndex + param.endIndex
          );
          indexChanges += newParam.value.length - param.value.length;
          this.canvas.requestRenderAll();
        }
      });
    });

    this.updateKeyValues();
    this.canvas?.renderAll();
    this.setKeyBounds();
  }

  removeEmptyText() {
    if (!this.text) {
      this.canvas.remove(this);
    }
  }

  updateParams(fromAddedEvent = false) {
    this.updateKeyValues();
    this.setValuesForKeys();
    this.canvas?.renderAll();
    this.setKeyBounds();
    this.updateKeys();

    if (!fromAddedEvent) return;
    this.padding = 0;
    if (!this.initialWidth) return;
    this.width = this.initialWidth;
  }

  // update keys with param includes value
  updateKeyValues() {
    // generate params from keys recently convert to values on canvas before exit editing
    // ** note: the textbox will exit editing after change from key to value
    // ex: {{test}} => testvalue
    const initialParams = this.getParamsFromKeys(this.text, REGEX_VAR);

    // params retrived from the text style from keys converted to values after exit editing or saved
    // ** note: first load will be empty array becase we haven't run setValuesForKeys() yet
    const paramsFromValues = this.getKeysFromValues();

    // update this.keyValues
    this.keyValues = [...paramsFromValues, ...initialParams];
  }

  getParamsFromKeys(text, matchRule) {
    let paramsSet = new Set();
    const matches = [...text.matchAll(matchRule)];
    let keyValues = this.convertedKeyValue;

    // create param (without value) for matched word with matched startIndex
    matches.forEach(match => {
      const matchWord = match['0'];
      const startIndex = match['index'];
      let created = false;

      // * loop though keyValues clone to match the kayvalue that has the matched word and delete the matched
      // key from keyValues clone to avoid duplicate.
      // * if nothing is matched, create a new param with new startIndex, endIndex, id and value
      for (const keyValue of keyValues.values()) {
        if (keyValue.key === matchWord) {
          if (!paramsSet.has(keyValue)) {
            paramsSet.add(keyValue);
            keyValues.delete(keyValue);
            created = true;
            break;
          }
        }
      }

      if (!created) {
        const existMatchedWord = this.keyValues.find(
          keyValue => keyValue.key === matchWord
        );

        paramsSet.add({
          key: matchWord,
          value: existMatchedWord
            ? existMatchedWord.value
            : matchWord.substring(2, matchWord.length - 2),
          startIndex: startIndex,
          endIndex: startIndex + matchWord.length,
          id: uuid(),
        });
      }
    });

    return [...paramsSet];
  }

  getKeysFromValues() {
    let textLines = this.getUpdatedTextLines();
    let params = [];

    textLines.forEach(textLine => {
      const groupStartIndex = textLine.groupStartIndex;
      let keyValuesPerLine = this.getKeysFromTextStyles(textLine.lineStyles);

      keyValuesPerLine.forEach(keyValue => {
        if (
          keyValue.startIndex !== undefined &&
          keyValue.endIndex !== undefined
        ) {
          keyValue.startIndex = keyValue.startIndex + groupStartIndex;
          keyValue.endIndex = keyValue.endIndex + groupStartIndex;
        }
      });
      params = params.concat(keyValuesPerLine);
    });

    return params;
  }

  setValuesForKeys() {
    this.keyValues = this.keyValues.map(keyValue => {
      let keyParam;

      // only replace key with id that haven't been replaced
      // to avoid wrong param assignment
      if (!this.replacedKeyWithValue.has(keyValue.id)) {
        // replace the key (ex: {{test}}) into the display value (new or pre-existed)
        // and style with different background and text color
        // (ex: {{test}} => test)
        keyParam = this.replaceKeyWithValue(keyValue);
        this.replacedKeyWithValue.add(keyValue.id);
      }

      // return the replaced key value and updated the this.keyValue
      // if not return the current key value
      if (keyParam) {
        return keyParam;
      } else {
        return keyValue;
      }
    });
  }

  // replace the key (ex: {{test}}) into the display value (new or pre-existed)
  // and style with different background and text color
  // (ex: {{test}} => test)
  replaceKeyWithValue(keyValue) {
    const { key, value, id } = keyValue;

    // get the index of the matched key in the this.text
    // if not return -1
    const matchIndex = this.text.search(key);

    // add style and param info into text style
    const styles = Array(value.length).fill({
      textBackgroundColor: '#d4d1cb',
      fill: '#29251f',
      key: key,
      id: id,
      value: value,
      original: value,
    });

    // if matchIndex is not -1
    // update the key with value with styles
    // return updated startIndex and endIndex after replace the key with value
    if (matchIndex > -1) {
      this.insertChars(value, styles, matchIndex, matchIndex + key.length);
      return {
        key,
        value,
        startIndex: matchIndex,
        endIndex: matchIndex + value.length,
        id,
      };
    }
  }

  updateKeys() {
    let keys = [];
    this.keyValues.forEach(({ key }) => {
      keys = keys.concat(key.substring(2, key.length - 2));
    });
    this.keys = keys;
  }

  // setting the reange and height of the key that can be click
  setKeyBounds() {
    let keysBounds = [];
    let textLines = this.getUpdatedTextLines();

    textLines.forEach(textLine => {
      const lineHeight =
        this.__lineHeights[parseInt(textLine.textStyleGroupIndex)];

      const params = this.getKeysFromTextStyles(textLine.lineStyles);

      const linekeyBounds = params.map(param => {
        if (!this.__charBounds[textLine.lineIndex]) return false;

        const charBounds = this.__charBounds[textLine.lineIndex].map(cbs => ({
          ...cbs,
          top: lineHeight * textLine.lineIndex,
        }));

        const charBoundMin = charBounds[param.startIndex - textLine.startIndex];

        let charBoundMax = charBounds[param.endIndex - textLine.startIndex];

        charBoundMax = charBoundMax
          ? charBoundMax
          : charBounds[charBounds.length - 1];

        if (!charBoundMin || !charBoundMax) return {};

        const lineWidth = this.__lineWidths[textLine.lineIndex];
        const width = this.width;
        let shift = 0;

        if (this.textAlign === 'center') {
          shift = (width - lineWidth) / 2;
        } else if (this.textAlign === 'right') {
          shift = width - lineWidth;
        }

        const updatedTextLines = this.getUpdatedTextLines();

        const currentStyle = updatedTextLines[textLine.lineIndex];

        let startIndexRelative = this.getStartIndex(
          updatedTextLines,
          currentStyle,
          textLine.lineIndex
        );

        const lineIndex = textLine.lineIndex;
        const charBound = {
          ...charBoundMin,
          ...param,
          lineIndex,
          shift,
          left: shift + charBoundMin.left,
          top: charBoundMin.top,
          // width: charBoundMax.width + charBoundMax.left - charBoundMin.left,
          width: charBoundMax.left - charBoundMin.left,
          height: charBoundMin.height,
          absoluteStarIndex:
            textLine.textStyleGroupIndex !== 0
              ? startIndexRelative + param.startIndex
              : param.startIndex,
          absoluteEndIndex:
            textLine.textStyleGroupIndex !== 0
              ? startIndexRelative + param.endIndex
              : param.endIndex,
          textStyleGroupIndex: textLine.textStyleGroupIndex,
        };

        return charBound;
      });

      keysBounds = keysBounds.concat(linekeyBounds);
    });

    this.keysBounds = keysBounds;
  }

  /**
   * Update text lines normalizing text and adding styles by text line
   */
  getUpdatedTextLines() {
    let allText = this.text;
    const textLines = this.textLines;
    let updatedTextLines = [];
    let textStyleGroupIndex = 0;
    let startIndex = 0;
    let lineIndex = 0;
    let groupStartIndexProgress = 0;
    let currentProgress = 0;

    textLines.forEach((textLine, index) => {
      let currentTextLine = textLine;
      let isBreakLine = false;
      lineIndex = index;
      const prevUpdatedLine = updatedTextLines[index - 1];

      if (allText[0] === '\n') {
        allText = allText.substring(1);
        textStyleGroupIndex += 1;
        if (index) {
          prevUpdatedLine.breakLine = true;
        }
      } else {
        const textLineChange = index ? ' ' : '';
        currentTextLine = textLineChange + currentTextLine;
      }

      const initialPart = allText.substring(0, currentTextLine.length);
      const remainingPart = allText.substring(currentTextLine.length);

      if (index) {
        if (prevUpdatedLine.breakLine) {
          startIndex = 0;
        } else {
          startIndex =
            prevUpdatedLine.startIndex +
            prevUpdatedLine.text.trimStart().length +
            (allText.length - allText.trimStart().length);
        }
      }

      if (prevUpdatedLine && prevUpdatedLine.breakLine) {
        groupStartIndexProgress += 1;
        currentProgress = groupStartIndexProgress;
      }

      allText = remainingPart;

      updatedTextLines = updatedTextLines.concat({
        text: initialPart,
        breakLine: isBreakLine,
        textStyleGroupIndex,
        startIndex,
        lineIndex: lineIndex,
        initialText: textLine,
        groupStartIndex:
          prevUpdatedLine && prevUpdatedLine.breakLine
            ? groupStartIndexProgress
            : currentProgress,
      });

      groupStartIndexProgress += initialPart.length;
    });

    const textStyleGroups = this.styles;

    const updatedTextLinesWithStyles = updatedTextLines.map(updatedTextLine => {
      const textStyleGroup =
        textStyleGroups[updatedTextLine.textStyleGroupIndex];

      const indexes = Array(updatedTextLine.text.length)
        .fill(0)
        .map((_, i) => (updatedTextLine.startIndex + i).toString());

      const lineStyles = pick(textStyleGroup, indexes);

      return { ...updatedTextLine, lineStyles };
    });

    return updatedTextLinesWithStyles;
  }

  getKeysFromTextStyles(textSyles) {
    let charStyles = [];
    let params = [];
    Object.keys(textSyles).forEach(style => {
      if (textSyles[style].key && textSyles[style].value) {
        charStyles = charStyles.concat({
          index: parseInt(style),
          key: textSyles[style].key,
          value: textSyles[style].value,
          id: textSyles[style].id,
        });
      }
    });
    const groupedCharStyles = groupBy(charStyles, 'id');
    Object.keys(groupedCharStyles).forEach(group => {
      const value = groupedCharStyles[group][0].value;
      const key = groupedCharStyles[group][0].key;
      const indexes = groupedCharStyles[group]
        .map(g => g.index)
        .sort((a, b) => a - b);
      const [startIndex] = [indexes[0]];
      const param = {
        key,
        value,
        startIndex,
        endIndex: startIndex + value.length,
        id: group,
      };
      params = params.concat(param);
    });
    return params;
  }

  /**
   * Replace all values with keys
   */
  replaceTextLineStyled(textLineStyled) {
    const { textLine, styles } = textLineStyled;
    const params = this.getKeysFromTextStyles(styles);

    if (params.length === 0) {
      return textLine;
    }

    let textArr = textLine.split('');
    let pieces = [];

    params.forEach((param, index) => {
      const currentParam = param;
      const prevParam = params[index - 1];
      const nextParam = params[index + 1];

      if (index === 0) {
        const initialSection = textArr.slice(0, param.startIndex);
        if (initialSection.length > 0) {
          pieces = pieces.concat(initialSection.join(''));
        }
      } else {
        const initialSection = textArr.slice(
          prevParam.endIndex,
          currentParam.startIndex
        );
        if (initialSection.length > 0) {
          pieces = pieces.concat(initialSection.join(''));
        }
      }
      pieces = pieces.concat(param.key.split(''));

      if (!nextParam) {
        const lastSection = textArr.slice(param.endIndex);

        if (lastSection.length > 0) {
          pieces = pieces.concat(lastSection.join(''));
        }
      }
    });

    return pieces.join('');
  }

  replaceValueWithKey() {
    const styles = this.styles;
    const textLines = this.text.split('\n');
    const textLinesStyled = textLines.map((textLine, index) => {
      return {
        textLine,
        styles: styles[index] ? styles[index] : {},
      };
    });
    let updatedText = '';

    textLinesStyled.forEach((textLineStyled, index) => {
      let originalText = this.replaceTextLineStyled(textLineStyled);
      if (textLinesStyled[index + 1]) {
        originalText += '\n';
      }
      updatedText += originalText;
    });
    this.insertChars(updatedText, null, 0, this.text.length);
  }

  getText() {
    const styles = this.styles;
    const textLines = this.text.split('\n');

    const textLinesStyled = textLines.map((textLine, index) => {
      return {
        textLine,
        styles: styles[index] ? styles[index] : {},
      };
    });
    let updatedText = '';

    textLinesStyled.forEach((textLineStyled, index) => {
      let originalText = this.replaceTextLineStyled(textLineStyled);
      if (textLinesStyled[index + 1]) {
        originalText += '\n';
      }
      updatedText += originalText;
    });
    return updatedText;
  }

  updateVariableName(props) {
    const currentKey = props.key;
    const nextKey = props.next;

    const updatedTextLines = this.getUpdatedTextLines();
    updatedTextLines.forEach(updatedTextLine => {
      const params = this.getKeysFromTextStyles(updatedTextLine.lineStyles);
      const newParam = params.find(p => p.key === currentKey);

      if (newParam) {
        const { value } = newParam;
        const styles = Array(value.length).fill({
          textBackgroundColor: '#d4d1cb',
          fill: '#29251f',
          key: nextKey,
          id: newParam.id,
          value: value,
        });

        const newStyles = { ...this.styles };

        const indexes = Array.from(
          { length: newParam.endIndex - newParam.startIndex + 1 },
          (v, k) => newParam.startIndex + k
        );

        indexes.forEach(index => {
          delete newStyles[updatedTextLine.textStyleGroupIndex][index];
        });

        this.styles = newStyles;

        this.insertChars(
          value,
          styles,
          updatedTextLine.groupStartIndex + newParam.startIndex,
          updatedTextLine.groupStartIndex + newParam.endIndex + 1
        );
        this.updateKeyValues();
        this.canvas?.renderAll();
        this.setKeyBounds();
        this.enterEditing();
        this.exitEditing();
        this.canvas.requestRenderAll();
        return false;
      }
    });
  }

  updateVariableNameX(props) {
    const currentKey = props.key;
    const nextKey = props.next;

    const styles = this.styles;
    const textLines = this.text.split('\n');

    const textLinesStyled = textLines.map((textLine, index) => {
      const prevItems = textLines
        .slice(0, index)
        .map(tl => tl.length)
        .concat(0)
        .reduce((a, b) => a + b);
      return {
        textLine,
        styles: styles[index] ? styles[index] : {},
        lineStartIndex: prevItems + index,
      };
    });

    textLinesStyled.forEach((textLineStyled, index) => {
      const { styles } = textLineStyled;
      const params = this.getKeysFromTextStyles(styles);
      params.forEach(param => {
        const newParam = this.keyValues.find(kv => kv.key === currentKey);
        const { value } = newParam;
        const styles = Array(value.length).fill({
          textBackgroundColor: '#d4d1cb',
          fill: '#29251f',
          key: nextKey,
          id: param.id,
          value: value,
        });
        this.removeStyleFromTo(
          textLineStyled.lineStartIndex + param.startIndex,
          textLineStyled.lineStartIndex + param.endIndex
        );
        this.insertChars(
          value,
          styles,
          textLineStyled.lineStartIndex + param.startIndex,
          textLineStyled.lineStartIndex + param.endIndex
        );
        this.canvas.requestRenderAll();
      });
    });
    this.updateKeyValues();
    this.setKeyBounds();
  }

  replaceValueWithKeyForText(textLine) {
    const params = this.getKeysFromTextStyles(textLine.lineStyles);
    const lineEnding = textLine.breakLine ? '\n' : '';
    const lineVariation = textLine.initialText === textLine.text ? '' : ' ';
    if (params.length === 0) {
      return lineVariation + textLine.initialText + lineEnding;
    } else {
      let pieces = [];
      let textArr = textLine.initialText.split('');
      params.forEach((param, index) => {
        const currentParam = param;
        const prevParam = params[index - 1];
        const nextParam = params[index + 1];
        const diff = textLine.startIndex;

        // calculate initial section
        if (index === 0) {
          const initialSection = textArr.slice(0, param.startIndex - diff);
          if (initialSection.length > 0) {
            pieces = pieces.concat(initialSection.join(''));
          }
        } else {
          const initialSection = textArr.slice(
            prevParam.endIndex - diff,
            currentParam.startIndex - diff
          );
          if (initialSection.length > 0) {
            pieces = pieces.concat(initialSection.join(''));
          }
        }

        pieces = pieces.concat(param.key.split(''));

        if (!nextParam) {
          const lastSection = textArr.slice(
            param.startIndex + param.value.length - diff
          );
          if (lastSection.length > 0) {
            pieces = pieces.concat(lastSection.join(''));
          }
        }
      });
      return lineVariation + pieces.join('') + lineEnding;
    }
  }

  getUpdatedTextLinesForObject(object) {
    let allText = object.text;
    const textLines = object.textLines;
    let updatedTextLines = [];
    let textStyleGroupIndex = 0;
    let startIndex = 0;
    let lineIndex = 0;

    textLines.forEach((textLine, index) => {
      let currentTextLine = textLine;
      let isBreakLine = false;
      lineIndex = index;
      const prevUpdatedLine = updatedTextLines[index - 1];
      if (allText[0] === '\n') {
        allText = allText.substring(1);
        textStyleGroupIndex += 1;
        if (index) {
          prevUpdatedLine.breakLine = true;
        }
      } else {
        const textLineChange = index ? ' ' : '';
        currentTextLine = textLineChange + currentTextLine;
      }

      const initialPart = allText.substring(0, currentTextLine.length);
      const remainingPart = allText.substring(currentTextLine.length);

      if (index) {
        if (prevUpdatedLine.breakLine) {
          startIndex = 0;
        } else {
          startIndex =
            prevUpdatedLine.startIndex + prevUpdatedLine.text.length + 1;
        }
      }

      allText = remainingPart;
      updatedTextLines = updatedTextLines.concat({
        text: initialPart,
        breakLine: isBreakLine,
        textStyleGroupIndex,
        startIndex,
        lineIndex: lineIndex,
        initialText: textLine,
      });
    });

    const textStyleGroups = object.styles;
    const updatedTextLinesWithStyles = updatedTextLines.map(updatedTextLine => {
      const textStyleGroup =
        textStyleGroups[updatedTextLine.textStyleGroupIndex];
      const indexes = Array(updatedTextLine.text.length)
        .fill(0)
        .map((_, i) => (updatedTextLine.startIndex + i).toString());
      const lineStyles = pick(textStyleGroup, indexes);
      return { ...updatedTextLine, lineStyles };
    });
    return updatedTextLinesWithStyles;
  }

  insertKey(value) {
    const initial = this.selectionStart;
    const end = this.selectionEnd;
    if (this.text[initial - 1] === '{' && this.text[initial - 2] === '{') {
      this.insertChars(value, null, initial - 2, end);
      this.selectionStart = initial + value.length - 2;
    } else {
      this.insertChars(value, null, initial, end);
      this.selectionStart = initial + value.length;
    }

    this.exitEditing();
    this.canvas?.renderAll();
    setTimeout(() => {
      this.enterEditing();
    }, 1000);
  }

  handleKeyDown(e) {
    const key = e.key;
    const currentPosition = this.selectionStart;

    if (this.triggered && this.isEditing) {
      const eventData = {
        object: this,
        isEditing: this.isEditing,
        visible: false,
        position: {
          left: 0,
          top: 0,
        },
        key: null,
      };

      if (this.canvas) {
        this.canvas.fire('text:key:selected', eventData);
      }
    }

    if (key === '{') {
      if (this.text[currentPosition - 1] === '{') {
        const cursorLocation = this.get2DCursorLocation();
        const zoom = this.canvas.getZoom();
        const charbounds = this.__charBounds;
        const charBound =
          charbounds[cursorLocation.lineIndex][cursorLocation.charIndex];
        const { left, top } = this.getBoundingRect(false);

        const getLeftByTextAlign = () => {
          // calculate character position when text align left
          if (this.textAlign === 'left') return left + charBound.left * zoom;

          // calculate character position when text align center
          if (this.textAlign === 'center') {
            const lastCharBound =
              charbounds[cursorLocation.lineIndex][
                charbounds[cursorLocation.lineIndex].length - 1
              ];
            return (
              left +
              charBound.left * zoom +
              ((this.calcTextWidth() * zoom) / 2 -
                (lastCharBound.left * zoom) / 2)
            );
          }

          // calculate character left position when text align right
          if (this.textAlign === 'right') {
            const charBoundLength = charbounds[cursorLocation.lineIndex].length;
            // swap selected character location
            const charBoundReverse =
              charbounds[cursorLocation.lineIndex][
                charBoundLength - cursorLocation.charIndex
              ];
            return (
              left +
              (this.calcTextWidth() * zoom - charBoundReverse.left * zoom)
            );
          }
        };

        // calculate character top position when line height and font size are different
        const getTop = () => {
          // font invisible background height constent
          // invisible background height: this._fontSizeMult * charBound.height
          const constantTextProportion = this._fontSizeMult;
          const lineHeight = this.lineHeight;
          const lineHeightWhitespace =
            charBound.height * zoom * lineHeight * constantTextProportion -
            charBound.height * zoom * constantTextProportion;

          return (
            top +
            charBound.height *
              zoom *
              lineHeight *
              constantTextProportion *
              (cursorLocation.lineIndex + 1) -
            lineHeightWhitespace
          );
        };

        const eventData = {
          object: this,
          isEditing: this.isEditing,
          visible: true,
          position: {
            left: getLeftByTextAlign(),
            top: getTop(),
          },
          key: undefined,
        };
        if (this.canvas) {
          this.canvas.fire('text:key:selected', eventData);
        }
        this.triggered = true;
      }
    }
  }

  getCanvasBoundingClientRect() {
    const canvasEl = document.getElementById('canvas');
    const position = {
      left: canvasEl?.getBoundingClientRect().left,
      top: canvasEl?.getBoundingClientRect().top,
    };
    return position;
  }

  triggerKeysMenu() {
    this.setCoords();
    const canvasPosition = this.getCanvasBoundingClientRect();
    const zoom = this.canvas.getZoom();
    const { scaleX, scaleY, width, height } = this;
    const { left, top } = this.getBoundingRect(false);
    const padLeft = (width * scaleX * zoom - width) / 2;
    const padTop = (height * scaleY * zoom - height) / 2;
    const cursorLocation = this.get2DCursorLocation();
    const charBounds = this.__charBounds;
    const charBound =
      charBounds[cursorLocation.lineIndex][cursorLocation.charIndex];
    const eventData = {
      object: this,
      isEditing: this.isEditing,
      visible: true,
      position: {
        left: canvasPosition.left + left + padLeft + charBound.left,
        top:
          canvasPosition.top +
          top +
          padTop +
          (cursorLocation.lineIndex + 1) * charBound.height * zoom,
      },
      key: undefined,
    };
    this.canvas.fire('text:key:selected', eventData);
  }

  _set(key, value) {
    if (key === 'keyValues') {
      const keyValues = value || [];
      if (keyValues.length > 0) {
        this.keyValues = keyValues;
        this.fire('text:keys:updated');
      }
    }
    return super._set(key, value);
  }

  getStartIndex(updatedTextLines, currentStyle, index, print, initial) {
    let updated = JSON.parse(JSON.stringify(updatedTextLines));
    let startChart = 0;

    // check the starting character index
    // * it's a line break string if it contain extra whitespace before the first character
    const startCharIndex = currentStyle.text.search(/\S|$/);

    let matchIndexInLine = 0;
    if (initial) {
      matchIndexInLine = this.textLines[index].search(initial);
    }
    const textLines = updated.splice(0, index);
    // let add = 0;
    // if (initial) {
    //   add = currentStyle.text.search(initial) - 1;
    // }

    for (const textLine of textLines) {
      const lineChange = textLine.breakLine ? 1 : 0;
      startChart += textLine.text.length + lineChange;
    }

    return startChart + startCharIndex + matchIndexInLine;
  }

  updateTextLine(options) {
    const styles = Array(options.value.length).fill(options);
    this.insertChars(
      options.value,
      styles,
      options.startIndex,
      options.startIndex + options.prev.length
    );
  }

  measureText(text) {
    const ctx = this.getMeasuringContext();
    ctx.font = `${this.fontSize}px ${this.fontFamily}`;
    return ctx.measureText(text).width;
  }

  moveCursorDown(e) {
    if (
      this.selectionStart >= this._text.length &&
      this.selectionEnd >= this._text.length
    ) {
      return;
    }
    this._moveCursorUpOrDown('Down', e);
    this.shouldChangeSelectionPosition('Down', e);
  }

  moveCursorLeft(e) {
    if (this.selectionStart === 0 && this.selectionEnd === 0) {
      return;
    }
    this._moveCursorLeftOrRight('Left', e);
    this.shouldChangeSelectionPosition('Left', e);
  }

  moveCursorUp(e) {
    if (this.selectionStart === 0 && this.selectionEnd === 0) {
      return;
    }
    this._moveCursorUpOrDown('Up', e);
    this.shouldChangeSelectionPosition('Up', e);
  }

  shouldChangeSelectionPosition(direction, e) {
    const updatedTextLines = this.getUpdatedTextLines();
    const cursorLocation = this.get2DCursorLocation();
    const currentLine = updatedTextLines.find(
      u => u.lineIndex === cursorLocation.lineIndex
    );
    const currentLineStyles = currentLine.lineStyles;
    const prevOffset = currentLine.startIndex === 0 ? 0 : -1;
    const nextOffset = currentLine.startIndex === 0 ? -1 : 0;
    let isPreviousStyled =
      currentLineStyles[
        currentLine.startIndex + cursorLocation.charIndex + prevOffset
      ];
    let isNextStyled =
      currentLineStyles[
        currentLine.startIndex + cursorLocation.charIndex + nextOffset
      ];
    if (direction === 'Left') {
      if (isPreviousStyled && isNextStyled) {
        this.moveCursorLeft(e);
      }
    } else if (direction === 'Right') {
      if (isPreviousStyled && isNextStyled) {
        this.moveCursorRight(e);
      }
    } else if (direction === 'Down') {
      if (isPreviousStyled && isNextStyled) {
        this.moveCursorRight(e);
      }
    } else if (direction === 'Up') {
      if (isPreviousStyled && isNextStyled) {
        this.moveCursorRight(e);
      }
    }
  }

  moveCursorRight(e) {
    if (
      this.selectionStart >= this._text.length &&
      this.selectionEnd >= this._text.length
    ) {
      return;
    }
    this._moveCursorLeftOrRight('Right', e);
    this.shouldChangeSelectionPosition('Right', e);
  }

  onInput(e) {
    e && e.stopPropagation();
    let fromPaste = this.fromPaste;
    this.fromPaste = false;
    let currentKey;

    const charInput = e.inputType === 'insertText' ? true : false;
    const backDelete = e.inputType === 'deleteContentBackward' ? true : false;
    const forwardDelete = e.inputType === 'deleteContentForward' ? true : false;

    let nextText = this._splitTextIntoLines(
      this.hiddenTextarea.value
    ).graphemeText;
    let defaultCharCount = this._text.length; // default text character count
    let updatedCharCount = nextText.length; // text character count after add or delete text
    let removedText;
    let insertedText;
    // number of character different between default and updated one
    // * note: + number is how many character you added
    // * note: - number is how many character you deleted
    let charDiff = updatedCharCount - defaultCharCount;
    // * note: if there is no selection, the start index is when where you start typing or deleting
    // * note: if there is no selection, both selectionStart and selectionEnd has the same index
    let selectionStart = this.selectionStart; // the start of selection index
    let selectionEnd = this.selectionEnd; // the end of selection index,
    // * note: if there selectionStart is not the same as selectionEnd
    // it means the text is selected
    let selection = selectionStart !== selectionEnd;
    let copiedStyle;
    let removeFrom;
    let removeTo;

    let updatedTextLines = this.getUpdatedTextLines();
    let cursorLocation = this.get2DCursorLocation();

    let currentLine = updatedTextLines.find(
      u => u.lineIndex === cursorLocation.lineIndex
    );

    let currentLineStyles = currentLine.lineStyles;

    let textareaSelection = this.fromStringToGraphemeSelection(
      this.hiddenTextarea.selectionStart,
      this.hiddenTextarea.selectionEnd,
      this.hiddenTextarea.value
    );

    // when there are text selected
    if (selection) {
      removedText = this._text.slice(selectionStart, selectionEnd);
      charDiff += selectionEnd - selectionStart;
    } else if (updatedCharCount < defaultCharCount) {
      // when updated char count is less then default char count
      // Back-deleting (Backspace)
      if (backDelete) {
        removedText = this._text.slice(selectionEnd + charDiff, selectionEnd);
      }

      // Forward-deleting (Delete)
      if (forwardDelete) {
        removedText = this._text.slice(
          selectionStart,
          selectionStart - charDiff
        );
      }
    }

    let isPreviousStyled;
    let isNextStyled;

    if (selection) {
      isPreviousStyled =
        currentLineStyles[
          currentLine.startIndex + cursorLocation.charIndex - 1
        ];
      isNextStyled =
        currentLineStyles[
          currentLine.startIndex + cursorLocation.charIndex + removedText.length
        ];
    } else {
      isPreviousStyled =
        currentLineStyles[
          currentLine.startIndex + cursorLocation.charIndex - 1
        ];
      isNextStyled =
        currentLineStyles[currentLine.startIndex + cursorLocation.charIndex];
    }

    this.keysBounds.find((key, index) => {
      if (!key) return false;
      if (isPreviousStyled && isNextStyled) {
        if (charInput && isPreviousStyled && isPreviousStyled.id === key.id) {
          currentKey = { key, index, currentLine };
          return true;
        }

        if (backDelete && isPreviousStyled && isPreviousStyled.id === key.id) {
          currentKey = { key, index, currentLine };
          return true;
        }

        if (forwardDelete && isNextStyled && isNextStyled.id === key.id) {
          currentKey = { key, index, currentLine };
          return true;
        }
      } else if (
        (isPreviousStyled && isPreviousStyled.id === key.id) ||
        (isNextStyled && isNextStyled.id === key.id)
      ) {
        currentKey = { key, index, currentLine };
        return true;
      }
      return false;
    });

    // check if before or after the cursor is styled when not selected
    const isStyled = isPreviousStyled ? isPreviousStyled : isNextStyled;

    let keyStartIndex = 0;
    let keyEndIndex = 0;

    if (isStyled && currentKey) {
      keyStartIndex = currentKey.key.startIndex + currentLine.groupStartIndex;
      keyEndIndex = currentKey.key.endIndex + currentLine.groupStartIndex;
    }

    if (!this.isEditing) {
      return;
    }

    // when the text box is empty, reset and exit onInput function
    if (this.hiddenTextarea.value === '') {
      this.styles = {};
      this.updateFromTextArea();
      this.fire('changed');
      if (this.canvas) {
        this.canvas.fire('text:changed', { target: this });
        this.canvas.requestRenderAll();
      }
      return;
    }

    insertedText = nextText.slice(
      textareaSelection.selectionEnd - charDiff,
      textareaSelection.selectionEnd
    );

    if (removedText && removedText.length) {
      if (insertedText.length) {
        // let's copy some style before deleting.
        // we want to copy the style before the cursor OR the style at the cursor if selection
        // is bigger than 0.
        copiedStyle = this.getSelectionStyles(
          selectionStart,
          selectionStart + 1,
          false
        );
        // now duplicate the style one for each inserted text.
        copiedStyle = insertedText.map(function () {
          // this return an array of references, but that is fine since we are
          // copying the style later.
          return copiedStyle[0];
        });
      }

      // detect differences between forwardDelete and backDelete
      // Back-deleting (Backspace)
      if (backDelete) {
        removeFrom = selectionStart - removedText.length;
        removeTo = selectionEnd;
      }
      // Forward-deleting (Delete)
      if (forwardDelete) {
        removeFrom = selectionStart;
        removeTo = selectionEnd + removedText.length;
      }

      if (isStyled && !selection) {
        if (
          backDelete &&
          isPreviousStyled &&
          isNextStyled &&
          isPreviousStyled.id === isNextStyled.id
        ) {
          this.removeChars(removeFrom, removeTo);
        }

        if (
          (backDelete && !(keyStartIndex <= selectionStart)) ||
          !(selectionStart <= keyEndIndex)
        ) {
          this.removeChars(removeFrom, removeTo);
        }

        // Back-deleting (Backspace) right before variable
        if (backDelete && selectionStart === keyStartIndex) {
          this.removeChars(removeFrom, removeTo);
        }

        // Forward-deleting (Delete) right after variable
        if (forwardDelete && selectionStart === keyEndIndex) {
          this.removeChars(removeFrom, removeTo);
        }

        // Back-deleting (Backspace)
        if (
          backDelete &&
          currentKey &&
          currentKey.key.value &&
          keyEndIndex === selectionStart
        ) {
          this.removeChars(keyStartIndex, keyEndIndex);
          // delete replaced key with value id from the tracking set
          this.replacedKeyWithValue.delete(currentKey.key.id);
          this.setSelectionStart(keyStartIndex);
          this.canvas?.renderAll();
          this.exitEditing();
          this.set('editable', true);
          this.enterEditing();
        }

        // Forward-deleting (Delete)
        if (
          forwardDelete &&
          currentKey &&
          currentKey.key.value &&
          keyStartIndex === selectionStart
        ) {
          this.removeChars(keyStartIndex, keyEndIndex);
          // delete replaced key with value id from the tracking set
          this.replacedKeyWithValue.delete(currentKey.key.id);
          this.setSelectionStart(keyStartIndex);
          this.canvas?.renderAll();
          this.exitEditing();
          this.set('editable', true);
          this.enterEditing();
        }
      } else {
        if (selection) {
          this.removeChars(selectionStart, selectionEnd);
          // this.setSelectionStart(selectionStart);
          // this.setSelectionEnd(selectionStart);
        } else {
          this.removeChars(removeFrom, removeTo);
        }
      }
    }

    // update variable index after deletion
    updatedTextLines = this.getUpdatedTextLines();
    cursorLocation = this.get2DCursorLocation();

    currentLine = updatedTextLines.find(
      u => u.lineIndex === cursorLocation.lineIndex
    );

    currentLineStyles = currentLine.lineStyles;

    isPreviousStyled =
      currentLineStyles[currentLine.startIndex + cursorLocation.charIndex - 1];
    isNextStyled =
      currentLineStyles[currentLine.startIndex + cursorLocation.charIndex];

    // insert text logic
    if (insertedText.length !== 0) {
      if (e.inputType !== 'insertLineBreak') {
        let startIndexRelative = 0;

        if (isStyled && currentKey) {
          const updatedTextLines = this.getUpdatedTextLines();
          const currentStyle = updatedTextLines[currentKey.key.lineIndex];

          startIndexRelative = this.getStartIndex(
            updatedTextLines,
            currentStyle,
            currentKey.key.lineIndex
          );

          // insert text at the end of the variable
          if (isPreviousStyled && !isNextStyled) {
            // if (
            //   this.keysBounds[currentKey.index + 1] &&
            //   this.keysBounds[currentKey.index + 1].textStyleGroupIndex ===
            //     currentKey.key.textStyleGroupIndex
            // ) {
            //   this.insertNewStyleBlock(
            //     insertedText,
            //     this.keysBounds[currentKey.index + 1].startIndex +
            //       currentKey.currentLine.groupStartIndex,
            //     copiedStyle
            //   );
            //   this.updateParams();
            // }

            // if exist another variable in the same textStyleGroupIndex
            // if (
            //   this.keysBounds[currentKey.index + 1] &&
            //   this.keysBounds[currentKey.index + 1].textStyleGroupIndex ===
            //     currentKey.key.textStyleGroupIndex &&
            //   this.keysBounds[currentKey.index + 1].lineIndex !==
            //     currentKey.key.lineIndex
            // ) {
            //   this.updateParams();
            // }

            this.insertChars(
              insertedText.toString(),
              [{}],
              selectionStart,
              undefined
            );
          } else if (!isPreviousStyled && isNextStyled) {
            this.insertChars(
              insertedText.toString(),
              [{}],
              selectionStart,
              undefined
            );
          } else {
            if (selectionStart !== startIndexRelative) {
              if (selection) {
                // Possible way 1 to fix two variable glue together but text in between is highlighted
                this.insertNewStyleBlock(
                  insertedText,
                  selectionStart - 1,
                  copiedStyle
                );

                // Possible way 2 to fix two variable glue together but text in between is not highlighted but has style object
                // this.insertNewStyleBlock(insertedText, selectionStart, [
                //   { fill: 'black' },
                // ]);

                // Possible way 3 to fix two variable glue together but text in between is not highlighted but has style object
                // this.insertChars(
                //   insertedText.toString(),
                //   [{ fill: 'black' }],
                //   selectionStart,
                //   undefined
                // );
              } else {
                this.insertNewStyleBlock(
                  insertedText,
                  selectionStart,
                  copiedStyle
                );
              }
            } else {
              this.insertChars(
                insertedText.toString() + currentKey.key.key,
                null,
                startIndexRelative,
                startIndexRelative + currentKey.key.value.length
              );
            }
          }
        } else {
          // insert text without variable
          if (!selection) {
            this.insertChars(
              insertedText.toString(),
              null,
              selectionStart,
              undefined
            );
          }
        }

        if (
          fromPaste &&
          insertedText.join('') === fabric.copiedText &&
          !fabric.disableStyleCopyPaste
        ) {
          copiedStyle = fabric.copiedTextStyle;
        }

        if (copiedStyle && !isPreviousStyled && !isNextStyled) {
          const haveStyles = copiedStyle.filter(
            style => style.textBackgroundColor === '#d4d1cb'
          );

          if (haveStyles.length > 0) {
            // this.insertNewStyleBlock(insertedText, selectionStart, copiedStyle);
          } else {
            // copiedStyle should be an array of a empty object [{}]
            this.insertNewStyleBlock(insertedText, selectionStart, copiedStyle);
          }
        }
      } else {
        if (currentKey && backDelete === false) {
          if (
            this.keysBounds[currentKey.index + 1] ||
            selectionStart === keyStartIndex
          ) {
            this.insertChars(
              insertedText.toString(),
              null,
              selectionStart,
              undefined
            );
          }
        } else {
          this.insertChars(insertedText.toString(), null, selectionStart, 0);
        }
      }
    }

    this.updateFromTextArea();

    this.setValuesForKeys();
    this.canvas?.renderAll();
    this.setKeyBounds();
    this.updateKeys();

    this.fire('changed');

    if (this.canvas) {
      this.canvas.fire('text:changed', { target: this });
      this.canvas.requestRenderAll();
    }
  }

  toObject(propertiesToInclude = []) {
    const originalText = this.getText();

    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {
        keys: this.keys,
        originalText: originalText,
        metadata: this.metadata,
        keyValues: this.keyValues,
        clipPath: this.clipPath,
        label: this.label,
      }
    );
  }

  toJSON(propertiesToInclude = []) {
    const originalText = this.getText();

    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {
        keys: this.keys,
        originalText: originalText,
        metadata: this.metadata,
        keyValues: this.keyValues,
        clipPath: this.clipPath,
        label: this.label,
      }
    );
  }

  static fromObject(options, callback) {
    return callback && callback(new fabric.DynamicText(options));
  }
}

fabric.DynamicText = fabric.util.createClass(DynamicTextObject, {
  type: DynamicTextObject.type,
});
fabric.DynamicText.fromObject = DynamicTextObject.fromObject;
