import React, { useCallback, useEffect, useState } from 'react';
import Icons from '../../Icons';
import {
  Box,
  Button,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { HexColorPicker } from 'react-colorful';
import SelectFormatSize from './components/SelectFormatSize';
import { useEditor } from 'components/Canvas';
import Grid from './components/Grid';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';

function Default() {
  const editor = useEditor();
  const { currentBackgroundColor } = useTemplateEditorContext();

  const [options, setOptions] = useState({
    backgroundColor: '#000000',
  });

  const setBackgroundColor = useCallback(
    color => {
      editor.handlers.frameHandler.setBackgroundColor(color);
      setOptions({ ...options, backgroundColor: color });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor]
  );

  useEffect(() => {
    setOptions({ ...options, backgroundColor: currentBackgroundColor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBackgroundColor]);

  useEffect(() => {
    if (editor) {
      const backgroundColor = editor.handlers.frameHandler.getBackgroundColor();
      setOptions({ ...options, backgroundColor });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return (
    <Box
      sx={{
        height: '60px',
        display: 'grid',
        alignItems: 'center',
        padding: '0 1.5rem',
        gridTemplateColumns: '200px 1fr 200px',
      }}
    >
      <Box>
        <Popover placement="bottom-start">
          <PopoverTrigger>
            <IconButton
              name="background"
              color={options.backgroundColor}
              borderRadius="0"
              variant="outline"
              size="sm"
              icon={<Icons.Square size={32} />}
            />
          </PopoverTrigger>
          <PopoverContent
            sx={{ display: 'flex', width: 'auto', padding: '1.5rem' }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}
            >
              <HexColorPicker
                name="colorPicker"
                color={options.backgroundColor}
                onChange={color => setBackgroundColor(color)}
              />
              <Box sx={{ width: '200px' }}>
                <Input
                  textAlign="center"
                  name="colorSelector"
                  value={options.backgroundColor}
                  onChange={e =>
                    setOptions({ ...options, backgroundColor: e.target.value })
                  }
                />
                <Button
                  name="applyColor"
                  onClick={() => setBackgroundColor(options.backgroundColor)}
                  mt="0.75rem"
                  w="100%"
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </PopoverContent>
        </Popover>
      </Box>

      <SelectFormatSize />
      <Grid />
    </Box>
  );
}

export default Default;
