import React from 'react';
import colors from '../../../src/theme/Colors.module.scss';
import InputFeedback from '../InputFeedback/InputFeedback';
import './FieldProto.scss';

const FieldProto = ({ type, error, useArrow, selected, ...props }) => {
  const customArrowStyle = {
    top: props.label ? '2.45rem' : '0.82rem',
    color: props.disabled ? colors.lighterGrey : colors.darkGrey,
  };

  if (type === 'select') {
    const { options, placeHolder, ...selectProps } = props;

    const grouped = options.every(op => op.optgroup);

    const renderOptions = () => {
      if (grouped) {
        let optionsOptGroup = [...new Set(options.map(op => op.optgroup))];

        return optionsOptGroup.map(groupName => {
          const groupOptions = options.filter(op => op.optgroup === groupName);
          return (
            <optgroup label={groupName} key={groupName}>
              {groupOptions.map(option => {
                const { value, key } = option;
                return (
                  <option
                    value={value === selected}
                    title={key}
                    key={`${groupName}-${option.value}`}
                    {...option}
                  >
                    {option.label}
                  </option>
                );
              })}
            </optgroup>
          );
        });
      } else {
        return options.map(option => {
          const { value, key, ...props } = option;
          return (
            <option value={value} key={option.value} {...props}>
              {option.label}
            </option>
          );
        });
      }
    };

    let placeHolderOpt = placeHolder ? (
      <option value="" disabled>
        {placeHolder}
      </option>
    ) : (
      ''
    );
    return (
      <label htmlFor={props.name} error={error}>
        {props.label}
        <select className="filedproto-select" {...selectProps}>
          {placeHolderOpt}
          {renderOptions()}
        </select>
        <div className="customArrow" style={customArrowStyle}>
          <i className="fas fa-caret-down"></i>
        </div>
        <InputFeedback name={selectProps.name} />
      </label>
    );
  } else {
    return (
      <label htmlFor={props.name} error={error}>
        {props.label}
        <input type={type} {...props} />

        {useArrow && (
          <div className="customArrow" style={customArrowStyle}>
            <i className="fas fa-caret-down"></i>
          </div>
        )}

        <InputFeedback name={props.name} />
      </label>
    );
  }
};

export default FieldProto;
