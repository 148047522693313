import React from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';

// This component requires the string/jsx-formatted "content" prop.
// The "title", "footer" (true, false), "onClose" and "footerContent" (string/jsx-formatted) props are optional.
// If footer={true}, "footerContent" should also be provided.

const Modal = props => {
  const { onClose, title, render, id, footer, footerContent } = props;

  const target = document.querySelector('#app');

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const buildFooter = () => {
    if (footer) {
      return (
        <div className="modalFooter">
          <p>{footerContent ? footerContent : ''}</p>
        </div>
      );
    }
  };

  const buildModal = () => {
    return (
      <div
        id={id}
        className="modal"
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <div className="modalBG"></div>

        <div className="modalWin">
          <div className="modalHeader">
            <p className="modalTitle">{title ? title : ''}</p>

            <div className="closeModalIcon" onClick={() => closeModal()}>
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="modalBody">{render}</div>

          {buildFooter()}
        </div>
      </div>
    );
  };

  return <>{createPortal(buildModal(), target)}</>;
};

export default Modal;
