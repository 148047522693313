import React, { useState } from 'react';
import Icons from '../../../Icons';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Center,
  Divider,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { creativesSelectors } from 'modules/creatives/creativesDuck';
import { parseSizeFormat } from 'components/Canvas/utils/transform';
import { useEditor } from 'components/Canvas';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';

function SelectFormatSize() {
  const [options, setOptions] = useState({
    backgroundColor: '#ffffff',
    isPortrait: true,
    sizeFormatId: 1,
  });
  const { onOpen, onClose, isOpen } = useDisclosure();

  const editor = useEditor();
  const { doubleSided, sizeFormat } = useTemplateEditorContext();

  // Selectors
  const sizeFormats = useSelector(state =>
    creativesSelectors.sizeFormats(state)
  );

  const changeSizeFormat = newVal => {
    const newFormat = sizeFormats.find(
      format => format.id === parseInt(newVal)
    );
    const isPortrait = sizeFormat.isPortrait;
    const newSizeFormat = parseSizeFormat(newFormat);
    const updatedNewSizeFormat = { ...newSizeFormat, isPortrait };
    editor.handlers.frameHandler.update(updatedNewSizeFormat);
    setOptions({ ...options, sizeFormatId: parseInt(newVal), isPortrait });
    editor.handlers.zoomHandler.initialZoom();
    editor.handlers.frameHandler.setButtonsPosition();
  };

  const changeOrientation = () => {
    const updatedSizeFormat = {
      ...sizeFormat,
      isPortrait: !sizeFormat.isPortrait,
    };
    editor.handlers.frameHandler.update(updatedSizeFormat);
    editor.handlers.scrollbarHandler.centerCanvas();
  };

  const enabledDoubleSided = () => {
    editor.handlers.frameHandler.remove('backSide');
    editor.handlers.scrollbarHandler.setScrollToTop();
    editor.handlers.scrollbarHandler.updateScrollPosition();
  };

  return (
    <Box
      name="formatSize"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        justifyContent: 'center',
      }}
    >
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom"
      >
        <Tooltip placement="bottom" label="Format size">
          <Box display="inline-block">
            <PopoverTrigger>
              <Button
                size="sm"
                borderRadius="0"
                fontWeight="400"
                variant="outline"
                fontSize="14px"
                rightIcon={<Icons.ChevronDown size={21} />}
              >
                {sizeFormat.label}
              </Button>
            </PopoverTrigger>
          </Box>
        </Tooltip>

        <PopoverContent sx={{ width: '240px' }}>
          <Box>
            {sizeFormats?.map(sizeFormat => {
              return (
                <Box
                  onClick={() => {
                    changeSizeFormat(sizeFormat.id);
                    onClose();
                  }}
                  sx={{
                    height: '48px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 1rem',
                    fontSize: '14px',
                    ':hover': {
                      background: 'rgba(0,0,0,0.02)',
                      cursor: 'pointer',
                    },
                  }}
                  key={sizeFormat.id}
                >
                  {sizeFormat.label}
                </Box>
              );
            })}
          </Box>
        </PopoverContent>
      </Popover>

      <Center height="30px">
        <Divider orientation="vertical" />
      </Center>

      <Tooltip label="Orientation">
        <Box name="orientation">
          <IconButton
            onClick={changeOrientation}
            color="#333333"
            borderColor="#A7A7A7"
            borderRadius="0"
            variant="ghost"
            size="sm"
            icon={
              sizeFormat.isPortrait ? (
                <Icons.PanoramaVertical size={22} />
              ) : (
                <Icons.PanoramaHorizontal size={22} />
              )
            }
          />
        </Box>
      </Tooltip>

      {doubleSided && (
        <Tooltip label="Double sided">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="#333333"
              borderColor="#A7A7A7"
              borderRadius="0"
              variant="ghost"
              size="sm"
              onClick={enabledDoubleSided}
              icon={<Icons.DoubleSide size={24} />}
            />
            <Box sx={{ fontSize: '10px' }}>
              <Box>Doubled sided</Box>
              <Box>print enabled</Box>
            </Box>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

export default SelectFormatSize;
