import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { Flex } from '@chakra-ui/react';
import { bufferModes } from 'components/Canvas/common/constants';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import {
  loadBGIntoCanvas,
  calcFitRatio,
  SCALE_FACTOR,
} from 'helpers/canvasHelpers';

const bleed = 0;

function Preview({ previewImage }) {
  const previewRef = useRef();
  const { sizeFormat } = useTemplateEditorContext();

  const autoFit = dimension => {
    // This autoFit function is different from others -- uses previewMode and returns a dimension.
    const format = sizeFormat;
    const newWidth =
      (sizeFormat.isPortrait ? format.pixelWidth : format.pixelHeight) *
      SCALE_FACTOR;
    const newHeight =
      (sizeFormat.isPortrait ? format.pixelHeight : format.pixelWidth) *
      SCALE_FACTOR;
    const calcFitArgs = {
      previewMode: true,
      fitMode: bufferModes[0],
      canvasDimensions: { width: newWidth, height: newHeight },
    };

    const { width, height } = calcFitArgs.canvasDimensions;
    const fitRatio = calcFitRatio(calcFitArgs);
    const zoomedDimensions = {
      width: Math.round(width * fitRatio.zoomVal) + bleed,
      height: Math.round(height * fitRatio.zoomVal) + bleed,
    };

    return dimension === 'width'
      ? zoomedDimensions.width
      : zoomedDimensions.height;
  };

  const makePreviewCanvas = image => {
    const mainHeight = autoFit('height');
    const mainWidth = autoFit('width');
    return new Promise(resolve => {
      fabric.Image.fromURL(image, fabricImage => {
        const { width, height } = fabricImage;
        const dimensions = {
          canvasWidth: width,
          canvasHeight: height,
          width: mainWidth,
          height: mainHeight,
        };

        const myPreviewCanvas = new fabric.Canvas(
          previewRef.current,
          dimensions
        );
        // setCanvasPreview(myPreviewCanvas);
        const conf = {
          top: myPreviewCanvas.height / 2,
          left: myPreviewCanvas.width / 2,
          width,
          height,
          scaleX:
            myPreviewCanvas[sizeFormat.isPortrait ? 'width' : 'height'] / width,
          scaleY:
            myPreviewCanvas[sizeFormat.isPortrait ? 'height' : 'width'] /
            height,
          originX: 'center',
          originY: 'center',
        };

        loadBGIntoCanvas(fabricImage, conf, myPreviewCanvas).then(resolve);

        !sizeFormat.isPortrait && myPreviewCanvas.backgroundImage.rotate(-90);

        // TODO: Ask if the stitcher is aware of background color.
        // It seems to always returns white as the background color.
        myPreviewCanvas.backgroundColor = '#ffffff';
        myPreviewCanvas.requestRenderAll();
      });
    });
  };

  useEffect(() => {
    if (previewImage) {
      makePreviewCanvas(previewImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImage]);

  return (
    <Flex justifyContent="center" paddingX="1rem">
      <canvas ref={previewRef} />
    </Flex>
  );
}

export default Preview;
