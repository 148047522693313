import { SCALE_FACTOR } from '../common/constants';

class ExportObject {
  run(item, sizeFormat, frameOptions) {
    let object;
    switch (item.type) {
      case 'DynamicText':
        object = this.dynamicText(item, sizeFormat, frameOptions);
        break;
      case 'StaticText':
        object = this.staticText(item, sizeFormat, frameOptions);
        break;
      case 'StaticImage':
        object = this.staticImage(item, sizeFormat, frameOptions);
        break;
      case 'DynamicImage':
        object = this.dynamicImage(item, sizeFormat, frameOptions);
        break;
      case 'path':
        object = this.staticPath(item, sizeFormat, frameOptions);
        break;
      case 'ConditionalText':
        object = this.conditionalText(item, sizeFormat, frameOptions);
        break;
      case 'ConditionalImage':
        object = this.conditionalImage(item, sizeFormat, frameOptions);
        break;
      case 'ConditionalQr':
        object = this.conditionalQr(item, sizeFormat, frameOptions);
        break;
      default:
    }
    return object;
  }

  staticText(item, sizeFormat, frameOptions) {
    const { type, subtype } = item;
    const objectType = this.getObjectType(type, subtype);
    const baseOptions = this.getBaseOptions(item, sizeFormat, frameOptions);
    const {
      fontFamily,
      textAlign,
      fontSize,
      fontWeight,
      charSpacing,
      lineHeight,
      fill,
      text,
      angle,
      opacity,
      // fontStyle,
      z_index,
    } = item;
    const { __fontFilename } = item.metadata;
    const scaledFontSize = fontSize / SCALE_FACTOR;
    const metadata = {
      ...item.metadata,
      angle,
      fill,
      fontWeight,
      charSpacing,
      fontSize: scaledFontSize,
      value: text,
      fontFamily,
      textAlign,
      type: 'textbox',
      lineHeight,
      opacity,
      __fontFilename,
    };
    // label default is provided with objectType, if baseOptions has a label
    // it will override the default value allowing persistence of customized labels
    const { label, ...restBaseOptions } = baseOptions;

    const object = {
      ...objectType,
      ...restBaseOptions,
      ...(label != null && { label }),
      metadata,
      z_index,
    };

    return object;
  }
  dynamicText(item, sizeFormat, frameOptions) {
    const { type, subtype } = item;
    const objectType = this.getObjectType(type, subtype);

    const baseOptions = this.getBaseOptions(item, sizeFormat, frameOptions);
    const {
      fontFamily,
      textAlign,
      fontSize,
      fontWeight,
      charSpacing,
      lineHeight,
      fill,
      // text,
      angle,
      originalText,
      keyValues,
      opacity,
      fontStyle,
      z_index,
    } = item;
    const { __fontFilename } = item.metadata;
    const scaledFontSize = fontSize / SCALE_FACTOR;
    const metadata = {
      ...item.metadata,
      angle,
      fill,
      fontWeight,
      charSpacing,
      fontSize: scaledFontSize,
      template: originalText,
      fontFamily,
      keyValues,
      textAlign,
      type: 'textbox',
      lineHeight,
      opacity,
      fontStyle,
      __fontFilename,
    };
    // label default is provided with objectType, if baseOptions has a label
    // it will override the default value allowing persistence of customized labels
    const { label, ...restBaseOptions } = baseOptions;

    const object = {
      ...objectType,
      ...restBaseOptions,
      ...(label != null && { label }),
      metadata,
      z_index,
    };
    return object;
  }

  staticImage(item, sizeFormat, options) {
    const { type, subtype, opacity, metadata, z_index } = item;
    const objectType = this.getObjectType(type, subtype);
    const baseOptions = this.getBaseOptions(item, sizeFormat, options);
    const object = {
      ...objectType,
      ...baseOptions,
      metadata: {
        url: item.src,
        opacity,
        angle: metadata?.angle || 0,
        imported: metadata?.imported,
      },
      z_index,
    };
    return object;
  }

  staticPath(item, sizeFormat, options) {
    const { metadata, z_index } = item;
    const baseOptions = this.getBaseOptions(item, sizeFormat, options);
    // console.log({type, subtype})
    const object = {
      ...baseOptions,
      res_type: 'shape',
      var_type: 'static',
      label: 'shape',
      metadata: {
        shape_type: 'path',
        fill: item.fill,
        path: item.path,
        imported: metadata?.imported,
      },
      z_index,
    };
    return object;
  }

  dynamicImage(item, sizeFormat, frameOptions) {
    const { width, height, type, angle, subtype, keyValues, opacity, z_index } =
      item;

    const objectType = this.getObjectType(type, subtype, keyValues.length > 0);
    const baseOptions = this.getBaseOptions(item, sizeFormat, frameOptions);
    const { run_scaleToWidth } = item.metadata;
    const metadata = {
      ...item.metadata,
      originX: item.originX,
      originY: item.originY,
      angle,
      width: isNaN(width) ? 0 : width,
      height: isNaN(height) ? 0 : height,
      keyValues,
      opacity,
      ...(run_scaleToWidth && { run_scaleToWidth: baseOptions.size_x }),
    };

    // label default is provided with objectType, if baseOptions has a label
    // it will override the default value allowing persistence of customized labels
    const { label, size_x, size_y, ...restBaseOptions } = baseOptions;

    const object = {
      ...objectType,
      ...restBaseOptions,
      ...(label != null && { label }),
      ...(run_scaleToWidth && {
        run_scaleToWidth: baseOptions.size_x,
      }),
      size_x: run_scaleToWidth ? 0 : size_x,
      size_y: run_scaleToWidth ? 0 : size_y,
      metadata,
      z_index,
    };

    return object;
  }

  getConditionalKeys(conditional, template_text) {
    let keys = [];
    // get keys from template
    let template_vars =
      template_text.match(/\{\{\s*\s*[\S\s]*?\}\}/g)?.map(function (x) {
        // eslint-disable-next-line no-useless-escape
        return x.match(/[\w\.]+/)[0];
      }) ?? [];

    if (!Array.isArray(conditional)) {
      // TODO: implementation does not support multiple keys for this case
      keys = conditional.conditions.map(x => {
        let [key] = x.logic.reduce((acc, y) => {
          if (typeof y === 'object' && 'key' in y) {
            return [...acc, y['key']];
          }
          return acc;
        }, []);
        return key;
      });
    } else {
      // NOTE / TODO: this case is almost complete, it is missing the retrieval of keys
      // from a result of a condition
      const raw_keys = conditional.reduce((acc0, x) => {
        // filter out keys being set by template_conditional
        template_vars = template_vars.filter(tx => x.key !== tx);
        let res1 = x.conditions.reduce((acc1, y) => {
          let res2 = y.logic.reduce((acc2, z) => {
            if (typeof z === 'object' && 'key' in z) {
              return [...acc2, z['key']];
            }
            return acc2;
          }, []);
          return [...acc1, ...res2];
        }, []);
        return [...acc0, ...res1];
      }, []);
      // combine template keys and keys needed by conditional
      const keys_set = new Set([...raw_keys, ...template_vars]);
      // array of unique keys
      keys = [...keys_set];
    }
    return keys;
  }

  conditionalText(item, sizeFormat, frameOptions) {
    const { type, subtype } = item;
    const objectType = this.getObjectType(type, subtype);
    const baseOptions = this.getBaseOptions(item, sizeFormat, frameOptions);
    const {
      fontFamily,
      textAlign,
      fontSize,
      fontWeight,
      charSpacing,
      lineHeight,
      fill,
      text,
      angle,
      opacity,
      // fontStyle,
      keys,
      z_index,
    } = item;
    const { __fontFilename } = item.metadata;

    // use existing keys since we do not support editing of conditional text template
    // var type in editor at the moment.
    //const keys = this.getConditionalKeys(template_conditional, text);

    const scaledFontSize = fontSize / SCALE_FACTOR;
    const metadata = {
      ...item.metadata,
      angle,
      fill,
      fontWeight,
      charSpacing,
      fontSize: scaledFontSize,
      template: text,
      fontFamily,
      textAlign,
      type: 'textbox',
      lineHeight,
      opacity,
      __fontFilename,
    };

    const object = {
      ...objectType,
      ...baseOptions,
      keys,
      metadata,
      z_index,
    };

    return object;
  }

  conditionalImage(item, sizeFormat, frameOptions) {
    const { width, height, type, angle, subtype, keyValues, opacity, z_index } =
      item;
    const objectType = this.getObjectType(type, subtype, keyValues.length > 0);
    const baseOptions = this.getBaseOptions(item, sizeFormat, frameOptions);
    const { run_scaleToWidth } = item.metadata;
    const metadata = {
      ...item.metadata,
      originX: item.originX,
      originY: item.originY,
      angle,
      width,
      height,
      keyValues,
      opacity,
      ...(run_scaleToWidth && { run_scaleToWidth: baseOptions.size_x }),
    };
    const object = {
      ...objectType,
      ...baseOptions,
      ...(run_scaleToWidth && {
        run_scaleToWidth: baseOptions.size_x,
      }),
      metadata,
      z_index,
    };

    return object;
  }

  conditionalQr(item, sizeFormat, frameOptions) {
    const { width, height, type, angle, subtype, keyValues, opacity, z_index } =
      item;
    const objectType = this.getObjectType(type, subtype, keyValues.length > 0);
    const baseOptions = this.getBaseOptions(item, sizeFormat, frameOptions);
    const { run_scaleToWidth } = item.metadata;
    const metadata = {
      ...item.metadata,
      originX: item.originX,
      originY: item.originY,
      angle,
      width,
      height,
      keyValues,
      opacity,
      ...(run_scaleToWidth && { run_scaleToWidth: baseOptions.size_x }),
    };
    const object = {
      ...objectType,
      ...baseOptions,
      ...(run_scaleToWidth && {
        run_scaleToWidth: baseOptions.size_x,
      }),
      metadata,
      z_index,
    };

    return object;
  }

  getBaseOptions(item, sizeFormat, frameOptions) {
    const { pixelWidth, isPortrait } = sizeFormat;
    const { id, top, left, width, height, scaleX, scaleY, label } = item;

    const baseOptions = {
      id,
      keys: item.keys ? item.keys : [],
      pos_x: isPortrait
        ? Number(
            (left / SCALE_FACTOR - frameOptions.left / SCALE_FACTOR).toFixed(2)
          )
        : Number(
            (
              pixelWidth -
              top / SCALE_FACTOR +
              frameOptions.top / SCALE_FACTOR
            ).toFixed(2)
          ),
      pos_y: isPortrait
        ? Number(
            (top / SCALE_FACTOR - frameOptions.top / SCALE_FACTOR).toFixed(2)
          )
        : Number(
            (left / SCALE_FACTOR - frameOptions.left / SCALE_FACTOR).toFixed(2)
          ),
      size_x: isNaN(width)
        ? 0
        : Number(((width * scaleX) / SCALE_FACTOR).toFixed(2)),
      size_y: isNaN(height)
        ? 0
        : Number(((height * scaleY) / SCALE_FACTOR).toFixed(2)),
      label,
    };
    return baseOptions;
  }

  getObjectType(type, subtype, isDynamicVar = false) {
    if (type === 'image') {
      return {
        label: 'Static QR Code',
        res_type: 'qrc',
        var_type: 'static',
      };
    }

    if (type === 'StaticText') {
      return {
        label: 'txt',
        res_type: 'txt',
        var_type: 'static',
      };
    }

    if (type === 'DynamicText') {
      return {
        label: 'txt_template',
        res_type: 'txt_template',
        var_type: 'dynamic',
      };
    }

    if (type === 'ConditionalText') {
      return {
        label: 'cond_txt_template',
        res_type: 'cond_txt_template',
        var_type: 'dynamic',
      };
    }

    if (type === 'ConditionalImage') {
      return {
        label: 'cond_img',
        res_type: 'cond_img',
        var_type: 'dynamic',
      };
    }

    if (type === 'ConditionalQr') {
      return {
        label: 'cond_qrc',
        res_type: 'cond_qrc',
        var_type: 'dynamic',
      };
    }
    switch ([type, subtype].join(':')) {
      case 'DynamicImage:qrc':
        return {
          label: 'qrc',
          res_type: 'qrc',
          var_type: isDynamicVar ? 'dynamic' : 'static',
        };
      case 'DynamicImage:barcode':
        return {
          label: 'barcode',
          res_type: 'barcode',
          var_type: isDynamicVar ? 'dynamic' : 'static',
        };
      case 'DynamicImage:img':
        return {
          label: 'img',
          res_type: 'img',
          var_type: 'dynamic',
        };
      case 'StaticImage:qrc':
        return {
          label: 'qrc',
          res_type: 'qrc',
          var_type: 'static',
        };
      case 'StaticImage:barcode':
        return {
          label: 'barcode',
          res_type: 'barcode',
          var_type: 'static',
        };
      case 'StaticImage:img':
        return {
          label: 'img',
          res_type: 'img',
          var_type: 'static',
        };
      default:
    }
  }
}

export default new ExportObject();
