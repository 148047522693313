import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlag } from 'flags';
// import Pagination from 'components/Pagination/Pagination';
import TemplateCard from './TemplateCard/TemplateCard';
import Filter from 'components/Filter/Filter';
import Button from 'components/Button/Button';
import EntityTable from 'components/EntityTable/EntityTable';
import colors from 'theme/Colors.module.scss';
// import {
//   paramsToObject,
//   sortFromString,
//   sortToString,
// } from 'helpers/paramHelpers';
import './Templates.scss';

import { showMessages } from 'helpers/messageHelpers';
import * as appActions from 'modules/app/appActions';
import * as appSelectors from 'modules/app/appSelectors';

import { Box, Flex } from '@chakra-ui/react';
import DesignImporter from './Modals/DesignImporter/DesignImporter';

const TEMPLATES_PERSISTENCE_KEYS = ['search'];

const Templates = props => {
  const { getTemplates, deleteTemplateById, createTemplate, isMobile } = props;

  const getTemplatesHeaders = isReadOnly => {
    const TEMPLATES_HEADERS = [
      {
        name: 'Preview',
        field: 'preview',
        sortable: false,
        align: 'left',
        width: '2rem',
      },
      {
        name: 'Name',
        field: 'name',
        sortable: true,
        sortIcon: 'alpha',
        align: 'left',
        width: isMobile ? '3rem' : '10rem',
      },
      {
        name: 'Associations',
        field: 'associations',
        sortable: false,
        align: 'center',
        width: '4rem',
      },
    ].concat(
      !isReadOnly
        ? [
            {
              name: 'Actions',
              field: 'actions',
              sortable: false,
              align: isMobile ? 'left' : 'right',
              width: '2rem',
            },
          ]
        : []
    );

    return TEMPLATES_HEADERS;
  };

  const dispatch = useDispatch();
  const [searchParameters, setSearchParameters] = useState({
    search: '',
  });
  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const headers = getTemplatesHeaders(isReadOnly);
  const [refreshSymbol, setRefreshSymbol] = useState(Symbol());
  const [openDesignImporterModal, setOpenDesignImporterModal] = useState(false);

  const handleDeleteTemplate = () => {
    setRefreshSymbol(Symbol());
  };

  const handleDuplicateTemplate = () => {
    setRefreshSymbol(Symbol());
  };

  // Selectors
  const messages = useSelector(state => appSelectors.messages(state));

  // Dispatches
  const clearMessage = index => dispatch(appActions.clearMessage(index));

  const renderTemplateCard = (template, state) => {
    return (
      <TemplateCard
        key={template.id}
        focus={state.focus}
        headers={headers}
        isMobile={isMobile}
        // creatives={creatives}
        template={template}
        deleteTemplateById={deleteTemplateById}
        getTemplates={getTemplates}
        createTemplate={createTemplate}
        onDelete={handleDeleteTemplate}
        onDuplicate={handleDuplicateTemplate}
      />
    );
  };

  return (
    <>
      <Box
        className="templatesHeader"
        style={{
          display: isMobile ? 'block' : 'auto',
        }}
      >
        {!isReadOnly && (
          <Flex
            style={{
              width: isMobile ? '100%' : 'auto',
              justifyContent: isMobile ? 'center' : 'auto',
              marginTop: isMobile ? '0.5rem' : 'auto',
            }}
          >
            <div
              className="left"
              style={{
                justifyContent: isMobile ? 'center' : 'auto',
              }}
            >
              <Button
                type="button"
                icon="add"
                text="New Template"
                textColor={colors.slate}
                btnStyle="transparent"
                url="/template-editor?ref=/templates"
              />
            </div>
            <div className="left">
              <Button
                type="button"
                icon="add"
                text="Import Template"
                textColor={colors.slate}
                btnStyle="transparent"
                onClick={() => setOpenDesignImporterModal(true)}
              />
            </div>
          </Flex>
        )}
        <div
          className="right"
          style={{
            justifyContent: isMobile ? 'center' : 'auto',
            width: isMobile ? '100%' : 'auto',
            marginBottom: isMobile ? '0.5rem' : 'auto',
          }}
        >
          <div className="legend">Currently used in campaign(s).</div>
        </div>

        <Box fontSize={'12px'} className="search">
          <Filter
            value={searchParameters.search}
            placeholder="Search templates"
            onChange={e =>
              setSearchParameters({ ...searchParameters, search: e })
            }
          />
        </Box>
      </Box>

      <div className="templatesBody">
        <EntityTable
          headers={headers}
          pageable
          sortable
          focusable
          scrollable
          // scrollSpacerHeight={65}
          persistable
          persistenceKeys={TEMPLATES_PERSISTENCE_KEYS}
          defaultSearchParameters={searchParameters}
          entityType="creatives"
          refreshSymbol={refreshSymbol}
          getEntities={searchParameters => {
            return new Promise((resolve, reject) => {
              getTemplates(searchParameters, resolve, reject);
            });
          }}
          renderRow={renderTemplateCard}
        />
      </div>
      {showMessages(messages, clearMessage)}
      <DesignImporter
        open={openDesignImporterModal}
        onClose={() => setOpenDesignImporterModal(false)}
        refresh={() => setRefreshSymbol(Symbol())}
      />
    </>
  );
};

export default Templates;
