import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Canvas, { useEditor } from 'components/Canvas';
import ContextMenu from './components/ContextMenu';
import Navbar from './components/Navbar';
import Panel from './components/Panel';
import Toolbox from './components/Toolbox';
import Zoom from './components/Zoom';
import useParam from './hooks/useParam';
import LoadingModal from './components/LoadingModal';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import { downloadFonts, getTemplateFonts } from 'helpers/canvasHelpers';
import { parseSizeFormat } from 'components/Canvas/utils/transform';
import UserGuide from './components/UserGuide';
import NoSizeFormat from './NoSizeFormat';
import Loading from './Loading';
import NewFrameButtons from './components/NewFrameButtons';
import AddNewPage from './components/AddNewPage';
import BackFrameTitle from './components/FramesTitles/BackFrameTitle';
import FrontFrameTitle from './components/FramesTitles/FrontFrameTitle';
import FrontFrameButtons from './components/FramesTitles/FrontFrameButtons';
import BackFrameButtons from './components/FramesTitles/BackFrameButtons';
import EnabledDoubleSideModal from './components/EnabledDoubleSideModal';

import {
  templateEditorActions,
  templateEditorSelectors,
} from 'modules/templateEditor/templateEditorDuck';
import { creativesActions } from 'modules/creatives/creativesDuck';
import * as appActions from 'modules/app/appActions';

import './Editor.scss';

import { TemplateEditorContext } from './context/TemplateEditorContext';

const editorConfig = {
  clipToFrame: false,
  scrollLimit: 800,
};

const Editor = props => {
  const { onTemplateSave, showWizard, preloadedTemplate } = props;

  const [initialized, setInitialized] = useState(false);
  const templateEditorContext = useContext(TemplateEditorContext);
  const dispatch = useDispatch();
  const editor = useEditor();
  const {
    doubleSided,
    setDoubleSided,
    setIsEditorLoading,
    setDefaultFont,
    setDefaultFormatSize,
    setIsTemplateLoading,
    defaultFormatSize,
    currentTemplate,
    setCurrentTemplate,
    setIsEnabledDoubleSide,
  } = useTemplateEditorContext();

  const id = useParam('id');
  const reset = useRef(true);
  const initializedZoom = useRef(false);
  const unMounted = useRef(false);

  // Selectors
  const userFonts = useSelector(state =>
    templateEditorSelectors.userFonts(state)
  );

  // Dispatches
  const getPremadeTemplates = (successCallback, failureCallback) =>
    dispatch(
      templateEditorActions.getPremadeTemplates(
        successCallback,
        failureCallback
      )
    );
  const getTemplates = (payload, successCallback, failureCallback) =>
    dispatch(
      creativesActions.getTemplates(payload, successCallback, failureCallback)
    );
  const getSizeFormats = (successCallback, failureCallback) =>
    dispatch(creativesActions.getSizeFormats(successCallback, failureCallback));
  const getUserFonts = (successCallback, failureCallback) =>
    dispatch(
      templateEditorActions.getUserFonts(successCallback, failureCallback)
    );
  const getEnabledDoubleSideStatus = (successCallback, failureCallback) =>
    dispatch(
      templateEditorActions.getEnabledDoubleSideStatus(
        successCallback,
        failureCallback
      )
    );
  const getMruVariableKeys = (successCallback, failureCallback) =>
    dispatch(
      templateEditorActions.getMruVariableKeys(successCallback, failureCallback)
    );
  const getTemplateById = (id, successCallback, failureCallback) =>
    dispatch(
      templateEditorActions.getTemplateById(
        id,
        successCallback,
        failureCallback
      )
    );
  const setMessage = payload => dispatch(appActions.setMessage(payload));

  useEffect(() => {
    const handleInitialLoad = async () => {
      setIsEditorLoading(true);

      const premadeTemplatesPromise = new Promise((resolve, reject) => {
        getPremadeTemplates(
          data => resolve(data),
          err => reject(err)
        );
      });

      const templatesPromise = new Promise((resolve, reject) => {
        getTemplates(
          {},
          data => resolve(data),
          err => reject(err)
        );
      });

      const sizeFormatsPromise = new Promise((resolve, reject) => {
        getSizeFormats(
          data => resolve(data),
          err => reject(err)
        );
      });

      const userFontsPromise = new Promise((resolve, reject) => {
        getUserFonts(
          data => resolve(data),
          err => reject(err)
        );
      });

      const enabledDoubleSideStatusPromise = new Promise((resolve, reject) => {
        getEnabledDoubleSideStatus(
          data => resolve(data),
          err => reject(err)
        );
      });

      const MRULoadPromise = new Promise((resolve, reject) => {
        getMruVariableKeys(
          data => {
            resolve(data);
          },
          err => reject(`FAILED TO LOAD MRU: ${err}`)
        );
      });

      await Promise.allSettled([
        premadeTemplatesPromise,
        templatesPromise,
        sizeFormatsPromise,
        userFontsPromise,
        enabledDoubleSideStatusPromise,
        MRULoadPromise,
      ])
        .then(results => {
          // Error Handling
          results.forEach(result => {
            if (result.status === 'rejected')
              console.error(`Error: ${result.reason}`);
          });
          const sizeFormats = results[2].value;
          const fonts = results[3].value.data.data;
          const enabledDoubleSideStatus =
            results[4].value.double_sided_printing_enabled;

          if (fonts) {
            if (Object.keys(fonts.public).length > 1) {
              const familys = Object.keys(fonts.public).sort((a, b) =>
                a.localeCompare(b)
              );

              if (fonts.public['Arials']) {
                setDefaultFont(fonts.public['Arial'][0]);
              } else {
                const defaultFamily = fonts.public[familys[0]];
                setDefaultFont(defaultFamily[0]);
              }
            } else if (fonts.private && fonts.private.length > 1) {
              setDefaultFont(fonts.private);
            }
          }

          setDefaultFormatSize(sizeFormats.data[0]);
          setIsEnabledDoubleSide(enabledDoubleSideStatus);
        })
        .finally(() => {
          setInitialized(true);
          setIsEditorLoading(false);
        });
    };
    handleInitialLoad();

    return () => {
      setDoubleSided(false);
      unMounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetEditor = useCallback(() => {
    editor.handlers.frameHandler.update(parseSizeFormat(defaultFormatSize));
    if (doubleSided === true) {
      editor.handlers.frameHandler.enabledDoubleSided(false);
      editor.handlers.frameHandler.remove('backSide');
    }
    editor.handlers.objectsHandler.clear(false);
    setCurrentTemplate(null);
    editor.handlers.zoomHandler.initialZoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, editor, doubleSided]);

  useEffect(() => {
    if (initialized && editor && reset.current) {
      // only run initialZoom once per page load
      if (initializedZoom.current === false) {
        editor.handlers.zoomHandler.initialZoom();
        initializedZoom.current = true;
      }
      if (id) {
        loadTemplateById(id);
        if (currentTemplate && currentTemplate.id !== +id) {
          if (doubleSided === true) {
            editor.handlers.frameHandler.enabledDoubleSided(false);
          }
          editor.handlers.zoomHandler.initialZoom();
        }
      } else if (preloadedTemplate) {
        loadTemplate(preloadedTemplate);
        editor.handlers.zoomHandler.initialZoom();
      } else {
        resetEditor();
      }
    }
    reset.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, initialized, editor, currentTemplate]);

  const loadTemplateById = async id => {
    try {
      const template = await new Promise((resolve, reject) => {
        getTemplateById(
          id,
          data => resolve(data),
          err => reject(err)
        );
      });
      if (template) await loadTemplate(template);
    } catch (err) {
      console.error(err);
    }
  };

  const loadTemplate = async template => {
    setIsTemplateLoading(true);
    const { is_portrait, size_format_id, size_format } = template || {};
    const args = {
      size_format: { ...size_format, isPortrait: is_portrait },
      size_format_id,
      userFonts,
      template,
      local: false,
    };
    args.setMessage = setMessage;
    const fontsList = getTemplateFonts(args, userFonts);
    try {
      await downloadFonts(fontsList);
    } catch (err) {
      console.error(err);
    }
    if (unMounted.current) return;
    await editor.handlers.templateHandler.importFromJSON(args);
    reset.current = false;
    setCurrentTemplate(template);
    setIsTemplateLoading(false);
  };

  if (!initialized) {
    return <Loading />;
  }

  if (!defaultFormatSize) {
    return <NoSizeFormat />;
  }

  return (
    <div className="template-editor">
      <LoadingModal />
      <EnabledDoubleSideModal />
      <ContextMenu />
      {showWizard && <UserGuide />}
      <Navbar onTemplateSave={onTemplateSave} />

      <div className="template-editor__content">
        <Panel getTemplateById={getTemplateById} {...props} />
        <div className="template-editor__main-content">
          <Toolbox />
          <Canvas config={editorConfig} context={templateEditorContext} />
          <Zoom />
        </div>
        <NewFrameButtons />
        <AddNewPage />
        <FrontFrameTitle />
        <FrontFrameButtons />
        <BackFrameTitle />
        <BackFrameButtons />
      </div>
    </div>
  );
};
export default Editor;
