import Editor from './Editor';
import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import KeysMenu from 'scenes/templateEditor/components/KeysMenu';
// import { defaultEditorConfig } from './common/constants';

import './objects';
import { objectTypes } from './common/constants';

function Canvas({ config, context }) {
  const containerRef = useRef();
  const { setEditor } = context;

  // useEffect(() => {
  //   let ctrlDown = false;
  //   const leftCtrlKey = 17;
  //   const rightCtrlKey = 91;
  //   // const vKey = 86;
  //   // const cKey = 67;
  //   const zKey = 90;
  //   document.body.addEventListener('keydown', e => {
  //     if (e.keyCode === leftCtrlKey || e.keyCode === rightCtrlKey)
  //       ctrlDown = true;
  //     if (ctrlDown && e.keyCode === zKey) e.preventDefault();
  //   });
  //   document.body.addEventListener('keyup', e => {
  //     if (e.keyCode === leftCtrlKey || e.keyCode === rightCtrlKey)
  //       ctrlDown = false;
  //   });
  // }, []);

  // useEffect(() => {
  //   let ctrlDown = false;
  //   // const ctrlKey = 17;
  //   // const vKey = 86;
  //   // const cKey = 67;
  //   const zKey = 90;

  //   document.body.onkeydown = function (e) {
  //     if (e.keyCode === 17 || e.keyCode === 91) {
  //       ctrlDown = true;
  //     }
  //     if (ctrlDown && e.keyCode === zKey) {
  //       e.preventDefault();
  //       return false;
  //     }
  //   };
  //   document.body.onkeyup = function (e) {
  //     if (e.keyCode === 17 || e.keyCode === 91) {
  //       ctrlDown = false;
  //     }
  //   };
  // });

  useEffect(() => {
    const editorConfig = config;
    const container = containerRef.current;

    const { clientHeight, clientWidth } = containerRef.current;
    const canvas = new fabric.Canvas('canvas', {
      backgroundColor: '#f6f7f9',
      height: clientHeight,
      width: clientWidth,
      preserveObjectStacking: true,
      controlsAboveOverlay: true,
      fireRightClick: true,
      stopContextMenu: true,
    });

    canvas.on('mouse:up', e => {
      const activeObjects = canvas
        .getActiveObjects()
        .filter(object => object.type !== objectTypes.FRAME);
      const canvasEl = document.getElementById('canvas');
      const canvasPosition = {
        left: canvasEl?.getBoundingClientRect().left,
        top: canvasEl?.getBoundingClientRect().top,
      };
      // trigger event only for multiple selection
      const eventData = {
        object: activeObjects,
        visible: false,
        position: {
          left: canvasPosition.left + e.pointer.x + 8,
          top: canvasPosition.top + e.pointer.y - 24,
        },
      };
      if (e.button === 1) {
        eventData.visible = false;
      } else if (e.button === 3) {
        if (activeObjects.length > 1) {
          eventData.visible = true;
        }
      }
      canvas.fire('object:rightclicked', eventData);
    });

    const editor = new Editor({
      canvas: canvas,
      context: context,
      config: editorConfig,
    });

    setEditor(editor);

    const resizeObserver = new ResizeObserver(entries => {
      const { width = clientWidth, height = clientHeight } =
        (entries[0] && entries[0].contentRect) || {};
      editor.canvas.resize(width, height);
    });
    resizeObserver.observe(container);

    return () => {
      canvas.dispose();
      editor.destroy();
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="undigital-editor-container"
      ref={containerRef}
      style={{ flex: 1, position: 'relative' }}
    >
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      >
        <KeysMenu />
        <canvas id="canvas"></canvas>
      </div>
    </div>
  );
}

export default Canvas;
