import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Tooltip, IconButton, Input, useDisclosure } from '@chakra-ui/react';
import Icons from 'scenes/templateEditor/components/Icons';
import DeleteModal from 'scenes/templateEditor/components/Navbar/components/DeleteModal';
import ConfirmChangesModal from 'scenes/templateEditor/components/Navbar/components/ConfirmChangesModal';
import { templateHasChanged } from 'helpers/editor';
import { useEditor } from 'components/Canvas';
import { duplicateTemplate } from 'helpers/canvasHelpers';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';

import './TemplateDropdown.scss';

import * as appActions from 'modules/app/appActions';
import {
  creativesSelectors,
  creativesActions,
} from 'modules/creatives/creativesDuck';
import { templateEditorActions } from 'modules/templateEditor/templateEditorDuck';

const TemplateDropdown = props => {
  const {
    updateTemplateHandler,
    onCreateTemplateModalOpen,
    setTemplateNameEdit,
    isDisabled,
  } = props;

  const { currentTemplate } = useTemplateEditorContext();

  const [selectedOption, setSelectedOption] = useState({
    label: 'New template',
    template: null,
    renaming: false,
    toBeDeleted: null,
    deleteInProgress: false,
  });

  const [tempSelectedOption, setTempSelectedOption] = useState();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const {
    isOpen: isConfirmChangesModalOpen,
    onClose: onConfirmChangesModalClose,
    onOpen: onConfirmChangesModalOpen,
  } = useDisclosure();

  const editor = useEditor();
  const history = useHistory();
  const dispatch = useDispatch();

  // Selectors
  const templates = useSelector(state => creativesSelectors.creatives(state));

  // Dispatches
  const getTemplates = (payload, successCallback, failureCallback) =>
    dispatch(
      creativesActions.getTemplates(payload, successCallback, failureCallback)
    );
  const updateTemplateById = (
    templateID,
    payload,
    successCallback,
    failureCallback
  ) =>
    dispatch(
      templateEditorActions.updateTemplateById(
        templateID,
        payload,
        successCallback,
        failureCallback
      )
    );
  const createTemplate = (payload, successCallback, failureCallback) =>
    dispatch(
      templateEditorActions.createTemplate(
        payload,
        successCallback,
        failureCallback
      )
    );
  const createTemplateDoubleSided = (
    payload,
    successCallback,
    failureCallback
  ) =>
    dispatch(
      templateEditorActions.createTemplateDoubleSided(
        payload,
        successCallback,
        failureCallback
      )
    );
  const deleteTemplateById = (id, successCallback, failureCallback) =>
    dispatch(
      creativesActions.deleteTemplateById(id, successCallback, failureCallback)
    );
  const setMessage = payload => dispatch(appActions.setMessage(payload));

  const everyTemplate = [{ name: 'New template', id: -1 }, ...templates];

  const mapTemplateToOption = template => {
    return {
      key: template.id,
      value: template.id,
      label: template.name,
      renaming: false,
      toBeDeleted: null,
      deleteInProgress: false,
      campaigns_count: template.campaigns_count || 0,
      image:
        template.double_sided === false && template.bg_img
          ? [template.bg_img.thumb_url]
          : template.double_sided === true &&
            template.ad_template_pages.length > 1
          ? template.ad_template_pages
              .sort((a, b) => a.page_num - b.page_num)
              .map(page => (page.bg_img ? page.bg_img.thumb_url : null))
          : null,
    };
  };

  let templateOptions = everyTemplate
    .map(o => mapTemplateToOption(o))
    .sort((a, b) => {
      const firstOption = b.label === everyTemplate[0].name;

      if (!firstOption) {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
      }

      return 0;
    });

  const onChangesSaved = async () => {
    if (currentTemplate) {
      await updateTemplateHandler('change-save');
      closeConfirmModal();
    } else {
      onCreateTemplateModalOpen();
    }
    onConfirmChangesModalClose();
  };

  const closeConfirmModal = () => {
    if (tempSelectedOption.label === 'New template') {
      setSelectedOption({
        label: 'New template',
        template: null,
        renaming: false,
        toBeDeleted: null,
        deleteInProgress: false,
      });
    } else {
      setSelectedOption(tempSelectedOption);
    }

    tempSelectedOption.label === 'New template'
      ? history.push('/template-editor')
      : history.push(`/template-editor?id=${tempSelectedOption.value}`);

    onConfirmChangesModalClose();
  };

  const onChangeTemplate = async option => {
    const localTemplate = editor.handlers.templateHandler.exportToJSON();

    const localTemplateHasChanged = await templateHasChanged(
      currentTemplate,
      localTemplate
    );

    if (option) {
      setTempSelectedOption(option);
    } else {
      setTempSelectedOption({
        label: 'New template',
        template: null,
        renaming: false,
        toBeDeleted: null,
        deleteInProgress: false,
      });
    }

    if (localTemplateHasChanged) {
      onConfirmChangesModalOpen();
    } else {
      option
        ? history.push(`/template-editor?id=${option.value}`)
        : history.push('/template-editor');
    }
  };

  useEffect(() => {
    if (currentTemplate) {
      setSelectedOption(mapTemplateToOption(currentTemplate));
    } else {
      setSelectedOption({
        label: 'New template',
        template: null,
        renaming: false,
        toBeDeleted: null,
        deleteInProgress: false,
      });
    }
  }, [currentTemplate]);

  const updateTemplateNameEditHandler = async () => {
    setTemplateNameEdit(false);
    const payload = {
      ad_template_vars: currentTemplate.ad_template_vars || [],
      name: selectedOption.label,
    };

    await new Promise((resolve, reject) => {
      updateTemplateById(
        currentTemplate.id,
        payload,
        data => resolve(data),
        err => reject(err)
      );
    });
    await getTemplates({}, () => {});
    setSelectedOption({ ...selectedOption, renaming: false });
    setMessage({
      message: 'Updated template name',
      type: 'success',
    });
  };

  const duplicateTemplateHandler = async template => {
    const context = {
      createTemplate,
      createTemplateDoubleSided,
      getTemplates,
      history,
    };

    try {
      await duplicateTemplate(
        template,
        context,
        () => {
          setMessage({
            type: 'success',
            message: 'Template duplicated successfully',
          });
        },
        () => {
          setMessage({
            type: 'error',
            message: 'Template duplication failed',
          });
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const deleteTemplateHandler = async id => {
    try {
      setSelectedOption({ ...selectedOption, deleteInProgress: true });
      await new Promise((resolve, reject) => {
        deleteTemplateById(
          id,
          () => resolve(true),
          err => reject(err)
        );
      });
      setSelectedOption({
        ...selectedOption,
        deleteInProgress: false,
        toBeDeleted: null,
      });
      onDeleteModalClose();
      setMessage({
        message: 'Deleted template',
        type: 'success',
      });
      if (id === currentTemplate.id) {
        history.push('/templates');
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Custom styles
  const customStyles = {
    container: baseStyles => ({
      ...baseStyles,
    }),
    control: (baseStyles, { isFocused }) => ({
      ...baseStyles,
      borderRadius: '2px',
      border: '0px solid transparent',
      background: 'transparent',
      cursor: 'pointer',
      boxShadow: isFocused ? '0 0 0 1px rgba(255, 255, 255, 0.5)' : 'none',
      '&:hover': {
        background: isFocused ? 'transparent' : 'rgba(255,255,255,0.15)',
      },
    }),
    option: baseStyles => ({
      ...baseStyles,
    }),
    menu: baseStyles => ({
      ...baseStyles,
    }),
    input: baseStyles => ({
      ...baseStyles,
      fontWeight: '500',
      color: 'transparent',
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      color: 'white',
      fontWeight: '500',
    }),
    indicatorContainer: baseStyles => ({
      ...baseStyles,
      display: 'none',
    }),
    indicatorSeparator: baseStyles => ({
      ...baseStyles,
      display: 'none',
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      display: 'none',
    }),
  };

  const onDeleteTemplate = template => {
    setSelectedOption({ ...selectedOption, toBeDeleted: template });
    onDeleteModalOpen();
  };

  const NewTemplateOption = ({ innerProps, isFocused, data }) => {
    return (
      <div
        className={`${isFocused ? 'focused' : ''} ${
          !currentTemplate ? 'selected' : ''
        } template__list-item list-item__new-template`}
        key={data.value}
        {...innerProps}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onChangeTemplate();
        }}
      >
        <Icons.Add size={18} />
        <div>New Template</div>
      </div>
    );
  };

  const ExistingAdTemplateOption = props => {
    const { innerProps, isFocused, isSelected, data, children } = props;
    const previewImg = data.image;
    const templateInUse = data.campaigns_count > 0;

    if (data?.value === -1)
      return NewTemplateOption({ innerProps, isFocused, data });
    else
      return (
        <div className="template__list-item" key={data.value} {...innerProps}>
          <div
            className={`${isFocused ? 'focused' : ''} ${
              isSelected ? 'selected' : ''
            } list-item__wrapper`}
          >
            <div className="list-item__duplicate">
              <Tooltip label="Duplicate template">
                <IconButton
                  color="#b1b1b1"
                  variant="ghost"
                  size="sm"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    duplicateTemplateHandler(
                      templates.find(template => template.id === data.value)
                    );
                  }}
                  icon={<Icons.Duplicate size={20} />}
                />
              </Tooltip>
            </div>
            <div className="list-item__text">
              <span>{children}</span>
            </div>
            <div className="list-item__img">
              {previewImg !== null &&
                previewImg.map((imageUrl, index) => {
                  return (
                    imageUrl && (
                      <div key={index} className="list-item__img-wrapper">
                        <Box className="preview-img" bgImage={imageUrl} />
                      </div>
                    )
                  );
                })}
            </div>
            <div className="list-item__delete">
              {templateInUse ? (
                <Tooltip label="Detach template from all campaigns to delete.">
                  <IconButton
                    cursor="not-allowed"
                    color="#b1b1b1"
                    variant="ghost"
                    size="sm"
                    icon={<Icons.Delete size={20} />}
                  />
                </Tooltip>
              ) : (
                <Tooltip label="Delete">
                  <IconButton
                    disabled={templateInUse}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onDeleteTemplate(
                        templates.find(template => template.id === data.value)
                      );
                    }}
                    variant="ghost"
                    size="sm"
                    icon={<Icons.Delete size={20} />}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      );
  };

  const loadOptions = search => {
    let filteredOptions = [];

    if (!search) {
      filteredOptions = templateOptions;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = templateOptions.filter(
        ({ label, value }) =>
          label.toLowerCase().includes(searchLower) && value !== -1
      );
    }

    return {
      options: filteredOptions,
    };
  };

  // Custom Menu component with a resizable wrapper
  const ResizableMenu = props => (
    <div
      className="template_menu-resize-wrapper"
      style={{
        ...props.getStyles('menu', props),
      }}
    >
      <div {...props.innerProps}>{props.children}</div>
    </div>
  );

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onOpen={onDeleteModalOpen}
        onClose={onDeleteModalClose}
        toBeDeleted={selectedOption.toBeDeleted}
        deleteTemplateHandler={deleteTemplateHandler}
        deleteInProgress={selectedOption.deleteInProgress}
      />
      <ConfirmChangesModal
        isOpen={isConfirmChangesModalOpen}
        onClose={onConfirmChangesModalClose}
        onSave={onChangesSaved}
        onCancel={closeConfirmModal}
      />
      {selectedOption.renaming ? (
        <>
          <Input
            className="template-name-edit__input"
            value={selectedOption.label}
            onChange={e =>
              setSelectedOption({ ...selectedOption, label: e.target.value })
            }
          />
          <Tooltip label="Apply">
            <IconButton
              className="template-name-edit__icon-btn"
              onClick={updateTemplateNameEditHandler}
              variant="ghost"
              icon={<Icons.Check size={20} />}
            />
          </Tooltip>
          <Tooltip label="Cancel">
            <IconButton
              className="template-name-edit__icon-btn"
              onClick={() => {
                setSelectedOption({ ...selectedOption, renaming: false });
                setTemplateNameEdit(false);
              }}
              variant="ghost"
              icon={<Icons.Cross size={20} />}
            />
          </Tooltip>
        </>
      ) : (
        <AsyncPaginate
          key={
            currentTemplate && currentTemplate?.id
              ? currentTemplate.id + templates.length
              : templates.length
          }
          className="template-dropdown"
          inputId="template-input"
          //   isClearable
          cacheOptions
          loadOptions={loadOptions}
          components={{
            Option: ExistingAdTemplateOption,
            Menu: ResizableMenu,
          }}
          // menuIsOpen={true} // force menu open for debug purpose
          value={selectedOption}
          onChange={onChangeTemplate}
          isDisabled={isDisabled}
          styles={customStyles}
        />
      )}
      {selectedOption && currentTemplate && !selectedOption.renaming && (
        <Tooltip label="Rename">
          <IconButton
            className="template-name-edit__icon-btn"
            onClick={() => {
              setSelectedOption({ ...selectedOption, renaming: true });
              setTemplateNameEdit(true);
            }}
            variant="ghost"
            icon={<Icons.Edit size={16} />}
          />
        </Tooltip>
      )}
    </>
  );
};

export default TemplateDropdown;
