import React, { useRef, useState, useEffect } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Tooltip,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { templateEditorSelectors } from 'modules/templateEditor/templateEditorDuck';
import { useEditor } from 'components/Canvas';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import Icons from '../../Icons';
// import UploadFont from '../../../../../components/UploadUserFont/UploadFont';
import UploadFontModal from './components/UploadFontModal';
import colors from 'theme/Colors.module.scss';

import './FontFamily.scss';

const fontFaceSet = document.fonts;

function FontFamily(props) {
  const [publicFonts, setPublicFonts] = useState([]);
  const [privateFonts, setPrivateFonts] = useState([]);
  const { activeObject, setIsFontLoading, activeSubMenu, setActiveSubMenu } =
    useTemplateEditorContext();

  const editor = useEditor();
  const [query, setQuery] = useState('');

  const [activeFontFamily, setActiveFontFamily] = useState(
    activeObject?.fontFamily
  );
  const [isUploadFontModalOpen, setIsUploadFontModalOpen] = useState(false);
  // const [isFontOptionsOpen, setIsFontOptionsOpen] = useState(false);
  const [file, setFile] = useState(false);
  const fileInput = useRef(null);

  const privateFontRef = useRef([]);
  const publicFontRef = useRef([]);

  const [activePrivateFontIndex, setActivePrivateFontIndex] = useState(null);
  const [activePublicFontIndex, setActivePublicFontIndex] = useState(null);

  const [privateFontRefLoaded, setPrivateFontRefLoaded] = useState(false);
  const [publicFontRefLoaded, setPublicFontRefLoaded] = useState(false);

  const [privateHandwritingFontRefLoaded, setPrivateHandwritingFontRefLoaded] =
    useState(false);
  const [publicHandwritingFontRefLoaded, setPublicHandwritingFontRefLoaded] =
    useState(false);

  const [privateAccordionIndex, setPrivateAccordionIndex] = useState();
  const [publicAccordionIndex, setPublicAccordionIndex] = useState();

  // Selectors
  const userFonts = useSelector(state =>
    templateEditorSelectors.userFonts(state)
  );

  const getLastChar = s => +s.match(/\d+(?=\D*$)/);

  const handWritingGroup = fonts =>
    fonts
      .filter(i => isFiltered(i.name))
      .filter(i => i.font_type === 'handwriting');

  const sortedFonts = fonts =>
    fonts
      .filter(i => isFiltered(i.name))
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => getLastChar(a.name) - getLastChar(b.name));

  const isFontLoaded = fontFace => {
    let fontExist = false;

    fontFaceSet.forEach(fontInSet => {
      if (fontInSet.family === fontFace.family) {
        fontExist = true;
      }
    });

    return fontExist;
  };

  const addFontToRefs = (el, index, category) => {
    if (category === 'private') {
      if (el && !privateFontRef.current.includes(el)) {
        privateFontRef.current[index] = el;
      }
    } else {
      if (el && !publicFontRef.current.includes(el)) {
        publicFontRef.current[index] = el;
      }
    }
  };

  const handleFontFamilyChange = async fontItem => {
    if (editor) {
      sortFonts();
      const font = {
        name: fontItem.name,
        url: fontItem.url,
      };

      const fontFace = new FontFace(font.name, `url(${font.url})`);

      // let __fontFilename;
      // if (font.url.match(/s3.amazonaws.com/g)) {
      const __fontFilename = font.url
        .substring(font.url.lastIndexOf('/') + 1)
        .split('?')[0];
      // } else {
      //   __fontFilename = font.url.substring(font.url.lastIndexOf('/') + 1);
      // }

      await fontFaceSet.ready.then(() => {
        if (isFontLoaded(fontFace)) {
          editor.update({
            fontFamily: font.name,
            metadata: {
              __fontFilename,
            },
          });
          setActiveFontFamily(font.name);
        } else {
          setIsFontLoading(true);

          if (fontFaceSet) {
            fontFaceSet.add(fontFace);
            fontFace.load();
            fontFace.loaded
              .then(fontFace => {
                editor.update({
                  fontFamily: font.name,
                  metadata: {
                    __fontFilename,
                  },
                });
                setActiveFontFamily(font.name);
              })
              .catch(err => console.log(err))
              .finally(() => {
                setIsFontLoading(false);
              });
          }
        }
      });
    }
  };

  const isFiltered = element => {
    const ele = element.toLowerCase();
    const res = query.toLowerCase();
    return ele.includes(res) || ele === res;
  };

  const closeUploadFontModal = () => {
    setIsUploadFontModalOpen(false);
    setFile(null);
    if (fileInput?.current) {
      fileInput.current.value = null;
    }
  };

  // const showFontOptions = () => {
  //   setIsFontOptionsOpen(!isFontOptionsOpen);
  // };

  const sortFonts = () => {
    if (userFonts) {
      if (userFonts.public) {
        const publicFonts = userFonts.public;
        const updatedPublicFonts = Object.keys(publicFonts).map(key => {
          const needle = 400;
          const regularStyles = publicFonts[key].filter(
            pf => !pf.fullname.includes('Italic')
          );
          const regularStylesWeights = regularStyles.map(f => f.weight);
          const regularStylesSorted = regularStylesWeights.sort((a, b) => {
            return Math.abs(needle - a) - Math.abs(needle - b);
          });

          const italicStyles = publicFonts[key].filter(pf =>
            pf.fullname.includes('Italic')
          );
          const italicStylesWeights = italicStyles.map(f => f.weight);
          const italicStylesSorted = italicStylesWeights.sort((a, b) => {
            return Math.abs(needle - a) - Math.abs(needle - b);
          });
          const defaultFont = regularStylesSorted[0]
            ? regularStyles.find(f => f.weight === regularStylesSorted[0])
            : italicStyles.find(f => f.weight === italicStylesSorted[0]);
          return defaultFont;
        });
        setPublicFonts(updatedPublicFonts);
      }
      if (userFonts.private) {
        const privateFonts = userFonts.private;
        setPrivateFonts(privateFonts);
      }
    }
  };

  const handleUserKeyPress = event => {
    const key = event.key;
    let currentFont;
    let isPublic;
    let isPrivate;
    let updatedPublicFonts;

    //validate if the current font is on public
    if (userFonts.public) {
      updatedPublicFonts = Object.keys(userFonts.public)
        .map(key => {
          const needle = 400;
          const regularStyles = userFonts.public[key].filter(
            pf => !pf.fullname.includes('Italic')
          );
          const regularStylesWeights = regularStyles.map(f => f.weight);
          const regularStylesSorted = regularStylesWeights.sort((a, b) => {
            return Math.abs(needle - a) - Math.abs(needle - b);
          });

          const italicStyles = userFonts.public[key].filter(pf =>
            pf.fullname.includes('Italic')
          );
          const italicStylesWeights = italicStyles.map(f => f.weight);
          const italicStylesSorted = italicStylesWeights.sort((a, b) => {
            return Math.abs(needle - a) - Math.abs(needle - b);
          });
          const defaultFont = regularStylesSorted[0]
            ? regularStyles.find(f => f.weight === regularStylesSorted[0])
            : italicStyles.find(f => f.weight === italicStylesSorted[0]);
          return defaultFont;
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      isPublic = updatedPublicFonts.find(font => {
        return font.fullname === activeObject.fontFamily;
      });
    }

    //validate if the current font is on private
    if (userFonts.private) {
      isPrivate = userFonts.private.find(font => {
        return font.fullname === activeObject.fontFamily;
      });
    }

    // if the current font is on private or is on both
    if ((isPrivate && !isPublic) || (isPrivate && isPublic)) {
      userFonts.private
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((font, index) => {
          if (font.fullname === activeObject.fontFamily) {
            currentFont = {
              index: index,
              font: font,
            };
          }
          return font;
        });

      if (userFonts.private[currentFont.index + 1] && key === 'ArrowDown') {
        handleFontFamilyChange(userFonts.private[currentFont.index + 1]);
        setActiveFontFamily(userFonts.private[currentFont.index + 1].fullname);
      }
      if (userFonts.private[currentFont.index - 1] && key === 'ArrowUp') {
        handleFontFamilyChange(userFonts.private[currentFont.index - 1]);
        setActiveFontFamily(userFonts.private[currentFont.index - 1].fullname);
      }
    }
    // if the current font is on public
    if (isPrivate === undefined && isPublic) {
      updatedPublicFonts.map((font, index) => {
        if (font.fullname === activeObject.fontFamily) {
          currentFont = {
            index: index,
            font: font,
          };
        }
        return font;
      });

      if (updatedPublicFonts[currentFont.index + 1] && key === 'ArrowDown') {
        handleFontFamilyChange(updatedPublicFonts[currentFont.index + 1]);
        setActiveFontFamily(updatedPublicFonts[currentFont.index + 1].fullname);
      }
      if (updatedPublicFonts[currentFont.index - 1] && key === 'ArrowUp') {
        handleFontFamilyChange(updatedPublicFonts[currentFont.index - 1]);
        setActiveFontFamily(updatedPublicFonts[currentFont.index - 1].fullname);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortFonts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      privateFontRefLoaded === true &&
      publicFontRefLoaded === true &&
      privateHandwritingFontRefLoaded === true &&
      publicHandwritingFontRefLoaded === true
    ) {
      if (
        ((activePrivateFontIndex !== null && activePublicFontIndex !== null) ||
          (activePrivateFontIndex !== null &&
            activePublicFontIndex === null)) &&
        privateFontRef.current.length > 0
      ) {
        let privateEl = privateFontRef.current[activePrivateFontIndex];
        if (privateAccordionIndex === 0) {
          setTimeout(() => {
            privateEl = privateFontRef.current[activePrivateFontIndex];
            privateEl.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }, '100');
        } else {
          privateEl.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      }

      if (
        activePrivateFontIndex === null &&
        activePublicFontIndex !== null &&
        publicFontRef.current.length > 0
      ) {
        let publicEl = publicFontRef.current[activePublicFontIndex];
        if (publicAccordionIndex === 0) {
          setTimeout(() => {
            publicEl = publicFontRef.current[activePublicFontIndex];
            publicEl.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }, '100');
        } else {
          publicEl.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    publicFontRefLoaded,
    privateFontRefLoaded,
    privateHandwritingFontRefLoaded,
    publicHandwritingFontRefLoaded,
  ]);

  const privateFontList = () => {
    const privateHandWritingGroup = handWritingGroup(privateFonts);
    const privateSortedFonts = sortedFonts(privateFonts);

    if (privateHandWritingGroup.length === 0) {
      if (privateHandwritingFontRefLoaded === false) {
        setPrivateHandwritingFontRefLoaded(true);
      }
    }
    return (
      <>
        {privateHandWritingGroup.length !== 0 && (
          <ListItem className="list-item__handwriting-group">
            <Accordion
              className="handwriting-group__accordion"
              allowMultiple
              allowToggle
              index={privateAccordionIndex}
              onChange={setPrivateAccordionIndex}
            >
              <AccordionItem className="handwriting-group__accordion-item">
                <AccordionButton className="handwriting-group__accordion-btn">
                  <span className="handwriting-group__title">Handwriting</span>
                  <AccordionIcon />
                </AccordionButton>

                {privateSortedFonts.map((privateFont, index) => {
                  if (privateFont.fullname === activeFontFamily) {
                    if (
                      activePrivateFontIndex === null &&
                      privateFont.font_type === 'handwriting'
                    ) {
                      setActivePrivateFontIndex(index);
                      setPrivateAccordionIndex(0);
                    }
                  }
                  if (index === privateFonts.length - 1) {
                    if (privateHandwritingFontRefLoaded === false) {
                      setPrivateHandwritingFontRefLoaded(true);
                    }
                  }
                  return (
                    privateFont.font_type === 'handwriting' && (
                      <Tooltip
                        key={privateFont.id}
                        label={privateFont.fullname}
                        placement="right"
                        closeOnScroll={true} // only work on chakra ui v2.8.2
                      >
                        <AccordionPanel
                          className="handwriting-group__accordion-panel"
                          ref={el => addFontToRefs(el, index, 'private')}
                          style={{
                            background:
                              privateFont.fullname === activeFontFamily
                                ? colors.neutral200
                                : '',
                          }}
                          onClick={() => handleFontFamilyChange(privateFont)}
                        >
                          <Image
                            className="handwriting-group__font-img"
                            src={privateFont.thumb}
                          />
                        </AccordionPanel>
                      </Tooltip>
                    )
                  );
                })}
              </AccordionItem>
            </Accordion>
          </ListItem>
        )}

        {privateSortedFonts.map((privateFont, index) => {
          if (privateFont.fullname === activeFontFamily) {
            if (
              activePrivateFontIndex === null &&
              privateFont.font_type !== 'handwriting'
            ) {
              setActivePrivateFontIndex(index);
            }
          }
          if (index === privateFonts.length - 1) {
            if (privateFontRefLoaded === false) {
              setPrivateFontRefLoaded(true);
            }
          }
          return (
            privateFont.font_type !== 'handwriting' && (
              <Tooltip
                label={privateFont.fullname}
                key={privateFont.id}
                placement="right"
                closeOnScroll={true} // only work on chakra ui v2.8.2
              >
                <ListItem
                  className="list-item__font"
                  ref={el => addFontToRefs(el, index, 'private')}
                  style={{
                    background:
                      privateFont.fullname === activeFontFamily
                        ? colors.neutral200
                        : '',
                  }}
                  onClick={() => handleFontFamilyChange(privateFont)}
                >
                  <Image
                    className="list-item__font-img"
                    src={privateFont.thumb}
                  />
                </ListItem>
              </Tooltip>
            )
          );
        })}
      </>
    );
  };

  const publicFontList = () => {
    const publicHandWritingGroup = handWritingGroup(publicFonts);
    const publicSortedFonts = sortedFonts(publicFonts);

    if (publicHandWritingGroup.length === 0) {
      if (publicHandwritingFontRefLoaded === false) {
        setPublicHandwritingFontRefLoaded(true);
      }
    }
    return (
      <>
        {publicHandWritingGroup.length !== 0 && (
          <ListItem className="list-item__handwriting-group">
            <Accordion
              className="handwriting-group__accordion"
              allowMultiple
              allowToggle
              index={publicAccordionIndex}
              onChange={setPublicAccordionIndex}
            >
              <AccordionItem className="handwriting-group__accordion-item">
                <AccordionButton className="handwriting-group__accordion-btn">
                  <span className="handwriting-group__title">Handwriting</span>
                  <AccordionIcon />
                </AccordionButton>

                {publicSortedFonts.map((publicFont, index) => {
                  if (publicFont.fullname === activeFontFamily) {
                    if (
                      activePublicFontIndex === null &&
                      publicFont.font_type === 'handwriting'
                    ) {
                      setActivePublicFontIndex(index);
                      setPublicAccordionIndex(0);
                    }
                  }
                  if (index === publicFonts.length - 1) {
                    if (publicHandwritingFontRefLoaded === false) {
                      setPublicHandwritingFontRefLoaded(true);
                    }
                  }
                  return (
                    publicFont.font_type === 'handwriting' && (
                      <Tooltip
                        label={publicFont.fullname}
                        placement="right"
                        key={publicFont.id}
                        closeOnScroll={true} // only work on chakra ui v2.8.2
                      >
                        <AccordionPanel
                          className="handwriting-group__accordion-panel"
                          ref={el => addFontToRefs(el, index, 'public')}
                          style={{
                            background:
                              publicFont.fullname === activeFontFamily
                                ? colors.neutral200
                                : '',
                          }}
                          onClick={() => handleFontFamilyChange(publicFont)}
                        >
                          <Image
                            className="handwriting-group__font-img"
                            src={publicFont.thumb}
                          />
                        </AccordionPanel>
                      </Tooltip>
                    )
                  );
                })}
              </AccordionItem>
            </Accordion>
          </ListItem>
        )}
        {publicSortedFonts.map((publicFont, index) => {
          if (publicFont.fullname === activeFontFamily) {
            if (
              activePublicFontIndex === null &&
              publicFont.font_type !== 'handwriting'
            ) {
              setActivePublicFontIndex(index);
            }
          }
          if (index === publicFonts.length - 1) {
            if (publicFontRefLoaded === false) {
              setPublicFontRefLoaded(true);
            }
          }
          return (
            publicFont.font_type !== 'handwriting' && (
              <Tooltip
                label={publicFont.fullname}
                placement="right"
                key={publicFont.id}
                closeOnScroll={true} // only work on chakra ui v2.8.2
              >
                <ListItem
                  className="list-item__font"
                  ref={el => addFontToRefs(el, index, 'public')}
                  style={{
                    background:
                      publicFont.fullname === activeFontFamily
                        ? colors.neutral200
                        : '',
                  }}
                  onClick={() => handleFontFamilyChange(publicFont)}
                >
                  <Image
                    className="list-item__font-img"
                    src={publicFont.thumb}
                  />
                </ListItem>
              </Tooltip>
            )
          );
        })}
      </>
    );
  };
  return (
    <div className="font-family">
      <div className="font-family__input-container">
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icons.Search size={24} color="gray.300" />}
          />
          <Input
            className="font-family__input"
            value={query}
            onChange={e => setQuery(e.target.value)}
            placeholder="Search font"
            _placeholder={{
              color: colors.neutral400,
            }}
          />
        </InputGroup>
      </div>
      <List className="font-family__list">
        <ListItem
          className="list-item__private-header"
          style={{
            borderBottom:
              privateFonts.length > 0
                ? `1px solid ${colors.neutral300}`
                : `0px solid ${colors.neutral300}`,
          }}
        >
          <span className="list-item__header-title">Private fonts</span>
          <Button
            className="list-item__upload-btn"
            leftIcon={<Icons.UploadCloud height={15} />}
            onClick={() => fileInput?.current?.click()}
          >
            Upload a font
          </Button>
          <input
            hidden
            type="file"
            ref={fileInput}
            accept=".ttf, .otf"
            multiple={Boolean(false)}
            onChange={ev => {
              setFile(ev.target.files[0]);
              setIsUploadFontModalOpen(true);
            }}
          />
        </ListItem>
        {privateFontList()}
        <ListItem className="list-item__public-header">
          <span className="list-item__header-title">Public fonts</span>
        </ListItem>
        {publicFontList()}
      </List>

      {/* {isUploadFontModalOpen && (
        <UploadFont
          activeSubMenu={activeSubMenu}
          setActiveSubMenu={setActiveSubMenu}
          handleFontFamilyChange={handleFontFamilyChange}
          closeUploadFontModal={closeUploadFontModal}
        />
      )} */}

      <UploadFontModal
        isOpen={isUploadFontModalOpen}
        onClose={closeUploadFontModal}
        handleFontFamilyChange={handleFontFamilyChange}
        file={file}
        setActiveSubMenu={setActiveSubMenu}
        activeSubMenu={activeSubMenu}
      />
    </div>
  );
}

export default FontFamily;
