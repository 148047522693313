import { Box, Input, Button, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState, Fragment } from 'react';
import Icons from '../../Icons';

function TextMenu({ keyObj, updateSingleKey, closeMenu }) {
  const [key, setKey] = useState('');
  const [defaultVal, setDefaultVal] = useState('');

  useEffect(() => {
    setKey(
      keyObj?.key ? keyObj.key.key.substring(2, keyObj.key.key.length - 2) : ''
    );
    setDefaultVal(keyObj?.key?.value || '');
  }, [keyObj]);

  const handleKeyChange = e => setKey(e.target.value);

  const handleDefaultChange = e => setDefaultVal(e.target.value);

  const handleApplyClick = e => {
    updateSingleKey('{{' + key + '}}', defaultVal);
    closeMenu();
  };

  return (
    <Fragment>
      <Box
        sx={{
          fontWeight: 700,
          fontSize: '14px',
          paddingBottom: '1rem',
        }}
      >
        Edit Personalization Token
      </Box>
      <Box>
        <Box>
          <Box
            sx={{
              fontSize: '14px',
              paddingBottom: '0.75rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>Variable name:</Box>
            <Tooltip label="The name of the variable that is passed to UnDigital in the template params of your order post.">
              <Box color="#666666" cursor="pointer">
                <Icons.Info size={22} />
              </Box>
            </Tooltip>
          </Box>
          <Input
            fontSize="14px"
            borderRadius={0}
            // disabled={keysMenu.object.type === 'DynamicText' ? true : false}
            style={{ width: '270px' }}
            value={key}
            name="variableName"
            onChange={handleKeyChange}
            _disabled={{
              color: '#666666 !important',
            }}
          />
        </Box>
        <Box>
          <Box
            sx={{
              fontSize: '14px',
              paddingBottom: '0.75rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            <Box>Display value:</Box>
            <Tooltip label='The value to use in place of the variable name while editing your ad template enabling you to more easily envision the final generated image.  For example, if the variable is for "firstname", you can place a default value of "John".'>
              <Box color="#666666" cursor="pointer">
                <Icons.Info size={22} />
              </Box>
            </Tooltip>
          </Box>
          <Input
            fontSize="14px"
            borderRadius={0}
            name="variableValue"
            value={defaultVal}
            onChange={handleDefaultChange}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: '0.75rem', paddingTop: '0.75rem' }}>
          <Button
            colorScheme="orange"
            backgroundColor="rgb(255, 111, 15)"
            borderRadius={0}
            name="variablyApply"
            size="sm"
            flex={1}
            onClick={handleApplyClick}
          >
            Apply
          </Button>
          <Button borderRadius={0} size="sm" flex={1} onClick={closeMenu}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
}
export default TextMenu;
