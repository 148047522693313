import Icons from '../../../Icons';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { useEditor } from 'components/Canvas/hooks';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';

function Duplicate() {
  const editor = useEditor();
  const { activeObject, setActiveObject } = useTemplateEditorContext();

  return (
    <Tooltip label="Duplicate">
      <IconButton
        onClick={() => {
          editor.clone();
          setActiveObject(activeObject);
        }}
        color="#000000"
        borderRadius="0"
        variant="ghost"
        size="sm"
        icon={<Icons.Duplicate size={24} />}
      />
    </Tooltip>
  );
}

export default Duplicate;
