import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppContainer from './scenes/app/appContainer';
import { ViewportSizeProvider } from 'modules/layout/layoutContexts';
import * as serviceWorker from './serviceWorker';
import theme from './theme/theme';
import './index.scss';
import 'focus-visible/dist/focus-visible';
// import mirage from './mirage';

// Redux
import { Provider } from 'react-redux';
import configureStore from './store';
import { ChakraProvider } from '@chakra-ui/provider';
const reduxDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = configureStore(reduxDevTools);

const container = document.getElementById('app');

// Main App
const root = createRoot(container);

// Main app
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ViewportSizeProvider>
        <ChakraProvider theme={theme}>
          <AppContainer />
        </ChakraProvider>
      </ViewportSizeProvider>
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
