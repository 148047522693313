import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';

import './AdTemplateDropdown.scss';

import { creativesSelectors } from 'modules/creatives/creativesDuck';

const AdTemplateDropdown = props => {
  const { initialForm, onChange, isDisabled } = props;

  const [selectedOption, setSelectedOption] = useState({});

  // Selectors
  const allCreatives = useSelector(state =>
    creativesSelectors.creatives(state)
  );
  const isCreativesLoading = useSelector(state =>
    creativesSelectors.isCreativesLoading(state)
  );

  const everyTemplate = [
    {
      id: '',
      name: 'None',
      is_portrait: true,
    },
    ...allCreatives,
  ];

  everyTemplate?.map(o => ({ key: o.id, value: o.id, label: o.name }));

  let adTemplateOptions = everyTemplate
    .map(o => ({
      key: o.id,
      value: o.id,
      label: o.name,
      image:
        o.double_sided === false && o.bg_img
          ? [o.bg_img.thumb_url]
          : o.double_sided === true && o.ad_template_pages.length > 1
          ? o.ad_template_pages
              .sort((a, b) => a.page_num - b.page_num)
              .map(page => (page.bg_img ? page.bg_img.thumb_url : null))
          : null,
    }))
    .sort((a, b) => {
      const firstOption = b.label === everyTemplate[0].name;

      if (!firstOption) {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
      }

      return 0;
    });

  useEffect(() => {
    if (!isCreativesLoading) {
      const existingSelectedOption = adTemplateOptions.find(
        option => +option.value === +initialForm.adTemplate
      );
      setSelectedOption(existingSelectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreativesLoading, initialForm.adTemplate]);

  const onOptionChange = option => {
    setSelectedOption(option);
    onChange(option);
  };

  const ExistingAdTemplateOption = ({
    innerProps,
    isFocused,
    isSelected,
    data,
    children,
  }) => {
    const previewImg = data.image;

    return (
      <div className="adTemplate__list-item" key={data.value} {...innerProps}>
        <div
          className={`${isFocused ? 'focused' : ''} ${
            isSelected ? 'selected' : ''
          } list-item__wrapper`}
        >
          <div className="list-item__text">
            <span>{children}</span>
          </div>
          <div className="list-item__img">
            {previewImg !== null &&
              previewImg.map((imageUrl, index) => {
                return (
                  imageUrl && (
                    <div key={index} className="list-item__img-wrapper">
                      <Box className="preview-img" bgImage={imageUrl} />
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  const loadOptions = search => {
    let filteredOptions = [];

    if (!search) {
      filteredOptions = adTemplateOptions;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = adTemplateOptions.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    return {
      options: filteredOptions,
    };
  };

  return (
    <AsyncPaginate
      className="adTemplateDropdown"
      inputId="adTemplateInput"
      isClearable
      cacheOptions
      loadOptions={loadOptions}
      components={{ Option: ExistingAdTemplateOption }}
      //   menuIsOpen={true} // force menu open for debug purpose
      value={selectedOption}
      onChange={onOptionChange}
      isDisabled={isDisabled}
    />
  );
};

export default AdTemplateDropdown;
