import React, { Fragment } from 'react';
const greaterOrEq = ['Greater/Equal to', 'ge', '>='];
const lessOrEq = ['Less/Equal to', 'le', '<='];
const greater = ['Greater than', 'gt', '>'];
const less = ['Less than', 'lt', '<'];
const equals = ['Equal to', 'eq', '='];
const notEquals = ['Not equal to', 'ne', '!='];
const on = ['On', 'eq', '='];
const onBefore = ['On or before', 'le', '<='];
const before = ['Before', 'lt', '<'];
const onAfter = ['On or after', 'ge', '>='];
const after = ['After', 'gt', '>'];
const notOn = ['Not on', 'ne', '!='];
const isEquals = ['Is', 'eq', '='];
const notIsEquals = ['Is not', 'ne', '!='];
const contains = ['Contains', 'con'];
const starts = ['Starts with', 'starts'];
const intOpValues = [greater, less, greaterOrEq, lessOrEq, equals, notEquals];
const decOpValues = [greater, less, greaterOrEq, lessOrEq, equals, notEquals];
const dtOpValues = [on, before, after, onBefore, onAfter, notOn];
const strOpValues = [isEquals, starts, contains, notIsEquals];
const listOpValues = [isEquals, notIsEquals];
const customOpValues = [
  ...strOpValues,
  ...intOpValues,
  ...dtOpValues,
  ...listOpValues,
];

const opTypeValues = {
  dt: dtOpValues,
  str: strOpValues,
  int: intOpValues,
  dec: decOpValues,
  // eslint-disable-next-line no-useless-computed-key
  ['[str]']: listOpValues,
  custom: customOpValues,
};

const includeTitle = 'Include';
const excludeTitle = 'Exclude';

const AudSummary = props => {
  const {
    data: { includes, excludes },
    listBackgroundColor,
  } = props;

  const renderSetSummary = set => {
    return set.rules.map((rule, index) => {
      const { key, kv_relational_op, value, value_type } = rule;
      const op = opTypeValues[value_type].find(arr =>
        arr.includes(kv_relational_op)
      );

      return (
        <div
          className="audience__rules"
          style={{
            display: 'grid',
            gridTemplateColumns: '34% 32% 34%',
            backgroundColor: listBackgroundColor
              ? listBackgroundColor
              : 'rgba(0,0,0,0.1)',
            padding: '0.5rem',
            marginBottom: '0.1rem',
          }}
          key={index}
        >
          <div className="rules__key" style={{ textAlign: 'left' }}>
            {key}
          </div>
          <div className="rules__relation" style={{ textAlign: 'center' }}>
            {(op && op[0]) || kv_relational_op}
          </div>
          <div className="rules__value" style={{ textAlign: 'right' }}>
            {value}
          </div>
        </div>
      );
    });
  };

  const renderSet = (data, title) => {
    return (
      <>
        {data.map((set, index) => {
          return (
            <Fragment key={index}>
              <p
                className="audience__rule-types"
                style={{ textAlign: 'center', padding: '1rem' }}
              >
                {index === 0 && title === excludeTitle && (
                  <strong>ALSO...</strong>
                )}
                {index !== 0 && <strong>AND...</strong>}
                <strong>{title}</strong>&nbsp; at least ONE of the following
              </p>

              {renderSetSummary(set)}
            </Fragment>
          );
        })}
      </>
    );
  };

  return (
    <>
      {includes && renderSet(includes, includeTitle)}
      {excludes && renderSet(excludes, excludeTitle)}
    </>
  );
};

export default AudSummary;
