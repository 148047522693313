import React, { useEffect, useState } from 'react';
import Icons from '../../../Icons';
import {
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tooltip,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { useEditor } from 'components/Canvas';
import { useDebounce } from 'scenes/templateEditor/hooks/useDebounce';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';

function Spacing() {
  const [options, setOptions] = useState({ charSpacing: 0, lineHeight: 0 });
  const [value, setValue] = useState({ charSpacing: '0', lineHeight: '0' });
  const editor = useEditor();
  const { activeObject } = useTemplateEditorContext();
  const debouncedValue = useDebounce(value, 1000);

  useEffect(() => {
    if (activeObject) {
      updateOptions(activeObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  const updateOptions = object => {
    const { charSpacing = 0, lineHeight = 0 } = object;
    setValue({
      ...value,
      charSpacing: charSpacing.toString(),
      lineHeight: lineHeight.toString(),
    });
    setOptions({
      ...options,
      charSpacing: charSpacing,
      lineHeight: lineHeight,
    });
  };

  const handleChange = (type, val) => {
    if (editor && !isNaN(val)) {
      setValue({ ...value, [type]: val.toString() });
      setOptions({ ...options, [type]: val });
      editor.update({ [type]: val });
    }
  };

  useEffect(() => {
    if (editor) {
      if (
        !isNaN(parseInt(debouncedValue.charSpacing)) &&
        parseInt(debouncedValue.charSpacing) !== parseInt(options.charSpacing)
      ) {
        const updatedCharSpacing = parseInt(debouncedValue.charSpacing);
        setValue({ ...value, charSpacing: updatedCharSpacing.toString() });
        setOptions({ ...options, charSpacing: updatedCharSpacing });
        editor.update({ charSpacing: updatedCharSpacing });
      }
      if (
        !isNaN(parseFloat(debouncedValue.lineHeight)) &&
        parseFloat(debouncedValue.lineHeight).toFixed(2) !==
          parseFloat(options.lineHeight).toFixed(2)
      ) {
        const updatedLineHeight = parseFloat(
          parseFloat(debouncedValue.lineHeight).toFixed(2)
        );
        setValue({ ...value, lineHeight: updatedLineHeight.toString() });
        setOptions({ ...options, lineHeight: updatedLineHeight });
        editor.update({ lineHeight: updatedLineHeight });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Popover placement="bottom">
      <Tooltip placement="bottom" label="Spacing">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              color="#000000"
              borderRadius="0"
              variant="ghost"
              size="sm"
              icon={<Icons.Spacing size={24} />}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>

      <PopoverContent
        sx={{
          padding: '1.5rem',
          display: 'flex',
          gap: '0.5rem',
          width: '400px',
        }}
      >
        <Box
          name="letterSpacing"
          sx={{
            display: 'grid',
            gridTemplateColumns: '100px 1fr 50px',
            alignItems: 'center',
            fontSize: '14px',
            gap: '0.5rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Letter spacing
          </Box>
          <Slider
            onChange={value => handleChange('charSpacing', value)}
            min={-100}
            max={100}
            colorScheme="gray"
            size="sm"
            value={options.charSpacing}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb
              boxSize={4}
              borderWidth="2px"
              borderColor="white"
              backgroundColor="black"
            />
          </Slider>
          <NumberInput
            value={value.charSpacing}
            onChange={val => setValue({ ...value, charSpacing: val })}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <NumberInputField
              height="1rem"
              borderRadius={0}
              paddingX={1}
              fontSize="12px"
              textAlign="center"
            />
          </NumberInput>
        </Box>
        <Box
          name="lineSpace"
          sx={{
            display: 'grid',
            gridTemplateColumns: '100px 1fr 50px',
            alignItems: 'center',
            fontSize: '14px',
            gap: '0.5rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Line height
          </Box>
          <Slider
            onChange={value => handleChange('lineHeight', value)}
            min={0}
            max={40}
            step={0.01}
            colorScheme="gray"
            size="sm"
            value={options.lineHeight}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb
              boxSize={4}
              borderWidth="2px"
              borderColor="white"
              backgroundColor="black"
            />
          </Slider>
          <NumberInput
            value={value.lineHeight}
            onChange={val => setValue({ ...value, lineHeight: val })}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <NumberInputField
              height="1rem"
              borderRadius={0}
              paddingX={1}
              fontSize="12px"
              textAlign="center"
            />
          </NumberInput>
        </Box>
      </PopoverContent>
    </Popover>
  );
}

export default Spacing;
