import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import ToolboxObjects from './objects';
import { getContextMenuType } from 'components/Canvas/utils/transform';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';

function Toolbox() {
  const [contextMenuType, setContextMenuType] = useState('Default');

  const { activeObject } = useTemplateEditorContext();

  useEffect(() => {
    const objectTypes = ['Frame', 'path', 'activeSelection'];
    if (activeObject && objectTypes.includes(activeObject.type)) {
      setContextMenuType('Default');
      return;
    }

    if (activeObject) {
      const contextMenuType = getContextMenuType(activeObject);
      setContextMenuType(contextMenuType);
    } else {
      setContextMenuType('Default');
    }
  }, [activeObject]);

  const Component = ToolboxObjects[contextMenuType];

  return (
    <Box
      sx={{
        height: '60px',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid rgba(57,76,96,.15)',
        zIndex: 1,
        flex: 'none',
      }}
      id="toolboxWrapper"
    >
      <Component />
    </Box>
  );
}

export default Toolbox;
