import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Menu,
  MenuList,
  MenuItem,
  forwardRef,
  MenuDivider,
  Kbd,
} from '@chakra-ui/react';
import { fabric } from 'fabric';
import { useEditor } from 'components/Canvas';
import { Icon } from '@iconify/react';
import BringForward from '../Icons/BringForward';
import BringToFront from '../Icons/BringToFront';
import SendBackward from '../Icons/SendBackward';
import SendToBack from '../Icons/SendToBack';
import Copy from '../Icons/Copy';
import Paste from '../Icons/Paste';
import Duplicates from '../Icons/Duplicates';
import Trash from '../Icons/Trash';
import Layer from '../Icons/Layer';
import { HoverMenu, SubMenuButton, SubMenuList } from './SubMenu';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import { objectTypes } from 'components/Canvas/common/constants';

export const commandsTypes = {
  COPY: 'Copy',
  PASTE: 'Paste',
  DUPLICATE: 'Duplicate',
  DELETE: 'Delete',
  LAYER: 'Layer',
  LAYER_BRING_FORWARD: 'Bring forward',
  LAYER_BRING_TO_FRONT: 'Bring to front',
  LAYER_SEND_BACKWARD: 'Send backward',
  LAYER_SEND_TO_BACK: 'Send to back',
};

function ContextMenu() {
  const editor = useEditor();
  const {
    setIsEnabledDoubleSideMessage,
    isEnabledDoubleSide,
    activeObject,
    setActiveObject,
    contextMenu,
    setContextMenu,
  } = useTemplateEditorContext();
  const canvas = editor?.handlers?.canvas;
  const containerRef = useRef();
  const [isTopLayer, setIsTopLayer] = useState(false);
  const [isBottomLayer, setIsBottomLayer] = useState(false);
  const isFrame = contextMenu?.object?.type === objectTypes.FRAME;
  const isFrontFrame =
    contextMenu?.object?.id === 'Frame1' ||
    contextMenu?.object?.id === 'frontSide';
  const frameCnt = editor?.handlers?.frameHandler?.getFrames()?.length ?? 0;

  const polygonIncludesPoint = (points, point) => {
    for (var i = 0; i < points.length; i++) {
      if (points[i].eq(point)) return true;
    }
    return false;
  };

  const isIntersecting = (obj1, obj2) => {
    var result = new fabric.Intersection(),
      points1 = obj1.getCoords(true, false),
      points2 = obj2.getCoords(true, false),
      length1 = points1.length,
      length2 = points2.length,
      a1,
      a2,
      b1,
      b2;

    // Check if one object lies within the other.
    if (
      obj1.isContainedWithinObject(obj2, false, true) ||
      obj2.isContainedWithinObject(obj1, false, true)
    )
      return true;

    // Check for intersection between all edges.
    for (var i = 0; i < length1; i++) {
      a1 = points1[i];
      a2 = points1[(i + 1) % length1];

      for (var j = 0; j < length2; j++) {
        b1 = points2[j];
        b2 = points2[(j + 1) % length2];
        result = fabric.Intersection.intersectLineLine(a1, a2, b1, b2);

        if (result.status === 'Intersection') {
          // It's not an intersection if we have only 1 intersection point and if this one is the end of one of our lines.
          let allPoints = [a1, a2, b1, b2];
          if (
            result.points.length > 1 ||
            !polygonIncludesPoint(allPoints, result.points[0])
          ) {
            return true;
          } else if (
            obj2.containsPoint(a1) ||
            obj2.containsPoint(a2) ||
            obj1.containsPoint(b1) ||
            obj1.containsPoint(b2)
          ) {
            // But it is if one corner of an edge is within the other object.
            return true;
          }
        }
      }
    }

    return false;
  };

  const updateLayerStatus = selectedObject => {
    if (selectedObject?.type === objectTypes.FRAME) return;

    const totalObjectCnt = (canvas?.getObjects() || []).length;
    if (!totalObjectCnt) return;
    // min: bottom, max: top
    const objectOrder = [];
    Array(totalObjectCnt)
      .fill(0)
      .map((_, index) => {
        const object = canvas?.item(index);
        if (
          object.type !== objectTypes.FRAME &&
          object.type !== objectTypes.BACKGROUND_IMAGE
        )
          objectOrder.push(
            object.id
              ? object.id
              : object.type.concat(object.metadata?.__session_var_id)
          );
        return object.id;
      });
    const objects = (canvas?.getObjects() || []).filter(
      object =>
        object.type !== objectTypes.FRAME &&
        object.type !== objectTypes.BACKGROUND_IMAGE
    );
    // group objects based on intersections
    const groupedObjects = [];
    objects.map(obj => {
      let intersectionId = -1;
      for (let i = 0; i < groupedObjects.length; i++) {
        const group = groupedObjects[i];
        intersectionId = group.findIndex(
          each => each && isIntersecting(each, obj) // each.intersectsWithObject(obj)
        );
        if (intersectionId !== -1) {
          groupedObjects[i].push(obj);
          break;
        }
      }
      if (intersectionId === -1) groupedObjects.push([obj]);
      return obj;
    });

    // selectedObjects
    if (!Array.isArray(selectedObject)) {
      const curGroup = groupedObjects.find(
        group =>
          group.findIndex(
            each =>
              (selectedObject.id && each.id === selectedObject.id) ||
              (!selectedObject.id &&
                each.id === selectedObject.id &&
                selectedObject?.metadata?.__session_var_id ===
                  each?.metadata?.__session_var_id &&
                selectedObject.type === each.type)
          ) > -1
      );
      if (!curGroup) return;
      const curGroupOrder = objectOrder.filter(
        objId =>
          curGroup.findIndex(
            each =>
              each &&
              ((each.id && each.id === objId) ||
                (!each.id &&
                  objId === each.type.concat(each.metadata?.__session_var_id)))
          ) > -1
      );
      setIsBottomLayer(
        selectedObject.id
          ? curGroupOrder[0] === selectedObject.id
          : curGroupOrder[0] ===
              selectedObject.type.concat(
                selectedObject.metadata?.__session_var_id
              )
      );
      setIsTopLayer(
        selectedObject.id
          ? curGroupOrder[curGroupOrder.length - 1] === selectedObject.id
          : curGroupOrder[curGroupOrder.length - 1] ===
              selectedObject.type.concat(
                selectedObject.metadata?.__session_var_id
              )
      );
    } else {
      const objectStatus = selectedObject.map(selObject => {
        const curGroup = groupedObjects.find(
          group =>
            group.findIndex(
              each =>
                (selObject.id && each.id === selObject.id) ||
                (!selObject.id &&
                  each.id === selObject.id &&
                  selObject.metadata.__session_var_id ===
                    each.metadata.__session_var_id &&
                  selObject.type === each.type)
            ) > -1
        );
        const curGroupOrder = objectOrder.filter(
          objId =>
            curGroup.findIndex(
              each =>
                each &&
                ((each.id && each.id === objId) ||
                  (!each.id &&
                    objId ===
                      each.type.concat(each.metadata?.__session_var_id)))
            ) > -1
        );
        let isBottom = true;
        let isTop = true;
        for (let i = 0; i < curGroupOrder.length; i++) {
          const each = curGroupOrder[i];
          if (
            (selObject.id && each === selObject.id) ||
            (!selObject.id &&
              each ===
                selObject.type.concat(selObject.metadata?.__session_var_id))
          )
            break;
          if (
            selectedObject.findIndex(
              item =>
                (item.id && item.id === each) ||
                (!item.id &&
                  each === item.type.concat(item.metadata?.__session_var_id))
            ) === -1
          ) {
            isBottom = false;
            break;
          }
        }
        for (let i = curGroupOrder.length; i >= 0; i--) {
          const each = curGroupOrder[i];
          if (
            (selObject.id && each === selObject.id) ||
            (!selObject.id &&
              each ===
                selObject.type.concat(selObject.metadata?.__session_var_id))
          )
            break;
          if (
            selectedObject.findIndex(
              item =>
                (item.id && item.id === each) ||
                (!item.id &&
                  each === item.type.concat(item.metadata?.__session_var_id))
            ) === -1
          ) {
            isBottom = false;
            break;
          }
        }
        return { isTop, isBottom };
      });
      setIsTopLayer(objectStatus.every(each => each.isTop));
      setIsBottomLayer(objectStatus.every(each => each.isBottom));
    }
  };

  useEffect(() => {
    if (contextMenu?.object && contextMenu?.visible) {
      const selectedObject = contextMenu.object;
      updateLayerStatus(selectedObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextMenu.object]);

  const handleCommands = (type, isFrameOp = false, isFrontFrameOp = false) => {
    switch (type) {
      case commandsTypes.COPY:
        editor.handlers.objectsHandler.copy();
        break;
      case commandsTypes.PASTE:
        editor.handlers.objectsHandler.paste();
        setActiveObject(activeObject);
        break;
      case commandsTypes.DUPLICATE:
        if (isFrameOp) {
          if (!isEnabledDoubleSide) {
            setIsEnabledDoubleSideMessage(true);
            return;
          }
          editor.handlers.frameHandler.duplicate();
          editor.handlers.scrollbarHandler.setScrollToBottom();
        } else {
          editor.handlers.objectsHandler.copy();
          editor.handlers.objectsHandler.paste();
          setActiveObject(activeObject);
        }
        break;
      case commandsTypes.DELETE:
        if (isFrameOp) {
          if (isFrontFrameOp) {
            editor.handlers.frameHandler.remove('frontSide');
          } else {
            editor.handlers.frameHandler.remove('backSide');
            editor.handlers.scrollbarHandler.setScrollToTop();
          }
          editor.handlers.scrollbarHandler.updateScrollPosition();
        } else {
          editor.handlers.objectsHandler.remove();
        }
        break;
      case commandsTypes.LAYER_BRING_FORWARD:
        editor.handlers.objectsHandler.bringForward();
        updateLayerStatus(contextMenu.object);
        break;
      case commandsTypes.LAYER_BRING_TO_FRONT:
        editor.handlers.objectsHandler.bringToFront();
        updateLayerStatus(contextMenu.object);
        break;
      case commandsTypes.LAYER_SEND_BACKWARD:
        editor.handlers.objectsHandler.sendBackwards();
        updateLayerStatus(contextMenu.object);
        break;
      case commandsTypes.LAYER_SEND_TO_BACK:
        editor.handlers.objectsHandler.sendToBack();
        updateLayerStatus(contextMenu.object);
        break;
      default:
    }
    setContextMenu({ ...contextMenu, visible: false });
  };

  const menuShortKeyLabel = command => <Kbd>{command}</Kbd>;

  const CommandsMenu = () => {
    // object: copy, duplicate, delete, [layer]
    // frame: paste, duplicate, delete
    return (
      <Menu isOpen={contextMenu.visible}>
        <MenuList>
          <MenuItem display="none"></MenuItem>
          {isFrame ? (
            <>
              <MenuItem
                icon={<Paste size={24} />}
                command={menuShortKeyLabel('Ctrl+V')}
                onClick={() => handleCommands(commandsTypes.PASTE)}
              >
                {commandsTypes.PASTE}
              </MenuItem>
              <MenuItem
                icon={<Duplicates size={24} />}
                command={menuShortKeyLabel('Ctrl+D')}
                onClick={() => handleCommands(commandsTypes.DUPLICATE, isFrame)}
                isDisabled={frameCnt !== 1 || !isEnabledDoubleSide}
              >
                {commandsTypes.DUPLICATE}
              </MenuItem>
              <MenuItem
                icon={<Trash size={24} />}
                command={menuShortKeyLabel('DELETE')}
                onClick={() =>
                  handleCommands(commandsTypes.DELETE, isFrame, isFrontFrame)
                }
                isDisabled={frameCnt <= 1}
              >
                {commandsTypes.DELETE}
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem
                icon={<Copy size={24} />}
                command={menuShortKeyLabel('Ctrl+C')}
                onClick={() => handleCommands(commandsTypes.COPY)}
                closeOnSelect={true}
              >
                {commandsTypes.COPY}
              </MenuItem>
              <MenuItem
                icon={<Duplicates size={24} />}
                command={menuShortKeyLabel('Ctrl+D')}
                onClick={() => handleCommands(commandsTypes.DUPLICATE)}
              >
                {commandsTypes.DUPLICATE}
              </MenuItem>
              <MenuItem
                icon={<Trash size={24} />}
                command={menuShortKeyLabel('DELETE')}
                onClick={() => handleCommands(commandsTypes.DELETE)}
              >
                {commandsTypes.DELETE}
              </MenuItem>
              {!(isBottomLayer && isTopLayer) && (
                <>
                  <MenuDivider />
                  <MenuItem
                    icon={<Layer size={24} />}
                    command={
                      <Icon
                        icon="material-symbols:arrow-forward-ios"
                        fontSize={18}
                      />
                    }
                    as={LayerSubMenu}
                  >
                    {commandsTypes.LAYER}
                  </MenuItem>
                </>
              )}
            </>
          )}
        </MenuList>
      </Menu>
    );
  };

  // console.log('====flag: ', isTopLayer, isBottomLayer);

  const LayerSubMenu = forwardRef((props, ref) => {
    return (
      <HoverMenu closeOnSelect={false}>
        <SubMenuButton
          ref={ref}
          {...{
            ...props,
            icon: <Layer size={24} />,
            command: (
              <Icon icon="material-symbols:arrow-forward-ios" fontSize={18} />
            ),
          }}
        >
          {commandsTypes.LAYER}
        </SubMenuButton>
        <SubMenuList>
          <MenuItem
            icon={<BringForward size={24} />}
            command={menuShortKeyLabel('Ctrl + ]')}
            onClick={() => handleCommands(commandsTypes.LAYER_BRING_FORWARD)}
            isDisabled={isTopLayer}
          >
            {commandsTypes.LAYER_BRING_FORWARD}
          </MenuItem>
          <MenuItem
            icon={<BringToFront size={24} />}
            command={menuShortKeyLabel('Ctrl + Alt + ]')}
            onClick={() => handleCommands(commandsTypes.LAYER_BRING_TO_FRONT)}
            isDisabled={isTopLayer}
          >
            {commandsTypes.LAYER_BRING_TO_FRONT}
          </MenuItem>
          <MenuItem
            icon={<SendBackward size={24} />}
            command={menuShortKeyLabel('Ctrl + [')}
            onClick={() => handleCommands(commandsTypes.LAYER_SEND_BACKWARD)}
            isDisabled={isBottomLayer}
          >
            {commandsTypes.LAYER_SEND_BACKWARD}
          </MenuItem>
          <MenuItem
            icon={<SendToBack size={24} />}
            command={menuShortKeyLabel('Ctrl + Alt + [')}
            onClick={() => handleCommands(commandsTypes.LAYER_SEND_TO_BACK)}
            isDisabled={isBottomLayer}
          >
            {commandsTypes.LAYER_SEND_TO_BACK}
          </MenuItem>
        </SubMenuList>
      </HoverMenu>
    );
  });

  return (
    <Box
      sx={{
        display: `${contextMenu.visible ? 'block' : 'none'}`,
        top: contextMenu.position.top + 20,
        left: contextMenu.position.left - 10,
        position: 'absolute',
        zIndex: 1111,
        background: '#ffffff',
        boxShadow: '0 0.1rem 0.25rem #f5f5f5',
        fontSize: '14px',
      }}
      ref={containerRef}
      onContextMenu={e => {
        e.preventDefault();
      }}
    >
      <CommandsMenu />
    </Box>
  );
}
export default ContextMenu;
