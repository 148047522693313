import _ from 'lodash';
// import { SCALE_FACTOR } from './canvasHelpers';

export function isObjectURL(fileString) {
  return fileString.startsWith('blob');
}

export function isBase64Image(fileString) {
  return fileString.startsWith('data:');
}

const checkBackgroundImg = (serverTemplate, localTemplate) => {
  if (
    (serverTemplate.bg_img && localTemplate.bg_img === undefined) ||
    (serverTemplate.bg_img === undefined && localTemplate.bg_img)
  )
    return true;

  if (
    serverTemplate.bg_img &&
    localTemplate.bg_img &&
    serverTemplate.bg_img.original_url !== localTemplate.bg_img.original_url
  )
    return true;
};

const checkSide = (serverTemplate, localTemplate) =>
  serverTemplate.ad_template_vars.some((itemObj, index) => {
    let filteredLocalObj = _.omit(localTemplate.ad_template_vars[index], [
      'z_index',
    ]);
    let filteredServerObj = _.omit(itemObj, [
      'ad_template_id',
      'ad_template_page_id',
      'z_index',
    ]);

    filteredServerObj.size_x = Number(filteredServerObj.size_x.toFixed(2));
    filteredServerObj.size_y = Number(filteredServerObj.size_y.toFixed(2));
    filteredServerObj.pos_x = Number(filteredServerObj.pos_x.toFixed(2));
    filteredServerObj.pos_y = Number(filteredServerObj.pos_y.toFixed(2));

    if (itemObj.label === 'qrc') {
      if (filteredLocalObj.hasOwnProperty('run_scaleToWidth')) {
        filteredLocalObj = _.omit(filteredLocalObj, ['run_scaleToWidth']);
        filteredLocalObj.size_x = 0;
        filteredLocalObj.size_y = 0;
      }
    }

    // Temporary exclude barcode's height and width since server height and wdith data will always be 100
    if (itemObj.label === 'barcode') {
      filteredLocalObj['metadata'] = _.omit(filteredLocalObj['metadata'], [
        'height',
        'width',
      ]);
      filteredServerObj['metadata'] = _.omit(filteredServerObj['metadata'], [
        'height',
        'width',
      ]);
    }

    console.log('checkSide serverTemplate', serverTemplate);
    console.log('checkSide localTemplate', localTemplate);
    console.log('checkSide filteredServerObj', filteredServerObj);
    console.log('checkSide filteredLocalObj', filteredLocalObj);

    return !_.isEqual(filteredServerObj, filteredLocalObj);
  });

export async function templateHasChanged(server, local) {
  // Check basic
  if (!server && !local.double_sided && local.ad_template_vars.length > 0)
    return true;
  if (
    !server &&
    local.double_sided &&
    (local.ad_template_pages[0].ad_template_vars.length > 0 ||
      local.ad_template_pages[1].ad_template_vars.length > 0)
  )
    return true;
  if (
    (!server &&
      local.ad_template_vars.length === 0 &&
      local.ad_template_pages.length === 0) ||
    (!server &&
      local.ad_template_pages[0].ad_template_vars.length === 0 &&
      local.ad_template_pages[1].ad_template_vars.length === 0)
  )
    return false;
  if (server.double_sided !== local.double_sided) return true;
  if (server.is_portrait !== local.is_portrait) return true;
  if (server.ad_template_pages.length !== local.ad_template_pages.length)
    return true;
  if (server.ad_template_vars.length !== local.ad_template_vars.length)
    return true;

  // Single sided template
  if (server.double_sided === false) {
    // Check background image
    if (checkBackgroundImg(server, local)) return true;

    // Check ad_template_vars for single sided template
    const singleSidedTemplateChanged = checkSide(server, local);

    if (singleSidedTemplateChanged) return true;
  }

  // Double sided template
  if (server.double_sided === true) {
    const doubleSidedTemplateChanged = server.ad_template_pages.some(
      (page, index) => {
        let serverPage = page;
        let localPage = local.ad_template_pages[index];

        // Check background image
        if (checkBackgroundImg(serverPage, localPage)) return true;

        // Check ad_template_vars for single sided template
        const singleSidedTemplateChanged = checkSide(serverPage, localPage);

        if (singleSidedTemplateChanged) return true;

        return false;
      }
    );

    if (doubleSidedTemplateChanged) return true;
  }

  return false;
}

export function base64ImageToFile(str) {
  // extract content type and base64 payload from original string
  const pos = str.indexOf(';base64,');
  const type = str.substring(5, pos);
  const b64 = str.substr(pos + 8);

  // decode base64
  const imageContent = atob(b64);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const filename = `background.${type.split('/')[1]}`;
  // convert ArrayBuffer to Blob
  let blob = new Blob([buffer], { type: type });
  const file = new File([blob], filename);
  return file;
}

export const remoteImageToFile = async original_url => {
  const relativeURL = () => {
    const relativePath = `/uploads/${original_url.split('/uploads')[1]}`;

    if (relativePath.includes('?')) {
      const corePath = relativePath.split('?');
      return corePath[0];
    }

    return relativePath;
  };
  const pathSplit = relativeURL().split('/');
  const extSplit = pathSplit[pathSplit.length - 1].split('.');
  const fileName = extSplit[0];
  const extension = extSplit[extSplit.length - 1];

  const res = await fetch(original_url, {
    cache: 'no-cache',
  });
  const data = await res.blob();
  const file = new File([data], `${fileName}.${extension}`, {
    type: `image/${extension || 'jpeg'}`,
  });

  return file;
};

export const awaiter = () => {
  return new Promise(resolve => {
    setTimeout(() => resolve(), 1000);
  });
};

const getFontStatus = font => {
  return document.fonts.check(`12px ${font}`);
};

export const loadFont = font => {
  const isFontReady = getFontStatus(font.name);
  return new Promise(resolve => {
    if (!isFontReady) {
      const fontFace = new FontFace(font.name, `url(${font.url})`);
      fontFace
        .load()
        .then(loadedFont => {
          document.fonts.add(loadedFont);
          fontFace.loaded
            .then(() => {
              resolve(true);
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err))
        .finally(() => {
          resolve(true);
        });
    } else {
      resolve(true);
    }
  });
};
