import { useContext } from 'react';
import { TemplateEditorContext } from 'scenes/templateEditor/context/TemplateEditorContext';

export function useEditor() {
  const templateEditorContext = useContext(TemplateEditorContext);
  const { editor } = templateEditorContext;

  if (editor && templateEditorContext) {
    editor.context = templateEditorContext;
  }
  return editor;
}
