import React, { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import colors from '../../theme/Colors.module.scss';
import './FlashMessage.scss';
import { CSSTransition } from 'react-transition-group';

const FlashMessage = ({ text, type, autoClose, onExited, ...props }) => {
  const nodeRef = useRef(null);
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    if (!isEmpty(text)) open();
    const autoCloseTimeout = setTimeout(close, autoClose || 2500);
    return () => clearTimeout(autoCloseTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const open = () => setIsShowing(true);
  const close = async () => {
    setIsShowing(false);
    await timeout(500);
    onExited(); // clear message after setTimeout
  };

  const timeout = ms => {
    return new Promise(res => setTimeout(res, ms));
  };

  const buildStyle = () => {
    const style = {
      icon: <i className="fas fa-info-circle" />,
      bgColor: colors.status00,
    };

    if (type) {
      if (type === 'success') {
        style.icon = <i className="fas fa-check-circle" />;
      }

      if (type === 'warning') {
        style.bgColor = colors.status50;
      }

      if (type === 'error') {
        style.icon = <i className="fas fa-exclamation-triangle" />;
        style.bgColor = colors.status100;
      }
    }

    return style;
  };

  if (isEmpty(text)) return null;
  const styleObject = buildStyle();
  const flashMessageStyle = { backgroundColor: styleObject.bgColor };

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isShowing}
      timeout={300}
      classNames="flash"
      unmountOnExit
      {...props}
    >
      <div ref={nodeRef} className="flashMessage" style={flashMessageStyle}>
        <div className="typeIcon">{styleObject.icon}</div>

        <p>{text}</p>

        <div className="closeIcon">
          <i className="fas fa-times" onClick={close}></i>
        </div>
      </div>
    </CSSTransition>
  );
};

export default FlashMessage;
