import React, { useEffect, useState } from 'react';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Input,
  useDisclosure,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import Icons from '../../../Icons';
import { useEditor } from 'components/Canvas';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import {
  FONT_SCALE_FACTOR,
  SCALE_FACTOR,
} from 'components/Canvas/common/constants';
import CustomScroller from 'components/CustomScroller';

const fontSizes = [
  6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 88, 96,
  104, 120, 144,
];
const format = num => {
  const split = (num ?? 0).toString().split('.');
  const whole = split[0];
  const decimal = split[1]?.substring(0, 2);

  return !decimal ? parseInt(whole) : parseFloat(`${whole}.${decimal}`);
};

function FontSize() {
  const editor = useEditor();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { activeObject } = useTemplateEditorContext();

  const firstFieldRef = React.useRef(null);
  const [value, setValue] = useState('24');

  const onChangeFontSize = newFontSize => {
    setValue((newFontSize ?? 0).toString());
    editor.update({
      fontSize: (newFontSize / SCALE_FACTOR) * FONT_SCALE_FACTOR,
    });
  };

  const updateObjectFontSize = () => {
    if (!isNaN(parseFloat(value)))
      editor.update({
        fontSize: (parseFloat(value) / SCALE_FACTOR) * FONT_SCALE_FACTOR,
      });
  };

  useEffect(() => {
    if (activeObject) {
      const objectFontSize = format(
        (activeObject.fontSize * SCALE_FACTOR) / FONT_SCALE_FACTOR
      );
      setValue(objectFontSize.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  return (
    <Box sx={{ width: '5rem' }}>
      <Box sx={{ display: 'flex' }}>
        {/* <IconButton
          borderColor="#CBCBCB"
          borderRight="none"
          color="#000000"
          borderRadius="0"
          variant="outline"
          size="sm"
          icon={<Icons.Minus size={16} />}
        /> */}
        <Popover
          isOpen={isOpen}
          onOpen={onOpen}
          initialFocusRef={firstFieldRef}
          onClose={onClose}
          placement="bottom"
        >
          <PopoverTrigger>
            <InputGroup>
              <Input
                type="number"
                borderColor="#CBCBCB"
                textAlign="center"
                paddingLeft={1}
                paddingRight={6}
                size="sm"
                ref={firstFieldRef}
                height="32px"
                onBlur={() => updateObjectFontSize()}
                onKeyPress={e => e.key === 'Enter' && updateObjectFontSize()}
                value={value}
                onChange={e => setValue(e.target.value)}
                borderRadius="0"
                fontWeight="600"
                variant="outline"
              />
              <InputRightElement
                sx={{ marginTop: '-0.25rem', width: '2rem' }}
                children={<Icons.ChevronDown size={16} />}
              />
            </InputGroup>
          </PopoverTrigger>
          <PopoverContent width="auto">
            <Box sx={{ height: '400px' }}>
              <CustomScroller>
                <Box width="5rem">
                  {fontSizes.map(fontSize => {
                    return (
                      <Box
                        sx={{
                          height: '36px',
                          fontSize: '14px',
                          display: 'flex',
                          padding: '0 1rem',
                          cursor: 'pointer',
                          alignItems: 'center',
                        }}
                        _hover={{
                          backgroundColor: 'rgba(0,0,0,0.025)',
                        }}
                        onClick={() => onChangeFontSize(fontSize)}
                        key={fontSize}
                      >
                        {fontSize}
                      </Box>
                    );
                  })}
                </Box>
              </CustomScroller>
            </Box>
          </PopoverContent>
        </Popover>
        {/* <IconButton
          borderColor="#CBCBCB"
          borderLeft="none"
          color="#000000"
          borderRadius="0"
          variant="outline"
          size="sm"
          icon={<Icons.Plus size={16} />}
        /> */}
      </Box>
    </Box>
  );
}

export default FontSize;
