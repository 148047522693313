import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import Icons from '../Icons';
import PreviewModal from '../PreviewModal';
import TemplateDropwdown from './components/TemplateDropdown/TemplateDropdown';
import { useHistory } from 'react-router-dom';
import { useEditor } from 'components/Canvas';
import {
  isObjectURL,
  remoteImageToFile,
  templateHasChanged,
} from 'helpers/editor';
import CreateModal from './components/CreateModal';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import ConfirmChangesModal from 'scenes/templateEditor/components/Navbar/components/ConfirmChangesModal';

import { templateEditorActions } from 'modules/templateEditor/templateEditorDuck';
import { creativesActions } from 'modules/creatives/creativesDuck';
import * as appActions from 'modules/app/appActions';

import './Navbar.scss';

const Navbar = props => {
  const { onTemplateSave } = props;

  const editor = useEditor();

  const [options, setOptions] = useState({
    createInProgress: false,
    updateInProgress: false,
    requestFail: false,
  });
  const {
    isOpen: isPopoverOpen,
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
  } = useDisclosure();
  const {
    isOpen: isCreateTemplateModalOpen,
    onOpen: onCreateTemplateModalOpen,
    onClose: onCreateTemplateModalClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmChangesModalOpen,
    onClose: onConfirmChangesModalClose,
    onOpen: onConfirmChangesModalOpen,
  } = useDisclosure();
  const {
    doubleSided,
    currentTemplate,
    setCurrentTemplate,
    setDoubleSidedBgImgUrls,
    sizeFormat,
  } = useTemplateEditorContext();

  const [templateNameEdit, setTemplateNameEdit] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();

  // Dispatches
  const getTemplates = (payload, successCallback, failureCallback) =>
    dispatch(
      creativesActions.getTemplates(payload, successCallback, failureCallback)
    );
  const createTemplateDoubleSided = (
    payload,
    successCallback,
    failureCallback
  ) =>
    dispatch(
      templateEditorActions.createTemplateDoubleSided(
        payload,
        successCallback,
        failureCallback
      )
    );
  const updateTemplateByIdDoubleSided = (
    emplateID,
    payload,
    successCallback,
    failureCallback
  ) =>
    dispatch(
      templateEditorActions.updateTemplateByIdDoubleSided(
        emplateID,
        payload,
        successCallback,
        failureCallback
      )
    );
  const getUserFonts = (successCallback, failureCallback) =>
    dispatch(
      templateEditorActions.getUserFonts(successCallback, failureCallback)
    );
  const setMessage = payload => dispatch(appActions.setMessage(payload));

  const getTemplateLabel = () => {
    if (sizeFormat) {
      const { pixelWidth, pixelHeight } = sizeFormat;
      const width = sizeFormat.isPortrait ? pixelWidth : pixelHeight;
      const height = sizeFormat.isPortrait ? pixelHeight : pixelWidth;
      return (
        <Box sx={{ display: 'flex' }}>
          <Box>{width}px</Box> <Box mx="0.5rem">x</Box> <Box>{height}px</Box>
        </Box>
      );
    }
  };

  const createBackgroundImgFile = async (localTemplate, type) => {
    let invalidFile = false;
    if (doubleSided === false) {
      const url = localTemplate.bg_img?.original_url;
      if (
        (type === 'update' && url !== currentTemplate.bg_img?.original_url) ||
        type === 'create'
      ) {
        if (url && isObjectURL(url)) {
          const fileName = editor.handlers.frameHandler.backgroundFileName;
          let blob = await fetch(url).then(r => r.blob());
          const file = new File([blob], fileName, { type: blob.type });
          localTemplate.bg_img = file;
          invalidFile = !!file.type.includes('webp');
        } else if (url) {
          const file = await remoteImageToFile(url);
          localTemplate.bg_img = file;
          invalidFile = !!file.type.includes('webp');
        } else {
          if (type === 'update') {
            localTemplate.bg_img = '';
          }
        }
      }
    }

    if (doubleSided === true) {
      const localAdTemplatePages = localTemplate.ad_template_pages;
      for (let i = 0; i < localAdTemplatePages.length; i++) {
        const url = localAdTemplatePages[i].bg_img?.original_url;
        const fileName = localAdTemplatePages[i].bg_img?.fileName;
        if (url && isObjectURL(url)) {
          let blob = await fetch(url).then(r => r.blob());
          const file = new File(
            [blob],
            fileName + '.' + blob.type.split('/')[1],
            { type: blob.type }
          );
          localAdTemplatePages[i].bg_img = file;
          invalidFile = !!file.type.includes('webp');
        } else if (url) {
          const file = await remoteImageToFile(url);
          localAdTemplatePages[i].bg_img = file;
          invalidFile = !!file.type.includes('webp');
        }
      }
    }
    return invalidFile;
  };

  const updateTemplateHandler = async status => {
    setOptions({ ...options, updateInProgress: true });
    const localTemplate = editor.handlers.templateHandler.exportToJSON();

    let invalidBackgroundImgFile = await createBackgroundImgFile(
      localTemplate,
      'update'
    );

    // clean up importInit functions so they aren't run on next load of template
    const cleanedVars = localTemplate.ad_template_vars.map(x => {
      if (x.metadata.importInit) {
        delete x.metadata.importInit;
      }
      return x;
    });
    localTemplate.ad_template_vars = cleanedVars;

    if (localTemplate.metadata?.importInit) {
      console.log('deleting importinit');
      delete localTemplate.metadata.importInit;
    }

    try {
      let updatedTemplate = await new Promise((resolve, reject) => {
        updateTemplateByIdDoubleSided(
          currentTemplate.id,
          localTemplate,
          data => resolve(data),
          err => reject(err)
        );
      });

      // ensure any new entity ids created for template vars are updated into
      // the var objects in the editor
      editor.handlers.templateHandler.setNewEntityIds(updatedTemplate);

      if (doubleSided === false) {
        setDoubleSidedBgImgUrls([
          updatedTemplate?.bg_img?.original_url || '',
          '',
        ]);
      }

      if (doubleSided === true) {
        setDoubleSidedBgImgUrls([
          updatedTemplate?.ad_template_pages?.[0]?.bg_img?.original_url || '',
          updatedTemplate?.ad_template_pages?.[1]?.bg_img?.original_url || '',
        ]);
      }

      // refetch user fonts after update
      getUserFonts(() => {});

      if (updatedTemplate !== 'Bad Request') {
        if (status !== 'change-save') {
          setCurrentTemplate(updatedTemplate);
        }
        setMessage({
          message: 'Updated template',
          type: 'success',
        });
      } else {
        invalidBackgroundImgFile &&
          setMessage({
            type: 'error',
            message: 'Background image format is invalid',
          });
      }
    } catch (err) {
      console.log('Error updating template.');
    } finally {
      setOptions({ ...options, updateInProgress: false });
    }
  };

  const createTemplateHandler = async name => {
    setOptions({ ...options, createInProgress: true });
    const localTemplate = editor.handlers.templateHandler.exportToJSON();
    const localTemplateWithName = { ...localTemplate, name };

    let invalidBackgroundImgFile = await createBackgroundImgFile(
      localTemplateWithName,
      'create'
    );

    let createdTemplate = await new Promise((resolve, reject) => {
      createTemplateDoubleSided(
        localTemplateWithName,
        data => resolve(data),
        err => reject(err)
      );
    });

    // ensure any new entity ids created for template vars are updated into
    // the var objects in the editor
    editor.handlers.templateHandler.setNewEntityIds(createdTemplate);

    // refetch user fonts after save
    getUserFonts(() => {});

    if (createdTemplate !== 'Bad Request') {
      if (onTemplateSave) {
        onCreateTemplateModalClose();
        onTemplateSave(createdTemplate);
        setOptions({ ...options, createInProgress: false });
        getTemplates({}, () => {});
      } else {
        const id = createdTemplate.id;
        id && history.push(`/template-editor?id=${id}`);
        setOptions({ ...options, createInProgress: false });
        getTemplates({}, () => {});
        setMessage({
          message: 'Saved template',
          type: 'success',
        });
        onCreateTemplateModalClose();
      }
    } else {
      setOptions({ ...options, requestFail: true });
      if (invalidBackgroundImgFile) {
        setMessage({
          type: 'error',
          message: 'Background image format is invalid',
        });
      }
    }
  };

  const leaveEditor = () =>
    onTemplateSave
      ? onTemplateSave()
      : history.location.state
      ? history.goBack()
      : history.push('/templates');

  const onExit = async () => {
    const localTemplate = editor.handlers.templateHandler.exportToJSON();

    const localTemplateHasChanged = await templateHasChanged(
      currentTemplate,
      localTemplate
    );

    localTemplateHasChanged ? onConfirmChangesModalOpen() : leaveEditor();
  };

  const closeConfirmModal = () => {
    onConfirmChangesModalClose();
    leaveEditor();
  };

  const onChangesSaved = async () => {
    if (currentTemplate) {
      await updateTemplateHandler('change-save');
      closeConfirmModal();
    } else {
      onCreateTemplateModalOpen();
    }
    onConfirmChangesModalClose();
  };

  return (
    <>
      <CreateModal
        isOpen={isCreateTemplateModalOpen}
        onClose={onCreateTemplateModalClose}
        onSave={createTemplateHandler}
        createInProgress={options.createInProgress}
        requestFail={options.requestFail}
      />
      {isPopoverOpen && (
        <PreviewModal
          isOpen={isPopoverOpen}
          onOpen={onPopoverOpen}
          onClose={onPopoverClose}
        />
      )}

      <ConfirmChangesModal
        isOpen={isConfirmChangesModalOpen}
        onClose={onConfirmChangesModalClose}
        onSave={onChangesSaved}
        onCancel={closeConfirmModal}
      />
      <Box
        className={`template-editor__navbar ${
          templateNameEdit ? 'template-name-edit' : ''
        }`}
        onClick={() => {
          editor.closeKeysMenu();
          editor.closeContextMenu();
        }}
      >
        <Box className="template-editor__navbar-left">
          <Box className="navbar-left__exit" onClick={onExit}>
            <Icons.ChevronLeft size={24} />
            Exit
          </Box>
          <TemplateDropwdown
            setTemplateNameEdit={setTemplateNameEdit}
            updateTemplateHandler={updateTemplateHandler}
            onCreateTemplateModalOpen={onCreateTemplateModalOpen}
          />
        </Box>
        <Box className="template-editor__navbar-center">
          <Box>{getTemplateLabel()}</Box>
        </Box>
        <Box className="template-editor__navbar-right">
          <Button
            className="navbar-right__create-save-btn"
            onClick={
              currentTemplate
                ? updateTemplateHandler
                : onCreateTemplateModalOpen
            }
            variant="variant"
            disabled={options.updateInProgress ? true : false}
          >
            {currentTemplate ? (
              <>{options.updateInProgress ? 'Saving' : 'Save'}</>
            ) : (
              'Create'
            )}
          </Button>
          <Button
            onClick={onPopoverOpen}
            className="navbar-right__preview-btn navbar_preview"
          >
            Preview
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
