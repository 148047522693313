import { Button } from '@chakra-ui/react';
import React from 'react';
import { useEditor } from 'components/Canvas';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';

function AddNewPage() {
  const editor = useEditor();
  const { grid, setIsEnabledDoubleSideMessage, isEnabledDoubleSide } =
    useTemplateEditorContext();

  const addNewFrame = () => {
    if (!isEnabledDoubleSide) {
      setIsEnabledDoubleSideMessage(true);
      return;
    }

    editor.handlers.frameHandler.addNewFrame();
    editor.handlers.scrollbarHandler.setScrollToBottom();
    editor.handlers.gridHandler.update({ ...grid });
  };

  return (
    <Button
      position="absolute"
      id="add-new-frame"
      onClick={() => {
        addNewFrame();
      }}
      sx={{ marginLeft: '1rem' }}
    >
      + Add Page
    </Button>
  );
}

export default AddNewPage;
