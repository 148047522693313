import { fabric } from 'fabric';
import { FRAME_MARGIN, SCROLL_SPEED } from '../common/constants';
import BaseHandler from './BaseHandler';
import colors from 'theme/Colors.module.scss';

class ScrollbarHandler extends BaseHandler {
  diffTop;
  diffBottom;
  diffRight;
  diffLeft;

  constructor(props) {
    super(props);
    this.initialize();
  }

  initialize = () => {
    this.diffBottom = 0;
    this.diffLeft = 0;
    this.diffRight = 0;
    this.diffTop = 0;
    this.canvas.on('mouse:wheel', this.onMouseWheel);
    this.initializeScrollY();
    this.initializeScrollX();
  };

  destroy = () => {
    this.canvas.off('mouse:wheel', this.onMouseWheel);
  };

  onMouseWheel = event => {
    const isCtrlKey = event.e ? event.e.ctrlKey : event.ctrlKey;
    if (!isCtrlKey) {
      this.handlePan(event);
      return;
    }
    this.updateScrollPosition();
  };

  handlePan = event => {
    const delta = event.e ? event.e.deltaY : event.deltaY;
    const isShiftKey = event.e ? event.e.shiftKey : event.shiftKey;
    let change = delta > 0 ? SCROLL_SPEED : -SCROLL_SPEED;

    if (delta > 0) {
      change =
        this.diffBottom + Math.abs(change) > FRAME_MARGIN
          ? Math.abs(FRAME_MARGIN - this.diffBottom)
          : change;
    }

    if (delta < 0) {
      change =
        this.diffTop + Math.abs(change) > FRAME_MARGIN
          ? -Math.abs(FRAME_MARGIN - this.diffTop)
          : change;
    }

    if (isShiftKey) {
      this.handleScrollX(change);
    } else {
      this.handleScrollY(change);
    }
  };

  handleScrollX = delta => {
    if (delta > 0) {
      if (this.diffRight > 200) {
        return;
      }
    } else {
      if (this.diffLeft > 200) {
        return;
      }
    }
    let pointX = -delta;
    let pointY = 0;
    const point = new fabric.Point(pointX, pointY);
    this.canvas.relativePan(point);
    this.updateScrollPosition();
  };

  handleScrollY = delta => {
    let pointY = -delta;
    let pointX = 0;

    if (delta > 0) {
      if (this.diffBottom > FRAME_MARGIN) {
        return;
      }
    } else {
      if (this.diffTop > FRAME_MARGIN) {
        return;
      }
    }
    const point = new fabric.Point(pointX, pointY);
    this.canvas.relativePan(point);
    this.updateScrollPosition();
  };

  setScrollToTop = () => {
    // this.handlers.zoomHandler.zoomToRatio(1);
    const zoomRatio = this.canvas.getZoom();

    const frame = this.handlers.frameHandler.getFrame();
    const frameTop = frame.getBoundingRect().top;

    if (frameTop >= 0) return;

    const margin = 50 * zoomRatio;

    const pointY = Math.abs(frameTop) + margin;
    let currentY = 0;
    let scrollArea = pointY;

    const interval = setInterval(() => {
      if (currentY >= scrollArea) {
        clearInterval(interval);
        return;
      }

      let y = 10;

      if (currentY + 10 > scrollArea) {
        y = scrollArea - currentY;
        currentY += scrollArea - currentY;
      } else {
        currentY += 10;
      }

      const point = new fabric.Point(0, y);

      this.canvas.relativePan(point);
      this.updateScrollPosition();
    });
  };

  setScrollToBottom = () => {
    // this.handlers.zoomHandler.zoomToRatio(1);
    const zoomRatio = this.canvas.getZoom();

    const frame = this.handlers.frameHandler.getFrame();
    const frameBR = frame.getBoundingRect();

    const topButtonsHeight = 30 * zoomRatio;
    const pointY = -Math.abs(frameBR.height + frameBR.top) - topButtonsHeight;

    let currentY = 0;
    let scrollArea = Math.abs(pointY);

    const interval = setInterval(() => {
      if (currentY >= scrollArea) {
        clearInterval(interval);
        return;
      }

      let y = 10;

      if (currentY + 10 > scrollArea) {
        y = scrollArea - currentY;
        currentY += scrollArea - currentY;
      } else {
        currentY += 10;
      }
      const point = new fabric.Point(0, -y);

      this.canvas.relativePan(point);
      this.updateScrollPosition();
    });
  };

  jumpUp = () => {
    // this.handlers.zoomHandler.zoomToRatio(1);

    const frame = this.handlers.frameHandler.getFrame();
    const frameTop = frame.getBoundingRect().top;
    const backFrame = this.handlers.frameHandler.getFrames()[1];
    const backFrameTop = backFrame.getBoundingRect().top;

    const pointY = backFrameTop - frameTop;

    let currentY = 0;
    let scrollArea = pointY;

    const interval = setInterval(() => {
      if (currentY >= scrollArea) {
        clearInterval(interval);
        return;
      }

      let y = 10;

      if (currentY + 10 > scrollArea) {
        y = scrollArea - currentY;
        currentY += scrollArea - currentY;
      } else {
        currentY += 10;
      }

      const point = new fabric.Point(0, y);

      this.canvas.relativePan(point);
      this.updateScrollPosition();
    });
  };
  jumpDown = () => {
    // this.handlers.zoomHandler.zoomToRatio(1);
    const zoomRatio = this.canvas.getZoom();

    const frame = this.handlers.frameHandler.getFrame();
    const frameBR = frame.getBoundingRect();
    const frameMargin = this.handlers.frameHandler.getFrameMargin();

    const topButtonsHeight = frameMargin * zoomRatio;

    const pointY =
      -Math.abs(frameBR.height + frameBR.top) - topButtonsHeight + frameBR.top;

    let currentY = 0;
    let scrollArea = Math.abs(pointY);

    const interval = setInterval(() => {
      if (currentY >= scrollArea) {
        clearInterval(interval);
        return;
      }

      let y = 10;

      if (currentY + 10 > scrollArea) {
        y = scrollArea - currentY;
        currentY += scrollArea - currentY;
      } else {
        currentY += 10;
      }
      const point = new fabric.Point(0, -y);

      this.canvas.relativePan(point);
      this.updateScrollPosition();
    });
  };

  updateScrollPosition = () => {
    this.calculateScrollBottom();
    this.calculateScrollRight();

    this.calculateScrollY();
    this.calculateScrollX();
  };

  initializeScrollY = () => {
    const container = document.getElementById('undigital-editor-container');
    const scrollbarContainer = document.createElement('div');
    const scrollbarWrapper = document.createElement('div');
    const scrollbar = document.createElement('div');

    scrollbar.setAttribute('id', 'scrollbarY');

    this.setElementStyle(scrollbarContainer, {
      position: 'absolute',
      right: '0px',
      paddingRight: '3px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    });
    this.setElementStyle(scrollbarWrapper, {
      position: 'relative',
      height: 'calc(100% - 30px)',
      width: '6px',
      overflow: 'hidden',
      margin: 'auto',
    });

    this.setElementStyle(scrollbar, {
      position: 'absolute',
      width: '6px',
      background: colors.neutral400,
      height: '0px',
      top: '125px',
      borderRadius: '5px',
      zIndex: 99,
    });

    scrollbarContainer.addEventListener('mousedown', ev => {
      let prevY = ev.clientY;
      const canvasHeight = this.canvas.getHeight();

      const doDrag = e => {
        const delta = e.movementY;
        const currentY = e.clientY;
        let difference = Math.abs(currentY - prevY);

        const scrollHeight = this.getScrollHeight();

        const containersDifference = scrollHeight - canvasHeight;

        const isGtCanvas = containersDifference > canvasHeight;

        difference = isGtCanvas
          ? (scrollHeight * difference) / canvasHeight
          : difference;

        let change = delta > 0 ? difference : -difference;
        this.scrollbarYMouseMove(delta, change);
        prevY = currentY;
      };

      const stopDrag = () => {
        window.removeEventListener('mousemove', doDrag, false);
        window.removeEventListener('mouseup', stopDrag, false);
      };

      window.addEventListener('mousemove', doDrag, false);
      window.addEventListener('mouseup', stopDrag, false);
    });

    scrollbarContainer.appendChild(scrollbarWrapper);
    scrollbarWrapper.appendChild(scrollbar);
    container.appendChild(scrollbarContainer);
  };

  scrollbarYMouseMove = (delta, change) => {
    let move = change;
    if (delta > 0) {
      move =
        this.diffBottom + Math.abs(change) > FRAME_MARGIN
          ? Math.abs(FRAME_MARGIN - this.diffBottom)
          : change;
    }

    if (delta < 0) {
      move =
        this.diffTop + Math.abs(change) > FRAME_MARGIN
          ? -Math.abs(FRAME_MARGIN - this.diffTop)
          : change;
    }
    this.handleScrollY(move);
  };

  initializeScrollX = () => {
    const container = document.getElementById('undigital-editor-container');
    const scrollbarContainer = document.createElement('div');
    const scrollbarWrapper = document.createElement('div');
    const scrollbar = document.createElement('div');
    scrollbar.addEventListener('mousedown', this.scrollbarXMouseDown);

    scrollbar.setAttribute('id', 'scrollbarX');

    this.setElementStyle(scrollbarContainer, {
      position: 'absolute',
      bottom: '5px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    });

    this.setElementStyle(scrollbarWrapper, {
      position: 'relative',
      width: 'calc(100% - 30px)',
      height: '6px',
      overflow: 'hidden',
      margin: 'auto',
    });

    this.setElementStyle(scrollbar, {
      position: 'absolute',
      width: '600px',
      background: colors.neutral400,
      height: '6px',
      left: '125px',
      borderRadius: '5px',
    });

    scrollbarContainer.appendChild(scrollbarWrapper);
    scrollbarWrapper.appendChild(scrollbar);
    container.appendChild(scrollbarContainer);
  };

  scrollbarXMouseDown = () => {
    document.addEventListener('mousemove', this.scrollbarXMouseMove);
    document.addEventListener('mouseup', this.scrollbarXMouseUp);
  };

  scrollbarXMouseUp = () => {
    document.removeEventListener('mousemove', this.scrollbarXMouseMove);
    document.removeEventListener('mouseup', this.scrollbarXMouseUp);
  };

  scrollbarXMouseMove = e => {
    const delta = e.movementX;
    const change = delta > 0 ? 5 : -5;
    this.handleScrollX(change);
  };

  getScrollHeight = () => {
    const canvasHeight = this.canvas.getHeight();
    const zoomRatio = this.canvas.getZoom();
    const doubleSided = this.editor.doubleSided;
    const frameMargin = this.handlers.frameHandler.getFrameMargin();

    const frames = this.handlers.frameHandler.getFrames();
    const frameHeight = frames[0].height * zoomRatio;
    const framesHeight = doubleSided ? frameHeight * 2 : frameHeight;

    const spaceHeight = doubleSided ? frameMargin * zoomRatio : 0;
    const margin = FRAME_MARGIN;

    let scrollHeight = framesHeight + spaceHeight + margin * 2;

    scrollHeight = scrollHeight < canvasHeight ? canvasHeight : scrollHeight;

    return scrollHeight;
  };

  calculateScrollY() {
    const canvasHeight = this.canvas.getHeight();
    const scrollbarY = document.getElementById('scrollbarY');
    const { diffTop } = this;

    const margin = FRAME_MARGIN;

    let scrollHeight = this.getScrollHeight();

    if (!scrollbarY) return;

    let difference = scrollHeight - canvasHeight;
    const isGtCanvas = difference > canvasHeight;

    difference = isGtCanvas
      ? (canvasHeight * difference) / scrollHeight
      : difference;

    let scrollbarHeight = Math.abs(canvasHeight - difference - 35);

    let top = isGtCanvas
      ? (canvasHeight * margin) / scrollHeight -
        (canvasHeight * diffTop) / scrollHeight
      : margin - diffTop;

    this.setElementStyle(scrollbarY, {
      bottom: 'auto',
      top: top + 'px',
      height: scrollbarHeight + 'px',
    });
  }

  getOffsets() {
    const frame = this.handlers.frameHandler.get();
    const frames = this.handlers.frameHandler.getFrames();
    const doubleSided = this.editor.doubleSided;
    const frameMargin = doubleSided
      ? this.handlers.frameHandler.getFrameMargin()
      : 0;
    const framesHeight = doubleSided
      ? frames[0].height + frames[1].height
      : frame.height; //Calculate scroll height

    const zoomRatio = this.canvas.getZoom();

    const canvasHeight = this.canvas.getHeight();
    const canvasCenter = this.canvas.getCenter();

    const panningOffset =
      canvasCenter.top -
      (frame.top + (framesHeight + frameMargin) / 2) * zoomRatio;
    const pannintTop = this.canvas.viewportTransform[5] - panningOffset;
    const availableSpace =
      canvasHeight - (framesHeight + frameMargin) * zoomRatio;

    const verticalOffset = availableSpace / 2;
    const offsetBottom = verticalOffset - pannintTop;
    const offsetTop = verticalOffset + pannintTop;

    return { offsetBottom, offsetTop };
  }

  calculateScrollBottom() {
    const { offsetTop, offsetBottom } = this.getOffsets();

    this.diffTop = offsetTop;
    this.diffBottom = offsetBottom;

    this.handlers.frameHandler.setButtonsPosition();
    this.handlers.frameHandler.setCurrentBackgroundColorByScrollPosition();
    this.handlers.frameHandler.setCurrentBackgroundImageByScrollPosition();
    // this.handlers.frameHandler.setActiveFrame();
  }

  calculateScrollX() {
    const canvasWidth = this.canvas.getWidth();
    const scrollbarX = document.getElementById('scrollbarX');
    const { diffLeft, diffRight } = this;

    if (!scrollbarX) return;

    if (diffLeft > 0 && diffRight > 0) {
      scrollbarX.style.width = '0px';
    } else if (diffLeft > 0 && diffRight < 0) {
      let calculatedSize = canvasWidth + diffRight;
      if (canvasWidth / 2 + diffRight < 100) {
        calculatedSize = 100;
        this.setElementStyle(scrollbarX, {
          right: 'auto',
          left: 0 + 'px',
          width: calculatedSize + 'px',
        });
      } else {
        this.setElementStyle(scrollbarX, {
          right: 'auto',
          left: diffRight + 'px',
          width: calculatedSize + 'px',
        });
      }
    } else if (diffLeft < 0 && diffRight < 0) {
      let calculatedSize = canvasWidth + diffLeft + diffRight;
      calculatedSize = calculatedSize > 100 ? calculatedSize : 100;
      this.setElementStyle(scrollbarX, {
        right: 'auto',
        left: Math.abs(diffLeft) + 'px',
        width: calculatedSize + 'px',
      });
    } else if (diffLeft < 0 && diffRight > 0) {
      let calculatedSize = canvasWidth + diffLeft;
      this.setElementStyle(scrollbarX, {
        left: 'auto',
        right: diffLeft + 'px',
        width: calculatedSize + 'px',
      });
    }
  }

  calculateScrollRight() {
    const frame = this.handlers.frameHandler.get();
    const zoomRatio = this.canvas.getZoom();

    const canvasWidth = this.canvas.getWidth();
    const canvasCenter = this.canvas.getCenter();

    const panningOffset =
      canvasCenter.left - (frame.left + frame.width / 2) * zoomRatio;
    const pannintLeft = this.canvas.viewportTransform[4] - panningOffset;

    const availableSpace = canvasWidth - frame.width * zoomRatio;

    const horizontalOffset = availableSpace / 2;
    const offsetRight = horizontalOffset - pannintLeft;
    const offsetLeft = horizontalOffset + pannintLeft;

    this.diffLeft = offsetLeft;
    this.diffRight = offsetRight;
  }

  setElementStyle = (element, styles) => {
    Object.keys(styles).forEach(style => {
      element.style[style] = styles[style];
    });
  };

  centerCanvas = () => {
    const zoomFitRatio = this.canvas.getZoom();
    const center = this.canvas.getCenter();
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);

    this.handlers.zoomHandler.zoomToPoint(
      new fabric.Point(center.left, center.top),
      zoomFitRatio,
      true
    );

    this.context.setZoomRatio(zoomFitRatio);

    if (this.editor.doubleSided) {
      const frame = this.handlers.frameHandler.getFrame();
      const frameMargin = this.handlers.frameHandler.getFrameMargin();
      const pointY = -Math.abs(
        frame.getBoundingRect().top - frameMargin * zoomFitRatio
      );
      this.canvas.relativePan(new fabric.Point(0, pointY));
    }

    this.updateScrollPosition();
  };
}

export default ScrollbarHandler;
