import React from 'react';
import Editor from './Editor';
import { TemplateEditorProvider } from 'scenes/templateEditor/context/TemplateEditorContext';

const TemplateEditor = props => {
  return (
    <TemplateEditorProvider>
      <Editor {...props} />
    </TemplateEditorProvider>
  );
};

export default TemplateEditor;
